import { Button, Card, Checkbox, Form, Input, InputNumber, Select, Space, Typography } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import VideosInModule from '../VideosInModule';
import AuthorSelect from '../AuthorSelect';
import PolicyModelTooltip from '../PolicyModelTooltip';

const { Paragraph, Text } = Typography;
const { Option } = Select;
const ModulesFormListItem = ({addImportedVideos, allModules, addVideoToModule, currentUser,  editing, formRef, field, index, numModules, playVideoInModal, policyModel, selectedVideos}) => {

    const fetchCreatorList = async (username) => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) =>
                body.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.username,
                })),
            );
    }
    const calculateLicensedModulePrice = () => {
        if(formRef.current) {
            let listName = field.name != null ? ['modules', field.name, "videos"] : ["videos"];
            let currentModuleId = formRef.current.getFieldValue(['modules', field.name, 'id'])
            let videos = formRef.current.getFieldValue(listName)
            if(videos){
                let licensedModuleIds = new Set(videos.map(video => video.parentModule));
                licensedModuleIds.delete(currentModuleId)
                licensedModuleIds.delete(undefined)
                licensedModuleIds = [...licensedModuleIds]
                let sum = 0;
                if(licensedModuleIds.length){
                    licensedModuleIds.forEach(moduleId => {
                    let licensedModule = allModules.find(moduleData => moduleData.id == moduleId);
                    if(licensedModule != null){
                        sum += licensedModule.price;
                    }
                    })
                }
                return sum;
            }else {
                return 0;
            }
        }
    }

    const calculateTotalPrice = () => {
        if(formRef.current) {
            let totalPrice = formRef.current.getFieldValue(['modules', field.name, 'customPrice']);
            //console.log(totalPrice)
            if(totalPrice == null){
                totalPrice = policyModel.platformDefaultPrice;
            }
            totalPrice += calculateLicensedModulePrice();
            return totalPrice;
        }
    }

    const handleModuleNameChange = (event)=>{
        let modules = formRef.current.getFieldValue(['modules']);
        modules[field.name]['name'] = event.target.value;

        return formRef.current.setFieldsValue({
            modules: modules
        })
    }

    const handleOnModulePrivacyScopeChange = (value) => {
        let modules = formRef.current.getFieldValue(['modules']);
        modules[field.name]['privacyScope'] = value;
        return formRef.current.setFieldsValue({
            modules: modules
        })
    }
    
    const modulePriceTooltipConfig = {
        title: <PolicyModelTooltip policyModel={policyModel}/>,
        placement: 'bottomLeft'
    }

    return <>
                <Form.Item
                    required={true}
                    key={field.key}
                >   
                
                    <Card className="new-collection-form-module-card">
                  
                        <Form.Item
                         
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            label="Module Name"
                            rules={[{ required: true, message: 'Please give your module a name!' }]}
                            >
                            <Input onChange={handleModuleNameChange} maxLength={50}
                            />
                        </Form.Item>
                        <Form.Item 
                            label="Author"
                            name={[field.name, 'primaryAuthorUsername']}
                            fieldKey={[field.fieldKey, 'primaryAuthorUsername']}
                            tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."
                        >
                            <AuthorSelect 
                                placeholder={"Select or search for a user by username (Or leave blank to default to yourself)"}
                                fetchOptions={fetchCreatorList}
                                mode={null}
                            />
                        </Form.Item>
                        <Form.Item 
                            label="Co-Authors"
                            name={[field.name, 'coAuthorUsernames']}
                            fieldKey={[field.fieldKey, 'coAuthorUsernames']}
                            tooltip="Used mainly for credit purposes only."

                        >
                            <AuthorSelect 
                                placeholder={"Select or search for a user by username"}
                                fetchOptions={fetchCreatorList}
                                mode={"multiple"}
                            />
                        </Form.Item>
                        
                        <Space size={'large'} align="start">
                            <Space align="center">
                                <Form.Item
                                    name={[field.name, 'customPrice']}
                                    fieldKey={[field.fieldKey, 'customPrice']}
                                    label='Module Price'
                                    tooltip={modulePriceTooltipConfig}
                                >
                                    <InputNumber 
                                        min={policyModel.platformMinimumPrice}
                                        onChange={value => formRef.current.setFieldsValue(['modules', field.name, 'customPrice'], value)}
                                    />
                                
                                </Form.Item>
                                cupoints
                            </Space>
                            + <Space direction="vertical">
                                <Text>Licensed Content Price</Text>
                                <InputNumber readOnly placeholder={calculateLicensedModulePrice()} />
                            </Space>
                            +
                            <Space direction="vertical">
                                <Text>Total Price</Text>
                                <InputNumber readOnly placeholder={calculateTotalPrice()}/>
                            </Space>
                        </Space>
                        <Form.Item 
                            label="Privacy Scope"
                            name={[field.name, 'privacyScope']}
                            fieldKey={[field.fieldKey, 'privacyScope']}
                        >
                            <Select defaultValue="private" style={{width: "100px"}} onChange={handleOnModulePrivacyScopeChange}>
                                <Option value="private" key="private">Private</Option>
                                <Option value="public" key="public">Public</Option>
                            </Select>
                        </Form.Item>
           
                        Videos:
                        <VideosInModule 
                            fieldKey={field.name}
                            formRef={formRef}
                            selectedVideos={selectedVideos}
                            addImportedVideos={addImportedVideos}
                            addVideoToModule={addVideoToModule}
                            currentUser={currentUser}
                            playVideoInModal={playVideoInModal}
                        />
                        
                    </Card>
            </Form.Item>
        </>
}


const mapStateToProps = (state) => {
    return {
        allModules: state.contentCreationReducer.modulePool
    }
}
export default connect(mapStateToProps, {})(ModulesFormListItem);
