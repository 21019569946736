import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Collapse, Descriptions, Form, Input, notification, PageHeader, Row, Space, Spin, Typography} from 'antd';
import {CloseCircleOutlined, DownOutlined, ImportOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';

import { clearCollectionDetail, clearPolicyModel, clearUserCreatedCollections, clearUserCreatedModules, fetchCollectionDetail, fetchPublicModules, 
        fetchUserCreatedCollections, fetchUserCreatedModules, updateCollection, fetchUserPolicyModel} from '../../actions/contentCreation';
import ImportVideoFromUrl from '../ImportFromUrl/ImportVideoFromUrl';
import ImportVideoFromCollection from '../ImportFromCollection/ImportVideoFromCollection';
import ImportVideoFromModule from '../ImportFromModule/ImportVideoFromModule';
import ModalVideoPlayer from '../ModalVideoPlayer';
import ImportedVideo from '../ImportedVideo';
import AuthorSelect from '../AuthorSelect';
import ModulesFormListItem from './ModulesFormListItem';

const {Text} = Typography;

class CollectionEditor extends Component {
    
    formRef = React.createRef();

    constructor(props){
        super(props)
        this.state = { 
            expandedModulePanel: "0",
            formPreloaded: false,
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        }
    }

    componentDidMount(){
        this.props.fetchPublicModules()
        if(this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)
            
            if(!this.props.receivedUserCreatedModules){
                this.props.fetchUserCreatedModules(this.props.user.id)
            }
            if(!this.props.receivedUserCreatedCollections){
                this.props.fetchUserCreatedCollections(this.props.user.id)
            }
            if(!this.props.receivedCollectionDetail) {
                this.props.clearCollectionDetail()
                this.props.fetchCollectionDetail(this.props.match.params.collectionId,  this.props.user.id, this.handleNotFound)
            }else if(this.props.policyModel) {
                this.prefillForm(this.props.collectionDetail)
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false){
            if(!this.props.receivedUserCreatedModules){
                this.props.fetchUserCreatedModules(this.props.user.id)
            }
            if(!this.props.receivedUserCreatedCollections){
                this.props.fetchUserCreatedCollections(this.props.user.id)
            }
            if(!this.props.receivedCollectionDetail){
                this.props.clearCollectionDetail()
                this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.user.id, this.handleNotFound)
            }
        } 
        if((!prevProps.receivedCollectionDetail && this.props.receivedCollectionDetail && this.props.policyModel)
            || (!prevProps.policyModel && this.props.policyModel && this.props.receivedCollectionDetail)){
        
            this.prefillForm(this.props.collectionDetail)
        }
        if(this.props.receivedCollectionDetail && this.props.collectionDetail.id != this.props.match.params.collectionId){
            this.props.clearCollectionDetail()
            this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.user.id, this.handleNotFound)
        }
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)
        }
    }
    
    handleNotFound = () => {
        this.props.history.push('/404')
    }

    prefillForm = (collectionData) => {
        let query = new URLSearchParams(this.props.location.search);
        let moduleIdToDefaultOpen = query.get('module');
        let moduleIndexToDefaultOpen;
        collectionData.modules.forEach((module,index) => {
            if(module.id == moduleIdToDefaultOpen){
                moduleIndexToDefaultOpen = index
            }
            module.defaultPrice = this.props.policyModel.platformDefaultPrice
            module.primaryAuthorUsername = module.primaryAuthor.username
            module.coAuthorUsernames = module.coAuthors.map(coAuthor => coAuthor.username)
        })
        collectionData.primaryAuthorUsername = collectionData.primaryAuthor.username
        collectionData.coAuthorUsernames = collectionData.coAuthors.map(coAuthor => coAuthor.username)

        this.formRef.current.setFieldsValue(collectionData)
        this.setState({ 
            formPreloaded: true,
            expandedModulePanel: moduleIndexToDefaultOpen != null ? [`${moduleIndexToDefaultOpen}`] : "0"
        })
    }

    openModal = (event) => {
        //this.setState({})
        this.setState({
            [event.currentTarget.getAttribute('data-modal-type')]: true
        })
    }

    closeModal = () => {
        this.setState({
            isPolicyModelModalVisible: false,
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false
        })
    }

    addImportedVideos = (videos) => {
        let videoIds = videos.map(video => video.id)
        this.setState({
            licensedVideos: [...this.state.licensedVideos, ...videoIds],
            importedVideos: [...this.state.importedVideos, ...videos],
            isVideoImportFromUrlModalVisible: false,
        })
    }


    selectVideos = (videoData) => {
        const newSelectedVideosState = this.state.selectedVideos.filter(existingVideo => existingVideo.embedId !== videoData.embedId)
        if(newSelectedVideosState.length === this.state.selectedVideos.length) {
            // same length == no change / video doesn't exist in selected videos
            this.setState({
                selectedVideos: [...this.state.selectedVideos, videoData]
            })
        } else {
            this.setState({
                selectedVideos: newSelectedVideosState
            })
        }
    }

    selectVideoToPlay = (video, module) => {
        this.setState({
            visiblePlayer: true, 
            videoToPlay: video,
            videoPlayedFromModule: module
        })
    }

    closeVideoPlayer = () => {
        this.setState({
            visiblePlayer: false,
        })
    }

    addVideoToModule = () => {
        this.setState({
            selectedVideos: [], 
            importedVideos: this.state.importedVideos.filter(videoData => {
                return !this.state.selectedVideos.some(selectedVideo => videoData.embedId === selectedVideo.embedId)
            })
        })
    }

    isImportedVideoSelected = (videoEmbedId) => {
        return this.state.selectedVideos.some(selectedVideo => videoEmbedId === selectedVideo.embedId)
    }

    handleOnUpdateCollectionFormFinish = (values) => { 
        if(values['coAuthorUsernames'] == null){
            values['coAuthorUsernames'] = []
        }
        if(values['primaryAuthorUsername'] == null) {
            values['primaryAuthorUsername'] = this.props.user.username
        }
        values['modules'] = values['modules'].map(moduleData => {
            if(moduleData['coAuthorUsernames'] == null){
                moduleData['coAuthorUsernames'] = []
            }
            if(moduleData['primaryAuthorUsername'] == null){
                moduleData['primaryAuthorUsername'] = this.props.user.username
            }
            return moduleData
        })
        this.setState({ submitLoading: true })
        const afterCreation = () => {
            this.setState({ submitLoading: false})
        }
        this.props.updateCollection(this.props.match.params.collectionId, values, this.handleAfterCollectionCreated, afterCreation)
        notification.destroy();
    }

    
    handleAfterCollectionCreated = () => {
        this.setState({
            expandedModulePanel: "0",
            formPreloaded: false,
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        })
        this.formRef.current.resetFields()
        this.props.history.push(`/creator/created-collections/${this.props.match.params.collectionId}/`)
    }

    handleOnSelectAllClick = () => {
        this.setState({
            selectedVideos: this.state.importedVideos
        })
    }

    handleOnUnselectAllClick = () => {
        this.setState({
            selectedVideos: []
        })
    }
    
    handleDiscardSelected = () => {
        const newImportedVideosState = this.state.importedVideos.filter(video => !this.state.selectedVideos.some(selectedVideo => video.id === selectedVideo.id));
        let videoIdsToRemove = this.state.selectedVideos.map(videoData => videoData.id)
    
        this.setState({
            licensedVideos: this.state.licensedVideos.filter(videoId => !videoIdsToRemove.includes(videoId)),
            importedVideos: newImportedVideosState,
            selectedVideos: []
        })
    }

    copyModuleOrCollection = (moduleDatas) => {
        moduleDatas.forEach(module => {
            if("id" in module){
                delete module["id"]
            }
        })
        let existingModules = this.formRef.current.getFieldValue("modules")
        if(!existingModules){ existingModules = [] }
        this.formRef.current.setFieldsValue({modules: [...existingModules, ...moduleDatas]})
    }

    assignModuleToCollection = (moduleData) => {
        let existingModules = this.formRef.current.getFieldValue("modules")
        if(!existingModules){ existingModules = [] }
        this.formRef.current.setFieldsValue({modules: [...existingModules, moduleData]})
    }


    fetchCreatorList = async (username) => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) =>
                body.map((user) => ({
                    label: `${user.firstName} ${user.lastName} (${user.username})`,
                    value: user.username,
                })),
            );
    }

    handleCollapseChange = (key) => {
        return this.setState({expandedModulePanel: [key]})
    }

    handleOnFormFinishFailed = ({values, errorFields, outOfDate}) => {
        notification.destroy();
        let errorMessages = errorFields.map(errorField => {
            let result;
            if(errorField.name[0] == "modules"){
                let numModule = errorField.name[1] + 1;
                result = "Module " + numModule;
            }else {
                result = errorField.name.join().replace(/([A-Z])/g, " $1");
            }
            return <li className="bold-error-list-item">
                {result.charAt(0).toUpperCase() + result.slice(1)}
                <ul>
                    {errorField.errors.map(error => <li className="normal-error-list-item">{error}</li>)}
                </ul>
            </li>
        })
        var today = new Date();
        notification.error({
            message: `Please resolve these errors before submitting again.`,
            description: <div>
                <p style={{fontStyle: "italic"}}>Attempted Submission at {today.toLocaleTimeString()}</p>
                <ul className="form-error-list">{errorMessages}</ul>
                </div>,
            duration: 0
        })
    }

    render(){
        if(!this.props.collectionDetail || !this.props.policyModel){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }

        return <div id="collection-creator-container">
                    <div id="collection-creator-header">
                        <PageHeader
                            title="Edit Collection"
                            className="site-page-header"
                            ghost={false}
                            onBack={() => {
                                notification.destroy();
                                this.props.history.push(`/creator/created-collections/${this.props.collectionDetail.id}`)
                            }}
                            extra={[
                                <Space key="1">
                                    <Button data-modal-type="isVideoImportFromCollectionModalVisible" onClick={this.openModal} ><ImportOutlined /> Add from collection</Button>
                                    <ImportVideoFromCollection 
                                        visible={this.state.isVideoImportFromCollectionModalVisible} 
                                        closeModal={this.closeModal} 
                                        playVideoInModal={this.selectVideoToPlay} 
                                        addImportedVideos={this.addImportedVideos}
                                        licensedVideos={this.state.licensedVideos}
                                        copyModuleOrCollection={this.copyModuleOrCollection}
                                    />
                                </Space>,
                                <Space key="2">
                                    <Button data-modal-type="isVideoImportFromModuleModalVisible" onClick={this.openModal}><ImportOutlined /> Add from module</Button>
                                    <ImportVideoFromModule 
                                        visible={this.state.isVideoImportFromModuleModalVisible} 
                                        closeModal={this.closeModal} 
                                        playVideoInModal={this.selectVideoToPlay} 
                                        addImportedVideos={this.addImportedVideos}
                                        licensedVideos={this.state.licensedVideos}
                                        copyModuleOrCollection={this.copyModuleOrCollection}
                                        assignModuleToCollection={this.assignModuleToCollection}
                                    />
                                </Space>, 
                                <Space key="3">
                                    <Button data-modal-type="isVideoImportFromUrlModalVisible" onClick={this.openModal}><ImportOutlined /> Import videos from URL</Button>
                                    <ImportVideoFromUrl 
                                        visible={this.state.isVideoImportFromUrlModalVisible} 
                                        closeModal={this.closeModal} 
                                        addImportedVideos={this.addImportedVideos}
                                    />
                                </Space>
                            ]}
                        >
                                
                            </PageHeader>
                    </div>
                    <br/>
                    <div id="new-collection-form-container">
                        <Card id="new-collection-form">
                            <Form
                                layout="vertical"
                                name="collection"
                                ref={this.formRef}
                                onFinishFailed={this.handleOnFormFinishFailed}
                                onFinish={this.handleOnUpdateCollectionFormFinish}
                            >
                                <Form.Item
                                    label="Collection Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Please give your collection a name!' }]}
                                >
                                    <Input maxLength={50}/>
                                </Form.Item>

                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ required: true, message: 'Please give your collection a description!' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Long Description"
                                    name="longDescription"
                                    rules={[{ required: true, message: 'Please give your collection a longer description!' }]}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item 
                                        label="Author"
                                        name="primaryAuthorUsername"
                                        tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."

                                    >
                                        <AuthorSelect 
                                            placeholder={"Select or search for a user by username"}
                                            fetchOptions={this.fetchCreatorList}
                                            mode={null}
                                        />
                                    </Form.Item>
                                    <Form.Item 
                                        label="Co-Authors"
                                        name="coAuthorUsernames"
                                        tooltip="Used mainly for credit purposes only."

                                    >
                                        <AuthorSelect 
                                            placeholder={"Select or search for a user by username"}
                                            fetchOptions={this.fetchCreatorList}
                                            mode={"multiple"}
                                        />
                                    </Form.Item>
                                <Form.List name="modules" >
                                    {(fields, { add, remove, move }) => (
                                        <>    
                                            <h4>Modules:</h4>
                                            <Collapse activeKey={this.state.expandedModulePanel} accordion={true} onChange={this.handleCollapseChange}>       
                                                {fields.map((field , index) => {
                                                    const shiftModuleUp = (event) => {
                                                        event.stopPropagation();
                                                        if(index > 0) { 
                                                            move(index, index - 1);
                                                            this.setState({expandedModulePanel: [`${index - 1}`]})
                                                        };
                                                    };
                                                    const shiftModuleDown = (event) => {
                                                        event.stopPropagation();
                                                    
                                                        if(index + 1  < fields.length) { 
                                                            move(index, index + 1) 
                                                            this.setState({expandedModulePanel: `${index + 1}`})
                                                        };
                                                    };
                                                    const removeModule = (event) => {
                                                        event.stopPropagation();
                                                        remove(index)
                                                        this.setState({expandedModulePanel: []})
                                                    }
                                                    const renderPanelHeader = () => {
                                                        if(this.formRef.current){
                                                            let moduleName = this.formRef.current.getFieldValue(['modules', field.name, 'name']);
                                                            if(moduleName){
                                                                return moduleName
                                                            }
                                                            return `Module ${index + 1}`
                                                        }else {
                                                            return `Module ${index + 1}`
                                                        }
                                                    }
                                                
                                                    const renderPanelButtons = () => {
                                                        if(this.state.expandedModulePanel == `${index}`){
                                                            return <Space key="0">
                                                                <Button onClick={shiftModuleUp} disabled={index == 0}>Move Up<UpOutlined /></Button>
                                                                <Button onClick={shiftModuleDown} disabled={index == fields.length - 1}>Move Down<DownOutlined /></Button>
                                                                <Button onClick={removeModule}>Remove <CloseCircleOutlined /></Button>
                                                            </Space>
                                                        }else {
                                                            return ""
                                                        }
                                                    }
                                                    return <Collapse.Panel 
                                                                header={renderPanelHeader()} 
                                                                key={`${index}`} 
                                                                className="module-form-collapse-panel"
                                                                extra={[renderPanelButtons()]}
                                                                id={`module-form-collapse-panel-${index}`}
                                                                forceRender={true}
                                                            >
                                                                <ModulesFormListItem 
                                                                    formRef={this.formRef}
                                                                    selectedVideos={this.state.selectedVideos}
                                                                    addVideoToModule={this.addVideoToModule}
                                                                    policyModel={this.props.policyModel}
                                                                    field={field}
                                                                    index={index}
                                                                    editing={true}
                                                                    currentUser={this.props.user}
                                                                    addImportedVideos={this.addImportedVideos}
                                                                    shiftModuleUp={shiftModuleUp}
                                                                    shiftModuleDown={shiftModuleDown}
                                                                    removeModule={removeModule}
                                                                    playVideoInModal={this.selectVideoToPlay} 
                                                                />
                                                            </Collapse.Panel>
                                                })} 
                                                </Collapse>
                                                <br/>
                                                
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                        Create module
                                                    </Button>
                                                </Form.Item>
                                            
                                        </>
                                    )}
                                 </Form.List>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={this.state.submitLoading}>
                                    Update
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        <Card 
                            id="imported-videos-container" 
                            title={`Unassigned Videos`}
                            actions={[
                                <Text onClick={this.handleOnSelectAllClick} disabled={this.state.importedVideos.length ? false : true}>Select all</Text>, 
                                <Text onClick={this.handleOnUnselectAllClick} disabled={this.state.selectedVideos.length ? false : true}>Unselect All</Text>, 
                                <Text onClick={this.handleDiscardSelected} disabled={this.state.selectedVideos.length ? false : true}>Remove selected</Text>]}
                        >
                            
                            <Descriptions bordered size="small">
                                <Descriptions.Item label="Imported">{this.state.importedVideos.length}</Descriptions.Item>
                                <Descriptions.Item label="Selected">{this.state.selectedVideos.length}</Descriptions.Item>
                            </Descriptions>
                            
                            <div id="imported-videos-scroll-container">
                                <div id="imported-video-cards-wrapper"> 
                                    <Row gutter={[16,16]}>
                                    {
                                        this.state.importedVideos.map((videoData, index) => 
                                            <>
                                                <Col span={12}>
                                                <ImportedVideo 
                                                    videoData={videoData} 
                                                    key={index} 
                                                    selectVideo={this.selectVideos}
                                                    videoSelected={this.isImportedVideoSelected(videoData['embedId'])}
                                                    
                                                />
                                                </Col>
                                                <br/>
                                            </>
                                        )
                                    }
                                    </Row>
                                </div>
                            </div>
                        </Card>
                        <ModalVideoPlayer  
                            module={this.state.videoPlayedFromModule}
                            playVideoInModal={this.selectVideoToPlay}
                            visible={this.state.visiblePlayer} 
                            toggleVideoPlayerModalVisibility={this.closeVideoPlayer}
                            video={this.state.videoToPlay}
                            videoActions={['play']}
                        />
                    </div>
                </div>
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
        policyModel: state.contentCreationReducer.policyModel,
        receivedUserCreatedCollections: state.contentCreationReducer.receivedUserCreatedCollections,
        receivedUserCreatedModules: state.contentCreationReducer.receivedUserCreatedModules,
        collectionDetail: state.contentCreationReducer.collectionDetail,
        receivedCollectionDetail: state.contentCreationReducer.receivedCollectionDetail
    }
}

export default connect(mapStateToProps, { clearCollectionDetail, clearPolicyModel, clearUserCreatedCollections, clearUserCreatedModules, 
                                        fetchCollectionDetail, fetchPublicModules, fetchUserCreatedCollections, 
                                        fetchUserCreatedModules, fetchUserPolicyModel, updateCollection})(CollectionEditor);