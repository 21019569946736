import {useState } from 'react';
import { Card, Collapse, Pagination } from 'antd';
import ModulesComponent from './ModulesComponent';
const ModulesComponentList = ({enablePagination=false, currentUser=null, assignModuleToCollection, modules, searchValue, playVideoInModal, addImportedVideos, copyModuleOrCollection,
                                licensedVideos, moduleActions, videoActions})=> {
    const numPerPage = 10;
    const [minIndex, setMinIndex] = useState(0);
    const [maxIndex, setMaxIndex] = useState(numPerPage)
    const [pageNumber, setPageNumber] = useState(1);
    const [currentSearchValue, setCurrentSearchValue] = useState("");

    if(enablePagination){
        if(currentSearchValue != searchValue){
            setMinIndex(0);
            setMaxIndex(10);
            setCurrentSearchValue(searchValue);
            setPageNumber(1);
        }
        const handlePageChange = (value) => {
            setMinIndex((value - 1) * numPerPage);
            setMaxIndex((value) * numPerPage);
            setPageNumber(value);
        }
    
    
        return  <Card>
                    <Collapse defaultActiveKey="0" accordion>
                        {modules.slice(minIndex, maxIndex).map(module => {
                            return <Collapse.Panel header={module.name}>
                                <ModulesComponent 
                                    module={module} 
                                    playVideoInModal={playVideoInModal}
                                    addImportedVideos={addImportedVideos}
                                    copyModuleOrCollection={copyModuleOrCollection}
                                    licensedVideos={licensedVideos}
                                    moduleActions={moduleActions}
                                    videoActions={videoActions}
                                    assignModuleToCollection={assignModuleToCollection}
                                    currentUser={currentUser}
                                />    
    
                            </Collapse.Panel>
                        })}  
                    </Collapse>
                    <br/>
                    <Pagination current={pageNumber} pageSize={numPerPage} pageSizeOptions={[10]} onChange={handlePageChange} total={modules.length} />
                </Card>    
    }else {
        return <Card>
                    <Collapse defaultActiveKey="0" accordion>
                        {modules.map((module, index) => {
                            return <Collapse.Panel header={module.name} key={`${index}`}>
                                <ModulesComponent 
                                    module={module} 
                                    playVideoInModal={playVideoInModal}
                                    addImportedVideos={addImportedVideos}
                                    copyModuleOrCollection={copyModuleOrCollection}
                                    licensedVideos={licensedVideos}
                                    moduleActions={moduleActions}
                                    videoActions={videoActions}
                                    assignModuleToCollection={assignModuleToCollection}
                                    currentUser={currentUser}
                                />    

                            </Collapse.Panel>
                        })}  
                    </Collapse>
                </Card>    
    }
   
    
}

export default ModulesComponentList;