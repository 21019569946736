import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import collectionReducer from './reducers/collection';
import contentCreationReducer from './reducers/contentCreation';
import userReducer from './reducers/user';
import videoControlReducer from './reducers/videoControl';
import './index.css';
import App from './App';
import 'babel-polyfill';
import reportWebVitals from './reportWebVitals';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({collectionReducer, contentCreationReducer, userReducer, videoControlReducer})
const store = createStore(reducers, composeEnhancer(applyMiddleware(thunk)));
//const store = createStore(reducers, applyMiddleware(thunk))

ReactDOM.render(
  
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
