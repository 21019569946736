import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Checkbox, Col, Descriptions, Form, Input, InputNumber, PageHeader, Row, Space, Spin, Typography} from 'antd';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';

import { fetchModuleDetail, fetchPublicModules, fetchUserCreatedCollections, fetchUserCreatedModules, fetchUserPolicyModel, resetModuleEditData, updateModule} from '../../actions/contentCreation';
import ImportVideoFromUrl from '../ImportFromUrl/ImportVideoFromUrl';
import ImportVideoFromCollection from '../ImportFromCollection/ImportVideoFromCollection';
import ImportVideoFromModule from '../ImportFromModule/ImportVideoFromModule';
import ModalVideoPlayer from '../ModalVideoPlayer';
import ImportedVideo from '../ImportedVideo';
import VideosInModule from '../VideosInModule';
import AuthorSelect from '../AuthorSelect';
import PolicyModelTooltip from '../PolicyModelTooltip';

const {Paragraph, Text} = Typography;

class ModuleEditor extends Component {
    formRef = React.createRef();
    constructor(props){
        super(props)
        this.state = { 
            formPreloaded: false,
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        }
    }

    componentDidMount(){
        this.props.fetchPublicModules()
        if(this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)

            if(!this.props.receivedUserCreatedModules){
                this.props.fetchUserCreatedModules(this.props.user.id)
            }
            if(!this.props.receivedUserCreatedCollections){
                this.props.fetchUserCreatedCollections(this.props.user.id)
            }
            if(!this.props.receivedModuleDetail) {
                this.props.fetchModuleDetail(this.props.match.params.moduleId, this.props.user.id, this.handleNotFound)
            }else {
                this.prefillForm(this.props.moduleDetail)
            }
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false){
            if(!this.props.receivedUserCreatedModules){
                this.props.fetchUserCreatedModules(this.props.user.id)
            }
            if(!this.props.receivedUserCreatedCollections){
                this.props.fetchUserCreatedCollections(this.props.user.id)
            }
            if(!this.props.receivedModuleDetail){
                this.props.fetchModuleDetail(this.props.match.params.moduleId, this.props.user.id, this.handleNotFound)
            }
        } 
        if((!prevProps.receivedModuleDetail && this.props.receivedModuleDetail && this.props.policyModel) 
            || (!prevProps.policyModel && this.props.policyModel && this.props.receivedModuleDetail)){
         
            this.prefillForm(this.props.moduleDetail)
        }
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)
        }
    }

    componentWillUnmount(){
        this.props.resetModuleEditData()
    }

    prefillForm = (moduleData) => {
        //while(!this.formRef.current);
        moduleData.primaryAuthor = moduleData.primaryAuthor.username
        moduleData.coAuthors = moduleData.coAuthors.map(coAuthor => coAuthor.username)
        this.formRef.current.setFieldsValue(moduleData)
        this.setState({ formPreloaded: true, defaultPricingChecked: !moduleData['customPrice']})
    }

    openModal = (event) => {
        //this.setState({})
        this.setState({
            [event.currentTarget.getAttribute('data-modal-type')]: true
        })
    }

    closeModal = () => {
        this.setState({
            isPolicyModelModalVisible: false,
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false
        })
    }

    addImportedVideos = (videos) => {
        let videoIds = videos.map(video => video.id)
        this.setState({
            licensedVideos: [...this.state.licensedVideos, ...videoIds],
            importedVideos: [...this.state.importedVideos, ...videos],
            isVideoImportFromUrlModalVisible: false,
        })
    }


    selectVideos = (videoData) => {
        const newSelectedVideosState = this.state.selectedVideos.filter(existingVideo => existingVideo.embedId !== videoData.embedId)
        if(newSelectedVideosState.length === this.state.selectedVideos.length) {
            // same length == no change / video doesn't exist in selected videos
            this.setState({
                selectedVideos: [...this.state.selectedVideos, videoData]
            })
        } else {
            this.setState({
                selectedVideos: newSelectedVideosState
            })
        }
    }

    selectVideoToPlay = (video, module) => {
        this.setState({
            visiblePlayer: true, 
            videoToPlay: video,
            videoPlayedFromModule: module
        })
    }

    closeVideoPlayer = () => {
        this.setState({
            visiblePlayer: false,
        })
    }

    addVideoToModule = () => {
        this.setState({
            selectedVideos: [], 
            importedVideos: this.state.importedVideos.filter(videoData => {
                return !this.state.selectedVideos.some(selectedVideo => videoData.embedId === selectedVideo.embedId)
            })
        })
    }

    isImportedVideoSelected = (videoEmbedId) => {
        return this.state.selectedVideos.some(selectedVideo => videoEmbedId === selectedVideo.embedId)
    }

    handleOnUpdateModuleFormFinish = (values) => {
        if(values['coAuthorUsernames'] == null){
            values['coAuthorUsernames'] = []
        }
        if(values['primaryAuthorUsername'] == null) {
            values['primaryAuthorUsername'] = this.props.user.username
        }
        this.setState({ submitLoading: true })
        const afterCreation = () => {
            this.setState({ submitLoading: false})
        }
        this.props.updateModule(this.props.match.params.moduleId, values, this.handleAfterModuleCreated, afterCreation)
    }

    handleAfterModuleCreated = () => {
        this.setState({
            formPreloaded: false,
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        })
        this.formRef.current.resetFields()
        this.props.history.push('/creator/home/?tab=2')
    }

    handleNotFound = () => {
        this.props.history.push('/404')
    }
    handleOnSelectAllClick = () => {
        this.setState({
            selectedVideos: this.state.importedVideos
        })
    }

    handleOnUnselectAllClick = () => {
        this.setState({
            selectedVideos: []
        })
    }
    
    handleDiscardSelected = () => {
        const newImportedVideosState = this.state.importedVideos.filter(video => !this.state.selectedVideos.some(selectedVideo => video.id === selectedVideo.id));
        let videoIdsToRemove = this.state.selectedVideos.map(videoData => videoData.id)
    
        this.setState({
            licensedVideos: this.state.licensedVideos.filter(videoId => !videoIdsToRemove.includes(videoId)),
            importedVideos: newImportedVideosState,
            selectedVideos: []
        })
    }

    copyModuleOrCollection = (moduleDatas) => {
        let existingModules = this.formRef.current.getFieldValue("modules")
        if(!existingModules){ existingModules = [] }
        this.formRef.current.setFieldsValue({modules: [...existingModules, ...moduleDatas]})
    }


    fetchCreatorList = async (username) => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) =>
                body.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.username,
                })),
            );
    }

    calculateLicensedModulePrice = () => {
        if(this.state.formPreloaded){
            let listName = ["videos"];
            let currentModuleId = this.formRef.current.getFieldValue(['id'])
            let videos = this.formRef.current.getFieldValue(listName)
            if(videos){
                let licensedModuleIds = new Set(videos.map(video => video.parentModule));
                licensedModuleIds.delete(currentModuleId)
                licensedModuleIds.delete(undefined)
                licensedModuleIds = [...licensedModuleIds]
                let sum = 0;
                if(licensedModuleIds.length){
                    licensedModuleIds.forEach(moduleId => {
                        let licensedModule = this.props.allModules.find(moduleData => moduleData.id == moduleId);
                        if(licensedModule != null){
                            sum += licensedModule.price;
                        }
                    })
                }
                return sum;
            }else {
                return 0;
            }
        }
    }

    calculateTotalPrice = () => {
        if(this.state.formPreloaded){
            let totalPrice = this.formRef.current.getFieldValue(['customPrice']);
            if(totalPrice == null){
                totalPrice = this.props.policyModel.platformDefaultPrice;
            }

            totalPrice += this.calculateLicensedModulePrice();
            return totalPrice;
            
        }
    }
    
    render(){
        if(!this.props.moduleDetail || !this.props.policyModel){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        const { policyModel } = this.props;
    
        
        const modulePriceTooltipConfig = {
            title: <PolicyModelTooltip policyModel={policyModel} />,
            placement: 'bottomLeft'
        }
        return <div id="collection-creator-container">
                    <div id="collection-creator-header">
                        <PageHeader
                            title="Edit Module"
                            className="site-page-header"
                            ghost={false}
                            onBack={() => this.props.history.push('/creator/home/')}
                            extra={[
                                <Space key="1">
                                    <Button data-modal-type="isVideoImportFromCollectionModalVisible" onClick={this.openModal}><ImportOutlined /> Add from collection</Button>
                                    <ImportVideoFromCollection 
                                        visible={this.state.isVideoImportFromCollectionModalVisible} 
                                        closeModal={this.closeModal} 
                                        playVideoInModal={this.selectVideoToPlay} 
                                        addImportedVideos={this.addImportedVideos}
                                        licensedVideos={this.state.licensedVideos}
                                        copyModuleOrCollection={this.copyModuleOrCollection}
                                    />
                                </Space>,
                                <Space key="2">
                                    <Button data-modal-type="isVideoImportFromModuleModalVisible" onClick={this.openModal}><ImportOutlined /> Add from module</Button>
                                    <ImportVideoFromModule 
                                        visible={this.state.isVideoImportFromModuleModalVisible} 
                                        closeModal={this.closeModal} 
                                        playVideoInModal={this.selectVideoToPlay} 
                                        addImportedVideos={this.addImportedVideos}
                                        licensedVideos={this.state.licensedVideos}
                                    />
                                </Space>, 
                                <Space key="3">
                                    <Button data-modal-type="isVideoImportFromUrlModalVisible" onClick={this.openModal}><ImportOutlined /> Import videos from URL</Button>
                                    <ImportVideoFromUrl 
                                        visible={this.state.isVideoImportFromUrlModalVisible} 
                                        closeModal={this.closeModal} 
                                        addImportedVideos={this.addImportedVideos}
                                    />
                                </Space>
                            ]}
                        >
                                
                            </PageHeader>
                    </div>
                    <br/>
                    <div id="new-module-form-container">
                        <Card id="new-collection-form">
                            <Form
                                layout="vertical"
                                name="collection"
                                ref={this.formRef}
                                onFinish={this.handleOnUpdateModuleFormFinish}
                            >
                                <Form.Item
                                    name="name"
                                    label="Module Name"
                                    rules={[{ required: true, message: 'Please give your module a name!' }]}
                                >
                                    <Input maxLength={50}/>
                                </Form.Item>
                                <Form.Item 
                                    label="Primary Author"
                                    name="primaryAuthorUsername"
                                    tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."

                                >
                                    <AuthorSelect 
                                        placeholder={"Select or search for a user by username (Or leave blank to default to yourself)"}
                                        fetchOptions={this.fetchCreatorList}
                                        mode={null}
                                    />
                                </Form.Item>
                                <Form.Item 
                                    label="Co-Authors"
                                    name="coAuthorUsernames"
                                    tooltip="Used mainly for credit purposes only."
                                >
                                    <AuthorSelect 
                                        placeholder={"Select or search for a user by username"}
                                        fetchOptions={this.fetchCreatorList}
                                        mode={null}
                                    />
                                </Form.Item>
                        
                                <Space size={'large'} align="start" wrap>
                                    <Space align="center">
                                        <Form.Item
                                            name='customPrice'
                                            label='Module Price'
                                            tooltip={modulePriceTooltipConfig}
                                        >
                                            <InputNumber 
                                                min={this.props.policyModel.platformMinimumPrice}
                                                onChange={value => this.formRef.current.setFieldsValue(['customPrice'], value) }
                                            />
                                        </Form.Item>
                                        cupoints
                                    </Space>
                                    + <Space direction="vertical">
                                        <Text>Licensed Content Price</Text>
                                        <InputNumber readOnly placeholder={this.calculateLicensedModulePrice()} />
                                    </Space>
                                    +
                                    <Space direction="vertical">
                                        <Text>Total Price</Text>
                                        <InputNumber readOnly placeholder={this.calculateTotalPrice()}/>
                                    </Space>
                                </Space>
                                <br/>
                                Videos:
                                <br/>
                                <VideosInModule 
                                    fieldKey={null}
                                    formRef={this.formRef}
                                    selectedVideos={this.state.selectedVideos}
                                    addVideoToModule={this.addVideoToModule}
                                    currentUser={this.props.user}
                                    addImportedVideos={this.addImportedVideos}
                                    playVideoInModal={this.selectVideoToPlay} 
                                />
                                <br/>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={this.state.submitLoading}>
                                    Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        <Card 
                            id="imported-videos-container" 
                            title={`Unassigned Videos`}
                            actions={[
                                <Text key="select-all" onClick={this.handleOnSelectAllClick} disabled={this.state.importedVideos.length ? false : true}>Select all</Text>, 
                                <Text key="unselect-all" onClick={this.handleOnUnselectAllClick} disabled={this.state.selectedVideos.length ? false : true}>Unselect All</Text>, 
                                <Text key="removed-selected" onClick={this.handleDiscardSelected} disabled={this.state.selectedVideos.length ? false : true}>Remove selected</Text>]}
                        >
                            
                            <Descriptions bordered size="small">
                                <Descriptions.Item label="Imported">{this.state.importedVideos.length}</Descriptions.Item>
                                <Descriptions.Item label="Selected">{this.state.selectedVideos.length}</Descriptions.Item>
                            </Descriptions>
                            
                            <div id="imported-videos-scroll-container">
                                <div id="imported-video-cards-wrapper"> 
                                    <Row gutter={[16,16]}>
                                    {
                                        this.state.importedVideos.map((videoData, index) => 
                                            <>
                                                <Col span={12}>
                                                <ImportedVideo 
                                                    videoData={videoData} 
                                                    key={index} 
                                                    selectVideo={this.selectVideos}
                                                    videoSelected={this.isImportedVideoSelected(videoData['embedId'])}
                                                    
                                                />
                                                </Col>
                                                <br/>
                                            </>
                                        )
                                    }
                                    </Row>
                                </div>
                            </div>
                        </Card>
                        <ModalVideoPlayer  
                            module={this.state.videoPlayedFromModule}
                            playVideoInModal={this.selectVideoToPlay}
                            visible={this.state.visiblePlayer} 
                            toggleVideoPlayerModalVisibility={this.closeVideoPlayer}
                            video={this.state.videoToPlay}
                            videoActions={['play']}
                        />
                    </div>
                </div>
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
        receivedUserCreatedCollections: state.contentCreationReducer.receivedUserCreatedCollections,
        receivedUserCreatedModules: state.contentCreationReducer.receivedUserCreatedModules,
        allModules: state.contentCreationReducer.modulePool,
        policyModel: state.contentCreationReducer.policyModel,
        moduleDetail: state.contentCreationReducer.moduleDetail,
        receivedModuleDetail: state.contentCreationReducer.receivedModuleDetail
    }
}

export default connect(mapStateToProps, {fetchModuleDetail, fetchPublicModules, fetchUserCreatedCollections, fetchUserCreatedModules, fetchUserPolicyModel, resetModuleEditData, updateModule})(ModuleEditor);