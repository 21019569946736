import Player from '@vimeo/player';
import {Component} from 'react';
import { connect } from 'react-redux';
import VideoTranscript from '../Collections/Video/Transcript/VideoTranscript';
import { setVideoTimestamp } from '../actions/videoControl';
import { getTranscript, resetTranscriptData, setTranscriptStatus } from '../actions/collection';


class VimeoPlayer extends Component {
    constructor(props){
        super(props)
        this.state = {
            currentTime: null,
        }
    }
    componentDidMount = () => {
        var options = {
            id: this.props.video.embedId,
            autoplay: true,
        };
        var player = new Player('vimeo-player', options);
        player.on('play', this.handleOnPlay);
        this.props.setVideoPlayerRef(player);

        if(this.props.video.id){
            this.props.getTranscript(this.props.video.id)
        } else {
            this.props.resetTranscriptData()
            this.props.setTranscriptStatus("LOADED")
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.video.embedId !== this.props.video.embedId){
            this.props.playerRef.loadVideo(this.props.video.embedId)
            if(this.props.video.id){
                this.props.getTranscript(this.props.video.id)
            } else {
                this.props.resetTranscriptData()
                this.props.setTranscriptStatus("LOADED")
            }   
        }
    }

    scrollToNextParagraph = (currentTime, currentElement) => {
        var transcriptDiv = document.getElementById('transcript-in-modal');
        var nextElement = currentElement.nextSibling;
        if(nextElement){
            var startTime = nextElement.getAttribute('data-start-time');
            var endTime = nextElement.getAttribute('data-end-time');
            var paragraphFlag = nextElement.getAttribute('data-paragraph-flag');
    
            if(startTime <= currentTime && endTime > currentTime && paragraphFlag === "true"){
                transcriptDiv.scrollTop = nextElement.offsetTop
            }
        }
    }
    seekOnTranscriptClick = (targetElement, timestamp) => {
        var transcriptDiv = document.getElementById('transcript-in-modal');
        transcriptDiv.scrollTop = targetElement.offsetTop
        this.props.playerRef.setCurrentTime(timestamp)
        this.props.setVideoTimestamp(timestamp)
        this.props.playerRef.getPaused().then(() =>{
            this.props.playerRef.play()
        })
    }
    
    updateVideoTimestamp = async () => {
        if(this.props.transcript && this.props.transcript.length > 0){
            var [currentTime, playbackRate] = await Promise.all([this.props.playerRef.getCurrentTime(), this.props.playerRef.getPlaybackRate()])
            var currentElement = document.getElementById('highlighted-transcript-segment');
            if(currentElement){
                this.scrollToNextParagraph(currentTime, currentElement);
            }
            this.props.setVideoTimestamp(currentTime)
            var timeout = setTimeout(this.updateVideoTimestamp, (this.props.nextTranscriptUpdate/playbackRate))
            this.setState({transcriptTimeout: timeout})
        }
    }

    handleOnPlay = () => {
        this.updateVideoTimestamp()
    }
    render(){
        return <div className="module-pool-video-player-modal-content">
            <div className="player-in-modal">
            <div id="video-wrapper"><div id='vimeo-player'></div></div>
            </div>
            <div id='transcript-in-modal'>
                <VideoTranscript seekOnTranscriptClick={this.seekOnTranscriptClick} />
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        nextTranscriptUpdate: state.videoControlReducer.nextTranscriptUpdate,
        transcript: state.collectionReducer.transcript    
    }
}
export default connect(mapStateToProps, {getTranscript, resetTranscriptData, setTranscriptStatus, setVideoTimestamp})(VimeoPlayer);