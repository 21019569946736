import { Button } from 'antd';
import { Component } from 'react';


class EditorButton extends Component {
    constructor(props){
        super(props)
    
    }

    handleOnClick = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
    }

    render() {
        let className = "";
        if(this.props.active){
            className += 'selected-rich-editor-button'
        }
        return <Button className={className} type="text" onMouseDown={this.handleOnClick}>{this.props.label}</Button>
    }
}

export default EditorButton;