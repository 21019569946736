import { Button, Card, Col, Collapse, Divider, Input, List, Modal, PageHeader, Row, Space, Typography, } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import SelectedCollection from './SelectedCollection';

const { Search } = Input;
const ImportVideoFromCollection = ({ closeModal, collections, copyModuleOrCollection, currentUser, licensedVideos, playVideoInModal, addImportedVideos, userCreatedCollections, visible}) => {
    const [selectedFilter, setSelectedFilter] = useState("public")
    const [selectedCollection, setSelectedCollection] = useState(null)
    const [collectionSearchValue, setCollectionSearchValue] = useState("")

    const determineDataSource = () => {
        let result;
        if(selectedFilter === "userCreated") {
            result = userCreatedCollections;
        } else if(selectedFilter === "public") {
            result = collections.filter(collection => collection.createdBy != currentUser.id);
        }
        return result.filter(collection => {
            return collection.name.toLowerCase().search(collectionSearchValue) != -1 || 
                collection.description.toLowerCase().search(collectionSearchValue) != -1 || 
                collection.longDescription.toLowerCase().search(collectionSearchValue) != -1 || 
                collection.primaryAuthor.firstName.toLowerCase().search(collectionSearchValue) != -1 ||
                collection.primaryAuthor.lastName.toLowerCase().search(collectionSearchValue) != -1
        })
    }
    const handleOnCollectionSearch = (value) => {
        setCollectionSearchValue(value.toLowerCase())
    }
    const handleOnCollectionSearchChange = (event) => {
        setCollectionSearchValue(event.target.value.toLowerCase())
    }
    const renderCollectionListorSingleCollection = () => {
        if(selectedCollection) {
            return <div style={{padding: "25px"}}>
                        <SelectedCollection 
                            collection={selectedCollection} 
                            clearSelectedCollection={()=>setSelectedCollection(null)}
                            addImportedVideos={addImportedVideos}
                            playVideoInModal={playVideoInModal}
                            licensedVideos={licensedVideos}
                            copyModuleOrCollection={copyModuleOrCollection}
                        />
                    </div>
        }else{
            return  <>
                        <Space style={{ padding: "10px 25px 0px 25px"}}>
                            <Search placeholder="Search for a collection" onSearch={handleOnCollectionSearch} onChange={handleOnCollectionSearchChange} allowClear style={{ width: 500 }} />

                            <Button shape="round" 
                                type={selectedFilter === "public" ? "primary" : "default"}
                                onClick={() => setSelectedFilter("public")}
                            >
                                Public
                            </Button>
                            <Button shape="round" 
                                type={selectedFilter === "userCreated" ? "primary" : "default"}
                                onClick={() => setSelectedFilter("userCreated")}
                            >
                                My Collections
                            </Button>
                        </Space>

                        <Divider style={{margin: "10px 0px 0px 0px"}}/>
                        <div style={{padding: "25px"}}>
                            <List
                                grid={{ gutter: 12, column: 4}}
                                dataSource={determineDataSource()}
                                pagination={{
                                    pageSize: 8,
                                }}
                                renderItem={collection => <List.Item key={collection.id} >
                                                            <Card
                                                                hoverable
                                                                cover={<img alt="example" src={collection.coverImage.image} />}
                                                                onClick={()=>setSelectedCollection(collection)}
                                                            >
                                                                <Card.Meta title={collection.name} />
                                                            </Card>
                                                        </List.Item>
                                            }
                            />
                        </div>
                    </>
        }
    }
    return  <Modal title="Available Collections" 
                className="import-content-from-modal"
                width="80vw"
                visible={visible}
                maskClosable={false}
                footer={[<Button onClick={closeModal} key="cancel">Cancel</Button>]}
                onCancel={closeModal}
                onOk={() => console.log("hello")}
            >
                {
                    renderCollectionListorSingleCollection()
                }    
            </Modal>
}

const mapStateToProps = state => {
    return {
        collections: state.collectionReducer.originalCollections,
        currentUser: state.userReducer.user,
        userCreatedCollections: state.contentCreationReducer.userCreatedCollections,
    }
}
export default connect(mapStateToProps, {})(ImportVideoFromCollection);