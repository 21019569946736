import React, { useState } from 'react';
import {connect} from 'react-redux';
import { Button, Form, Input, Modal } from 'antd';
import {verifyCoupon} from '../../actions/user';


const CouponSearchModal = ({isAuthenticated, verifyCoupon}) => {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [checkAttemptedCoupon, setCheckAttemptedCoupon] = useState(false);
    const [form] = Form.useForm();

    const handleOnCouponVerified = () => {
        setLoading(false)
        setVisible(false)
        form.resetFields()
    }

    const handleOnFinish = (values) => {
        if(!isAuthenticated){
            localStorage.setItem("couponCode", values['couponCode']);
            window.location.href = `https://www.api.labs.cubits.ai/accounts/login/?next=${window.location.href}`;
            return null;
        } else {
            setLoading(true)
            verifyCoupon(values['couponCode'], handleOnCouponVerified)
        }
    }

    const handleOnClose = () => {
        form.resetFields()
        setVisible(false)
    }

    if(!checkAttemptedCoupon && isAuthenticated){
        let couponCode = localStorage.getItem('couponCode');
        if(couponCode != null){
            localStorage.removeItem("couponCode")
            handleOnFinish({ couponCode: couponCode})
        }
        setCheckAttemptedCoupon(true);
    }
    return <>
            <Button onClick={() => setVisible(true)}>Have a coupon code?</Button>
            <Modal
                title="Apply a Coupon"
                visible={visible}
                footer={[
                    <Button key="cancel" onClick={handleOnClose}>Cancel</Button>,
                    <Button
                        key="submit" 
                        type="primary" 
                        htmlType="submit"
                        loading={loading}
                        onClick={() => form.submit()}
                    >
                        Apply Coupon
                    </Button>
                ]}
            > 
                <Form form={form} onFinish={handleOnFinish} style={{padding: '15px'}}>
                    <Form.Item 
                        name="couponCode"
                        rules={[{ required: true, message: 'Please input a coupon code!' }]}
                        style={{margin: 0}}
                    >
                        <Input placeholder="Enter a coupon code"/>  
                    </Form.Item>
                </Form>
            </Modal>
        </>
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated
    }
}
export default connect(mapStateToProps, {verifyCoupon})(CouponSearchModal);