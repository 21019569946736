import { Component } from 'react';
import { convertToRaw, ContentState, Editor, EditorState, Modifier, RichUtils} from 'draft-js';
import { Button } from 'antd';
import EditorButton from './EditorButton';
import { compositeDecorator } from './RichTextDecoratorConfig';
import 'draft-js/dist/Draft.css';


class RichTextEditor extends Component {

    constructor(props) {
        super(props);
        this.state = { editorState: props.allowTimestamps ? EditorState.createEmpty(compositeDecorator) : EditorState.createEmpty()};

        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.reset != this.props.reset && this.props.reset){
            this.setState({
                editorState: this.getResetEditorState(this.state.editorState)
            })
        }
    }

    // https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
    removeSelectedBlocksStyle = (editorState)  => {
        const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);

        this.props.setReset(false);
        if (newContentState) {
            return EditorState.push(editorState, newContentState, 'change-block-type');
        }
        return editorState;
    }

    // https://github.com/jpuri/draftjs-utils/blob/master/js/block.js
    getResetEditorState = (editorState) => {
        const blocks = editorState
            .getCurrentContent()
            .getBlockMap()
            .toList();
        const updatedSelection = editorState.getSelection().merge({
            anchorKey: blocks.first().get('key'),
            anchorOffset: 0,
            focusKey: blocks.last().get('key'),
            focusOffset: blocks.last().getLength(),
        });
        const newContentState = Modifier.removeRange(
            editorState.getCurrentContent(),
            updatedSelection,
            'forward'
        );

        const newState = EditorState.push(editorState, newContentState, 'remove-range');
        return this.removeSelectedBlocksStyle(newState)
    }

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
          this.onChange(newState);
          return true;
        }
        return false;
    }
    _toggleBlockType(blockType) {
        this.onChange(
          RichUtils.toggleBlockType(
            this.state.editorState,
            blockType
          )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
          RichUtils.toggleInlineStyle(
            this.state.editorState,
            inlineStyle
          )
        );
    }

    onChange = editorState => {
        this.props.customOnChange(convertToRaw(editorState.getCurrentContent()));
        this.setState({editorState}
    )};
    getBlockStyle(block) {
        switch (block.getType()) {
          case 'blockquote': return 'rich-editor-blockquote';
          case 'code-block': return 'rich-editor-code-block';
          default: return null;
        }
    }

    
    BLOCK_TYPES = [
        {label: 'H1', style: 'header-one'},
        {label: 'H2', style: 'header-two'},
        {label: 'H3', style: 'header-three'},
        {label: 'H4', style: 'header-four'},
        {label: 'H5', style: 'header-five'},
        {label: 'H6', style: 'header-six'},
        {label: 'Blockquote', style: 'blockquote'},
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'},
        {label: 'Code Block', style: 'code-block'},
    ];

    INLINE_STYLES = [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'},
        {label: 'Monospace', style: 'CODE'}
    ]

    styleMap = {
        CODE: {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
          fontSize: 16,
          padding: 2,
        },
    };

    renderEditorButtons = () => {
        const selection = this.state.editorState.getSelection();
        const blockType = this.state.editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

        if(!this.props.readOnly) {
            return <div className="rich-editor-buttons">
                        {
                            this.BLOCK_TYPES.map(type => {
                                return <EditorButton key={type.label} active={blockType == type.style} onToggle={this.toggleBlockType} style={type.style} label={type.label} />
                            })
                        }
                        <br/>
                        {this.INLINE_STYLES.map(type => {
                            return <EditorButton key={type.label} active={this.state.editorState.getCurrentInlineStyle().has(type.style)} label={type.label} style={type.style} onToggle={this.toggleInlineStyle}/>
                            }
                        )}
                    </div>
        }else {
            return;
        }
    }
    
    render() {
        
        let className = 'rich-editor-input';

        return (<div className="rich-editor">
            {this.renderEditorButtons()}
            <div className={className}>
                <Editor 
                    blockStyleFn={this.getBlockStyle}
                    editorState={this.state.editorState} 
                    customStyleMap={this.styleMap}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange} 
                    placeholder={this.props.placeholder}
                    readOnly={this.props.readOnly}
                />
            </div>
        </div>
        );
    }
}

export default RichTextEditor;