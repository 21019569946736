import {Card, Image, message, Tooltip, Typography} from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckOutlined, FileAddOutlined, PlayCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const VideoList = ({addImportedVideos, currentUser, licensedVideos, module, playVideoInModal, videoData, videoActions=[]}) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)
    const history = useHistory();

    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image 
                    src={`https://img.youtube.com/vi/${video.embedId}/0.jpg`} 
                    preview={false}
                    width="320px" 
                    height="200px"
                />
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                        width="320px" 
                        height="200px"
                    />
        }
    }

    const addToImportedVideos = () => {
        addImportedVideos([videoData])
        message.success('Video successfully imported')
    }

    const playVideo = () => {
        playVideoInModal(videoData, module)
    }


    const renderVideoActions = () => {
        const videoActionButtons = videoActions.map(videoAction => {
            switch(videoAction) {
                case "play":
                    return <Text key="play" onClick={playVideo}><PlayCircleOutlined /> Watch</Text>
                case "add":
                    if(licensedVideos.includes(videoData.id)){
                        return <Text key="added"><CheckOutlined /> Added</Text>
                    }else {
                        return <Tooltip title="Add this video to your unassigned videos"><Text key="add" onClick={addToImportedVideos}><PlusCircleOutlined key="play"/> Add Video</Text></Tooltip>
                    }
                case "createQuestions":
                    if(videoData.owner == currentUser.id || currentUser.isAdmin){
                        return <Text key='create-questions' onClick={()=>history.push(`/modules/${module.id}/videos/${videoData.id}/questions/create/`)}><FileAddOutlined /> Create Questions </Text>  
                    }else {
                        return 
                    }
                default:
                    return
            }
        })

        return videoActionButtons.filter(action => action != null);
    }
    return <Card
                className="module-pool-video-card"
                cover={getVideoThumbnail(videoData)}
                actions={renderVideoActions()}>
                <Text ellipsis={{tooltip: "HELLO"}} style={{width:"300px"}}>{videoData.title}</Text>
            </Card>

}


export default VideoList;