import { CompositeDecorator } from 'draft-js';

import TimestampComponent from './TimestampComponent';

  

const HANDLE_REGEX = /\@(?:(\d{1,2}):)?(\d{1,2}):(\d{2})/g;

function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
}


function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}


export const compositeDecorator = new CompositeDecorator([
    {
      strategy: handleStrategy,
      component: TimestampComponent,
    },
]);