import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Button, Form, Modal, Radio} from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { setDataAccessPreference } from '../../actions/user';


const DataPreferencesModal = ({accessObject, setDataAccessPreference}) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)   

    const handleSettingsClick = (event) => {
        event.stopPropagation();
        setModalVisible(true)
    }

    const handleOnCancel = () => {
        setModalVisible(false)
    }

    const handlePreferenceSaved = () => {
        setLoading(false)
        setModalVisible(false)
    }
    const handleOnFinish = (values) => {
        setLoading(true)
        setDataAccessPreference(accessObject["id"], values["dataAccessLevel"], handlePreferenceSaved)
    }

    return <>
            <Button onClick={handleSettingsClick}><SettingOutlined /></Button>
            <Modal visible={modalVisible} 
                title="Data Sharing Preferences"
                maskClosable={false}
                modalRender={modal => <div onClick={(event) => event.stopPropagation()}>{modal}</div>}
                footer={[
                    <Button key="cancel" onClick={handleOnCancel}>Cancel</Button>,
                    <Button form={`dataPreferenceForm-${accessObject.id}`} 
                            key="submit" 
                            type="primary" 
                            htmlType="submit"
                            loading={loading}
                    >
                        Set Preference
                    </Button>
                ]}
            >
                <Form id={`dataPreferenceForm-${accessObject.id}`} onFinish={handleOnFinish} initialValues={{"dataAccessLevel": accessObject.dataAccessLevel}}>
                    <Form.Item name="dataAccessLevel">
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="full">Full</Radio.Button>
                            <Radio.Button value="anonymous">Anonymous</Radio.Button>
                            <Radio.Button value="no_access">None</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
}


const mapStateToProps = state=> {
    return {
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps, {setDataAccessPreference})(DataPreferencesModal);