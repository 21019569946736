import YouTube from 'react-youtube';
import {Component} from 'react';
import {connect} from 'react-redux';
import VideoTranscript from '../Collections/Video/Transcript/VideoTranscript';
import {setVideoTimestamp} from '../actions/videoControl';
import { getTranscript, resetTranscriptData, setTranscriptStatus } from '../actions/collection';


const PLAYER_OPTIONS = {
  width: "100%",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    rel: 0
  },
};

class YouTubePlayer extends Component {

  constructor(props){
    super(props)
    this.state = {
        transcriptTimeout: null,
    }
  }

  PLAYING = 1;
  
  componentDidMount = () => {
    if(this.props.video.id){
      this.props.getTranscript(this.props.video.id)
    } else {
      this.props.resetTranscriptData()
      this.props.setTranscriptStatus("LOADED")
    }
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.video.embedId !== this.props.video.embedId){
      if(this.props.video.id){
        this.props.getTranscript(this.props.video.id)
      }else {
        this.props.resetTranscriptData()
        this.props.setTranscriptStatus("LOADED")
      }
    }
  }
  componentWillUnmount = () => {
    if(this.state.transcriptTimeout){
        clearTimeout(this.state.transcriptTimeout)
    }
    this.props.setVideoTimestamp(0)
    this.setState({transcriptTimeout: null})
  }
  

  handleOnReady = (event) => {
    this.props.setVideoPlayerRef(event.target);
  }
    
  seekOnTranscriptClick = (targetElement, timestamp) => {
    var transcriptDiv = document.getElementById('transcript-in-modal');
    transcriptDiv.scrollTop = targetElement.offsetTop

    this.props.playerRef.seekTo(timestamp)
    this.props.setVideoTimestamp(timestamp)
    if (this.props.playerRef.getPlayerState !== this.PLAYING){
        this.props.playerRef.playVideo();
    }
  }
  scrollToNextParagraph = (currentTime, currentElement) => {
    var transcriptDiv = document.getElementById('transcript-in-modal');
    var nextElement = currentElement.nextSibling;
    if(nextElement){
        var startTime = nextElement.getAttribute('data-start-time');
        var endTime = nextElement.getAttribute('data-end-time');
        var paragraphFlag = nextElement.getAttribute('data-paragraph-flag');

        if(startTime <= currentTime && endTime > currentTime && paragraphFlag === "true"){
            transcriptDiv.scrollTop = nextElement.offsetTop
        }
    }
  }

  updateVideoTimestamp = () => {
    if(this.props.transcript && this.props.transcript.length > 0){
        var currentTime = this.props.playerRef.getCurrentTime()
        var playbackRate = this.props.playerRef.getPlaybackRate()
        var currentElement = document.getElementById('highlighted-transcript-segment');
        if(currentElement){
            this.scrollToNextParagraph(currentTime, currentElement);
        }
        this.props.setVideoTimestamp(currentTime)
        var timeout = setTimeout(this.updateVideoTimestamp, (this.props.nextTranscriptUpdate/playbackRate))
        this.setState({transcriptTimeout: timeout})
    }
  }

  handleOnPlay = () => {
    if(this.state.transcriptTimeout){
        clearTimeout(this.state.transcriptTimeout)
    }
    this.updateVideoTimestamp()
  }


  render(){
    return <div className="module-pool-video-player-modal-content">
      <div style={{width: "60%", display: "inline-block"}}>
      <div id="video-wrapper">
        <YouTube videoId={this.props.video.embedId}
          opts={PLAYER_OPTIONS}
          onPlay={this.handleOnPlay}
          onReady={this.handleOnReady}
        />
      </div>
      </div>
      <div id='transcript-in-modal'>
        <VideoTranscript seekOnTranscriptClick={this.seekOnTranscriptClick} />
      </div>
    </div>
  }
}
const mapStateToProps = state => {
  return {
    nextTranscriptUpdate: state.videoControlReducer.nextTranscriptUpdate,
    transcript: state.collectionReducer.transcript
  }
}


export default connect(mapStateToProps, { getTranscript, resetTranscriptData, setTranscriptStatus, setVideoTimestamp })(YouTubePlayer);