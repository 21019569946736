import React, {Component} from 'react';
import { Layout, Menu } from 'antd';

import {connect} from 'react-redux';
import {Switch, Route, Link, withRouter} from "react-router-dom";
import RedirectableRoute from './RedirectableRoute';
import Home from './Home';
import LoginLogoutButtons from './LoginLogoutButtons';
import CollectionList from './Collections/List/CollectionList';
import CollectionShow from './Collections/Show/CollectionShow';
import CollectionCreator from './ContentCreation/Collections/CollectionCreator';
import CollectionEditor from './ContentCreation/Collections/CollectionEditor';
import CreatorSignup from './CreatorSignup';
import UserCreatedCollectionDetail from './ContentCreation/CreatorPanel/UserCreatedCollectionDetail';
import ModuleCreator from './ContentCreation/Modules/ModuleCreator';
import ModuleEditor from './ContentCreation/Modules/ModuleEditor'
import PurchasedContent from './Collections/PurchasedContent/PurchasedContent';
import CreatorHome from './ContentCreation/CreatorPanel/CreatorHome';
import UsedCouponPage from './UsedCoupons/UsedCouponPage';
import QuestionCreator from './ContentCreation/CreatorPanel/QuestionsCreator/QuestionCreator';
import VideoPage from './Collections/Video/VideoPage';
import WalletPage from './Wallet/WalletPage';
import PageNotFound from './PageNotFound';
import { fetchOriginalCollections } from './actions/collection.js';
import { fetchJWTTokens, fetchUserData } from './actions/user.js';
import Hugo from './Hugo';
import './App.less';
import logo from './cubits-logo.png';


const { Header, Content, Footer } = Layout;


class App extends Component {
  componentDidMount() {
    this.props.fetchOriginalCollections()
    if(localStorage.getItem("access") === null){
      this.props.fetchJWTTokens()
    } else if(localStorage.getItem("access") !== null){
      this.props.fetchUserData()
    }
  }
  componentDidUpdate(prevProps) {
    if(prevProps.requireLogin !== this.props.requireLogin && prevProps.requireLogin === true) {
      this.props.fetchUserData()
    }
  }

  render() {
    return (
      <Layout>
        <Header id='header'>
          <Link to='/' className='logo' ><img alt="cubits-logo" src={logo}/> cubits</Link>
          
          <Menu theme="dark" mode="horizontal" style={{float: 'left'}}>

            <Menu.Item key="1"><Link to='/collections/'>Our Collections</Link></Menu.Item>
            <Menu.Item key="2">About Us</Menu.Item>
          </Menu>

          <LoginLogoutButtons user={this.props.user || ''} />
        </Header>

        <Content className="site-layout" style={{marginTop: 64, minHeight: "calc(100vh - 134px)"}}>
          <Switch>
          <Route path="/" exact component={Home}/>
            <RedirectableRoute path='/aggregator/apply/' component={CreatorSignup} />
            <RedirectableRoute path='/collections/create/' exact component={CollectionCreator} />
            <RedirectableRoute path='/collections/:collectionId/edit/' exact component={CollectionEditor} />
            <RedirectableRoute path='/collections/:collectionId/modules/:moduleId/videos/:videoId' exact component={VideoPage}/>
            <RedirectableRoute path="/collections/:collectionId" exact component={CollectionShow} />
            <RedirectableRoute path='/collections/' exact component={CollectionList} />
            <RedirectableRoute path='/creator/home/' exact component={CreatorHome}/>
            <RedirectableRoute path='/creator/created-collections/:collectionId' exact component={UserCreatedCollectionDetail} />
            <RedirectableRoute path='/modules/create/' exact component={ModuleCreator} /> 
            <RedirectableRoute path='/modules/:moduleId/edit/' exact component={ModuleEditor} />
            <RedirectableRoute path='/modules/:moduleId/videos/:videoId/questions/create/' exact component={QuestionCreator} />
            <RedirectableRoute path='/purchased-content/' exact component={PurchasedContent} />
            <RedirectableRoute path='/account' exact component={WalletPage}/>
            <RedirectableRoute path='/scheduled-licenses' exact component={UsedCouponPage}/>
            <RedirectableRoute component={PageNotFound} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center' }}>CUbits ©2021</Footer>
      </Layout>
  )}
}

const mapStateToProps = state => {
  return {
    user: state.userReducer.user,
    requireLogin: state.userReducer.requireLogin
  }
}
export default connect(mapStateToProps, {fetchOriginalCollections, fetchJWTTokens, fetchUserData})(withRouter(App));
