import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout, Menu } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';


const { SubMenu } = Menu;

class VideoPageMenu extends Component {
 
    render() {
        return (
            <>
            <Layout.Sider id="video-page-menu"
                width={"15.625vw"}
                collapsedWidth={0} 
                collapsible 
                collapsed={this.props.isMenuCollapsed} 
                trigger={null}
            >
                <Menu mode="inline" 
                    onClick={this.handleClick} 
                    theme="dark" 
                    defaultOpenKeys={[`sub${this.props.currentModule.id}`]}
                    defaultSelectedKeys={[`${this.props.currentModule.id}-${this.props.currentVideo.id}`]}
                >
                    {this.props.collection.modules.map((module) => {
                        return <SubMenu key={`sub${module.id}`} title={module.name} disabled={!this.props.user.moduleAccess[module.id]}>
                            {module.videos.map((video) => {
                                return  <Menu.Item key={`${module.id}-${video.id}`} title={video.title} icon={<PlayCircleOutlined />}>   
                                            <Link to={this.props.unlistedKey != null ? `/collections/${this.props.collection.id}/modules/${module.id}/videos/${video.id}/?key=${this.props.unlistedKey}` : `/collections/${this.props.collection.id}/modules/${module.id}/videos/${video.id}/`} >
                                                {video.title}
                                            </Link>
                                        </Menu.Item>
                            })}
                        </SubMenu>
                    })}
                </Menu>
            </Layout.Sider></>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        collection: state.collectionReducer.singleCollection,
        currentModule: state.collectionReducer.singleModule,
        currentVideo: state.collectionReducer.singleVideo
    }
}
export default connect(mapStateToProps, {})(VideoPageMenu);