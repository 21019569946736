import { message } from "antd"
import {getNewAccessToken} from './user.js';
import { updateOriginalCollection } from './collection';


export const clearCollectionDetail = () => {
    return {
        type: "CLEAR_COLLECTION_DETAIL"
    }
}
export const clearUserCreatedModules = () => {
    return {
        type: "CLEAR_USER_CREATED_MODULES"
    }
}

export const clearUserCreatedCollections = () => {
    return {
        type: "CLEAR_USER_CREATED_COLLECTIONS"
    }
}

export const clearPolicyModel = () => {
    return {
        type: "CLEAR_POLICY_MODEL"
    }
}

export const removeDeletedQuizQuestion = (questionId) => {
    return {
        type: "REMOVE_DELETED_QUIZ_QUESTION",
        questionId
    }
}

export const setFetchingUserCreatedCollections = (status) => {
    return {
        type: "SET_FETCHING_USER_CREATED_COLLECTIONS",
        status
    }
}

export const setFetchingUserCreatedModules = (status) => {
    return {
        type: "SET_FETCHING_USER_CREATED_MODULES",
        status
    }
}

export const setCollectionDetail = (collectionData) => {
    return {
        type: "SET_COLLECTION_DETAIL",
        collectionData
    }
}

export const setModuleDetail = (moduleData) => {
    return {
        type: "SET_MODULE_DETAIL",
        moduleData
    }
}

export const setModulePool = (modules) => {
    return {
        type: "SET_MODULE_POOL",
        modules
    }
}

export const setPolicyModel = (policyModel) => {
    return {
        type: "SET_POLICY_MODEL",
        policyModel
    }
}

export const setScheduledLicensesForCollectionDetail = (licenses) => {
    return {
        type: "SET_SCHEDULED_LICENSES_FOR_COLLECTION_DETAIL",
        licenses
    }
}

export const setReceivedScheduledLicensesStatus = (status) => {
    return {
        type: "SET_RECEIVED_SCHEDULED_LICENSES_STATUS",
        status
    }
}
export const setUserCreatedModules = (modules) => {
    return {
        type: "SET_USER_CREATED_MODULES",
        modules
    }
}

export const setUserCreatedCollections = (collections) => {
    return {
        type: "SET_USER_CREATED_COLLECTIONS",
        collections
    }
}

export const setQuizQuestionsForVideo = (quizQuestions) => {
    return {
        type: "SET_QUIZ_QUESTIONS_FOR_VIDEO",
        quizQuestions
    }
}

export const setQuizQuestionsForVideoStatus = (status) => {
    return {
        type: "SET_QUIZ_QUESTIONS_FOR_VIDEO_STATUS",
        status
    }
}

export const setNotifyToEditLicenseOnCollectionChangeStatus = (status) => {
    return {
        type: "NOTIFY_TO_EDIT_LICENSE_ON_COLLECTION_CHANGE_STATUS",
        status
    }
}
export const resetReceivedCollectionDetail = (status) => {
    return {
        type: "RESET_RECEIVED_COLLECTION_DETAIL",
        status
    }
}

export const resetCollectionEditData = () => {
    return {
        type: "RESET_COLLECTION_EDIT_DATA"
    }
}
export const resetModuleEditData = () => {
    return {
        type: "RESET_MODULE_EDIT_DATA"
    }
}


export const addNewlyCreatedModule = (moduleData) => {
    return {
        type: "ADD_NEWLY_CREATED_MODULE",
        moduleData
    }
}

export const addNewlyCreatedCollection = (collectionData) => {
    return {
        type: "ADD_NEWLY_CREATED_COLLECTION",
        collectionData
    }
}

export const addNewlyCreatedScheduledLicense = (licenseData) => {
    return {
        type: "ADD_NEWLY_CREATED_SCHEDULED_LICENSE",
        licenseData
    }
}

export const addNewlyCreatedQuizQuestion = (quizQuestionData) => {
    return {
        type: "ADD_NEWLY_CREATED_QUIZ_QUESTION",
        quizQuestionData
    }
}

export const updateNewlyEditedCollection = (collectionData) => {
    return {
        type: "UPDATE_NEWLY_EDITED_COLLECTION",
        collectionData
    }
}

export const updateNewlyEditedModule = (moduleData) => {
    return {
        type: "UPDATE_NEWLY_EDITED_MODULE",
        moduleData
    }
}

export const updateCollectionCoverImage = (imageUrl) => {
    return {
        type: "UPDATE_COLLECTION_COVER_IMAGE",
        imageUrl
    }
}

export const updateCreatedScheduledLicense = (licenseData) => {
    return {
        type: "UPDATE_CREATED_SCHEDULED_LICENSE",
        licenseData
    }
}

export const fetchUserPolicyModel = (userId) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/policymodel/`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setPolicyModel (data))
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchUserPolicyModel(userId)))
            }
        })
    }
}
export const fetchPublicModules = () => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/modules/`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setModulePool(data))
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchPublicModules()))
            }
        })
    }
}

export const fetchUserCreatedModules = (userId) => {
    return dispatch => {
        dispatch(setFetchingUserCreatedModules(true))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/modules/`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setUserCreatedModules(data))
            dispatch(setFetchingUserCreatedModules(false))
        })
        .catch(error => {
            dispatch(setFetchingUserCreatedModules(false))
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchUserCreatedModules(userId)))
            }
        })
    }
}

export const fetchCollectionDetail = (collectionId, userId, handleNotFound) => {
    return dispatch => {
        dispatch(resetReceivedCollectionDetail(false))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/collections/${collectionId}/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access'),
                        'Content-Type': 'application/json'
                    }
                })
                .then(response=>{
                    if(response.ok){
                        return response.json()
                    }else {
                        throw new Error(response.status);
                    }
                })
                .then(data => dispatch(setCollectionDetail(data)))
                .catch(error => {
                    if(parseInt(error.message) === 401){
                        dispatch(getNewAccessToken(()=>fetchCollectionDetail(collectionId, handleNotFound)))
                    }else if(parseInt(error.message) === 404){
                        handleNotFound()
                    }
                })
    }
}

export const fetchModuleDetail = (moduleId, userId, handleNotFound) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/modules/${moduleId}/`,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response=>{
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => dispatch(setModuleDetail(data)))
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchModuleDetail(moduleId, handleNotFound)))
            }else if(parseInt(error.message) === 404){
                handleNotFound()
            }
        })
    }
}

export const fetchUserCreatedCollections = (userId) => {
    return dispatch => {
        dispatch(setFetchingUserCreatedCollections(true))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/collections/`,{
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setUserCreatedCollections(data))
            dispatch(setFetchingUserCreatedCollections(false))
        })
        .catch(error => {
            dispatch(setFetchingUserCreatedCollections(false))
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>fetchUserCreatedCollections(userId)))
            }
        })
    }
}

export const createNewModule = (newModuleFormData, handleAfterCreate, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/modules/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...newModuleFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                let error = new Error(response.status);
                error.response = response
                throw error
            }
        })
        .then(data => {
            dispatch(addNewlyCreatedModule(data))
            handleAfterCreate()
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> createNewModule(newModuleFormData, handleAfterCreate, finishLoading)))
            } else if(parseInt(error.message) === 400) {
                // need to display errors
                error.response.json().then(data => {
                    console.log(data)
                })
                finishLoading(false)
            }else {
                console.log(error)
            }
        })
    }
}

export const toggleModulePrivacyScope = (moduleId, currentPrivacyScope, handleAfterUpdate) => {
    let newPrivacyScope;
    if(currentPrivacyScope === "private"){
        newPrivacyScope = "public";
    }else{
        newPrivacyScope = "private";
    }
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/modules/${moduleId}/`, {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'privacyScope': newPrivacyScope
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(updateNewlyEditedModule(data))
            message.success(`Privacy scope updated for module, "${data['name']}"`)
            handleAfterUpdate()
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> toggleModulePrivacyScope(moduleId, currentPrivacyScope, handleAfterUpdate)))
            } else if(parseInt(error.message) === 400){
                handleAfterUpdate()
            }else {
                console.log(error) 
            }
        })
    }
}
export const updateModule = (moduleId, editedModuleFormData, handleAfterUpdate, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/modules/${moduleId}/`,{
            method: "PUT",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...editedModuleFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                let error = new Error(response.status);
                error.response = response
                throw error
            }
        })
        .then(data => {
            dispatch(updateNewlyEditedModule(data))
            message.success(`Module successfully updated.`)
            handleAfterUpdate()
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> updateModule(moduleId, editedModuleFormData, handleAfterUpdate, finishLoading)))
            } else if(parseInt(error.message) === 400) {
                // need to display errors
                error.response.json().then(data => {
                    console.log(data)
                })
                finishLoading(false)
            }else {
                console.log(error)
            }
        })
    }
}

export const fetchVideoDataFromURL = (URL, parseVimeoData, parseYouTubeData, handleOnNewVideoByURLError) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/retrieve-video-playlist-data/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "url": URL
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data=> {
            switch(data['videoType']){
                case "youtube":
                    return parseYouTubeData(data)
                case "vimeo":
                    return parseVimeoData(data)
                default:
                    return
            }
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> fetchVideoDataFromURL(URL, parseVimeoData, parseYouTubeData, handleOnNewVideoByURLError)))
            }else if(parseInt(error.message) === 400){
                handleOnNewVideoByURLError()
            }else {
                console.log(error)
            }
        })
    }
}


export const createCollection = (newCollectionFormData, handleAfterCreate, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...newCollectionFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(addNewlyCreatedCollection(data))
            finishLoading(true)
            message.success("Collection created")
            return handleAfterCreate(data['id'])
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> createCollection(newCollectionFormData, handleAfterCreate, finishLoading)))
            }else if(parseInt(error.message) === 400){
               
            }else {
                console.log(error)
            }
        })
    }
}

export const updateCollection = (collectionId, updatedCollectionFormData, handleAfterUpdate, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...updatedCollectionFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(updateNewlyEditedCollection(data))
            if(data['originalCollection']){
                dispatch(updateOriginalCollection(data))
            }
            finishLoading(true)
            message.success("Collection created")
            return handleAfterUpdate()
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> updateCollection(collectionId, updatedCollectionFormData, handleAfterUpdate, finishLoading)))
            }else if(parseInt(error.message) === 400){
               
            }else {
                console.log(error)
            }
        })
    }
}

export const fetchCollectionDetailScheduledLicenses = (collectionId, userId) => {
    return dispatch => {
        dispatch(setReceivedScheduledLicensesStatus(false))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/users/${userId}/collections/${collectionId}/scheduled-licenses/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(setScheduledLicensesForCollectionDetail(data))
            dispatch(setReceivedScheduledLicensesStatus(true))
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> fetchCollectionDetailScheduledLicenses(collectionId, userId) ))
            }else if(parseInt(error.message) === 400){
               
            }else {
                console.log(error)
            }
        })
    }
}

export const createScheduledLicense = (scheduledLicenseFormData, afterCreationSuccess, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/coupons/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...scheduledLicenseFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(addNewlyCreatedScheduledLicense(data))
            finishLoading(true)
            message.success("License created")
            return afterCreationSuccess(data)
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> createScheduledLicense(scheduledLicenseFormData, afterCreationSuccess, finishLoading)))
            }else if(parseInt(error.message) === 400){
                message.error("Failed to create coupon.")
                finishLoading(true)
            }else {
                console.log(error)
            }
        })
    }
}

export const updateScheduledLicense = (couponCode, scheduledLicenseFormData, afterUpdateSuccess, finishLoading) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/coupons/${couponCode}/`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...scheduledLicenseFormData,
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(updateCreatedScheduledLicense(data))
            finishLoading(true)
            message.success("License updated")
            return afterUpdateSuccess()
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> updateCreatedScheduledLicense(scheduledLicenseFormData, afterUpdateSuccess, finishLoading)))
            }else if(parseInt(error.message) === 400){
                message.error("Failed to update coupon.")
                finishLoading(true)
            }else {
                console.log(error)
            }
        })
    }
}

export const getAllQuestionsForVideo = (videoId) => {
    return dispatch => {
        dispatch(setQuizQuestionsForVideoStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/videos/${videoId}/allquizquestions/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(setQuizQuestionsForVideo(data))
            dispatch(setQuizQuestionsForVideoStatus("LOADED"))
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> getAllQuestionsForVideo(videoId)))
            }else {
                console.log(error)
            }
        })
    }
}

export const createQuizQuestion = (videoId, quizQuestionFormData, handleAfterSuccess) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/videos/${videoId}/quizquestions/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(quizQuestionFormData)
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(addNewlyCreatedQuizQuestion(data))
            handleAfterSuccess()
        })
        .catch(error=> {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> createQuizQuestion(videoId, quizQuestionFormData, handleAfterSuccess)))
            }else {
                console.log(error)
            }
        })
    }
}

export const deleteQuizQuestion = (videoId, questionId, afterDeleteSuccess) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/videos/${videoId}/quizquestions/${questionId}/`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),

            }
        })
        .then(response => {
            if(response.ok){
                dispatch(removeDeletedQuizQuestion(questionId))
                afterDeleteSuccess()
                message.success("Quiz question successfully deleted.")
            }else {
                throw new Error(response.status)
            }
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=> deleteQuizQuestion(videoId, questionId)))
            }else {
                message.error("Failed to delete quiz question")
            }
        })
    }
}