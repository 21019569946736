import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { Affix, Button, Dropdown, Layout, message, Menu, PageHeader, Space, Spin, Switch, Typography} from 'antd';
import {toggleMutedQuizQuestions} from '../../actions/videoControl';
import { clearSelectedModuleAndVideo, getQuizQuestions, fetchSingleCollection, getTranscript, getVideoData} from '../../actions/collection';
import {LoadingOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import VideoPageMenu from './VideoPageMenu';
import VimeoPlayer from './Player/VimeoPlayer';
import YouTubePlayer from './Player/YouTubePlayer';
import QnAContainer from './Q&A/QnAContainer';


const {Title} = Typography;

class VideoPage extends Component {
    constructor(props){
        super(props)
        let affixStatus = localStorage.getItem('videoPageAffix')
        this.state = {
            collapsedMenu: false,
            collapsedTranscript: false,
            customStartTime: null,
            openAffixDropdown: (typeof affixStatus !== "undefined") && affixStatus !== null ? affixStatus === "true" : false,
            selectedQnALevel: "video"
        }
    }
    componentDidMount() {
        // fetch transcript text
        let query = new URLSearchParams(this.props.location.search)
        let startTime = query.get('t');
        if(startTime){
            this.setState({customStartTime: parseFloat(startTime)})
        }
        if(!this.props.singleCollectionIsSet){
            
           
            this.props.fetchSingleCollection(this.props.match.params.collectionId, query.get('key'), this.collectionNotFound)
        }else {
            this.props.getVideoData(this.props.match.params)
            this.props.getTranscript(this.props.match.params['videoId'])
            this.props.getQuizQuestions(this.props.match.params['collectionId'], this.props.match.params['moduleId'], this.props.match.params['videoId'])
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.singleCollectionIsSet !== this.props.singleCollectionIsSet && this.props.singleCollectionIsSet) || !this.equalUrlParams(prevProps.match.params, this.props.match.params)) {
            this.props.getVideoData(this.props.match.params)
            this.props.getTranscript(this.props.match.params['videoId'])
            this.props.getQuizQuestions(this.props.match.params['collectionId'], this.props.match.params['moduleId'], this.props.match.params['videoId'])
        }
    }

    componentWillUnmount(){
        this.props.clearSelectedModuleAndVideo()
    }

    collectionNotFound = () => {
        this.props.history.push('/404')
    }

    equalUrlParams = (prev, current) => {
        return prev['collectionId'] === current['collectionId'] && prev['moduleId'] === current['moduleId'] && prev['videoId'] === current['videoId']
    }

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last || route.path == null ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        let query = new URLSearchParams(this.props.location.search)

        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: 'Home'
                }, {
                    path: "/collections/",
                    breadcrumbName: "Collections"
                }, 
                {
                    path: `/collections/${this.props.singleCollection.id}/${query.get('key') ? "?key=" + query.get('key') : ""}`,
                    breadcrumbName: this.props.singleCollection.name
                },
                {
                    breadcrumbName: this.props.singleModule.name
                },
                {
                    breadcrumbName: this.props.singleVideo.title
                }
            ]
        }
    }
    toggleCollapsedMenu = () => {
        this.setState({
            collapsedMenu: !this.state.collapsedMenu
        })
    }
    toggleCollapsedTranscript = () => {
        this.setState({
            collapsedTranscript: !this.state.collapsedTranscript
        })
    }

    scrollToTopOfQnA = () => {
        let element = document.getElementById('video-page-extra-content-layout');
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    scrollToTopOfVideoPlayer = () => {
        let element = document.getElementById('video-show-page');
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    setQnALevel = (value) => {
        this.setState({
            selectedQnALevel: value
        })
    }

    menu = (
        <Menu>
            <Menu.Item onClick={this.scrollToTopOfVideoPlayer}>
                Video Player
            </Menu.Item>
            <Menu.ItemGroup title="Q&A">
                <Menu.Item onClick={()=>{
                    this.setQnALevel("collection")
                    this.scrollToTopOfQnA()
                }}>
                    For Collection
                </Menu.Item>
                <Menu.Item onClick={()=>{
                    this.setQnALevel("module")
                    this.scrollToTopOfQnA()
                }}>
                    For Module
                </Menu.Item>
                <Menu.Item onClick={()=>{
                    this.setQnALevel("video")
                    this.scrollToTopOfQnA()
                }}>
                    For Video
                </Menu.Item>
            </Menu.ItemGroup>
           
      </Menu>
    )

    render() {
        if((this.props.fetchingCollections || this.props.fetchingUser) && localStorage.getItem("access") != null){
            return <Space align='center'>
                <Spin indicator={<LoadingOutlined spin style={{ fontSize: 50 }}/>}/> 
                <Title >Loading the video data...</Title>
            </Space>
        }
        if(!this.props.isAuthenticated && (!this.props.fetchingUser) && localStorage.getItem("access") === null){
            let query = new URLSearchParams(this.props.location.search)
            message.warning("You must login before accessing this content.")
            return <Redirect to={`/collections/${this.props.match.params.collectionId}/${query.get('key') ? "?key=" + query.get('key') : ""}`} />
        }
        if(!this.props.contentDoesNotExist && (this.props.fetchingUser || this.props.fetchingCollections || !this.props.singleCollectionIsSet || !this.props.singleVideoIsSet || !this.props.singleModuleIsSet)){
            return <Space align='center'>
                <Spin indicator={<LoadingOutlined spin style={{ fontSize: 50 }}/>}/> 
                <Title >Loading the video data...</Title>
            </Space>
        }

        if(!this.props.user.moduleAccess[this.props.match.params.moduleId]){
            let query = new URLSearchParams(this.props.location.search)
            message.error("You do not have access to this content. Please purchase the module to continue.", 0)
            return <Redirect to={`/collections/${this.props.match.params.collectionId}/${query.get('key') ? "?key=" + query.get('key') : ""}`} />
        }

        if(this.props.contentDoesNotExist){
            return <Redirect to='/404' />
        }
       
        return <div id="video-show-page" className="clearfix">
                    
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title={this.props.singleVideo.title}
                        breadcrumb={this.breadcrumbConfig()}
                        extra={[<Space align="center" key={1}>
                        <Switch size="small" onChange={this.props.toggleMutedQuizQuestions} checked={this.props.mutedQuizQuestions}/>Mute Quiz Questions
                        <Switch size="small" onChange={this.toggleCollapsedMenu} checked={!this.state.collapsedMenu}/>
                        {this.state.collapsedMenu ? "Show Menu" : "Hide Menu"}
                        <Switch size="small" onChange={this.toggleCollapsedTranscript} checked={!this.state.collapsedTranscript}/>
                        {this.state.collapsedTranscript ? "Show Transcript" : "Hide Transcript"}
                    </Space>
                        
                        ]}
                    >
                        
                    </PageHeader>
        
                    <Layout id="video-page-layout">
                        <VideoPageMenu isMenuCollapsed={this.state.collapsedMenu} unlistedKey={(new URLSearchParams(this.props.location.search)).get('key')}/>
                        
                        {this.props.singleVideo.videoType === "youtube" ? <YouTubePlayer 
                                                                            collection={this.props.singleCollection} 
                                                                            module={this.props.singleModule} 
                                                                            video={this.props.singleVideo} 
                                                                            customStartTime={this.state.customStartTime}
                                                                            collapsedTranscript={this.state.collapsedTranscript}
                                                                        /> 
                                                                        : <VimeoPlayer 
                                                                            collection={this.props.singleCollection} 
                                                                            module={this.props.singleModule} 
                                                                            video={this.props.singleVideo} 
                                                                            customStartTime={this.state.customStartTime}
                                                                            collapsedTranscript={this.state.collapsedTranscript}
                                                                        />}
                       
                    </Layout>

                    <Layout id='video-page-extra-content-layout'>
                        <QnAContainer 
                            collectionId={this.props.match.params.collectionId}
                            moduleId={this.props.match.params.moduleId}
                            videoId={this.props.match.params.videoId}
                            selectedQnALevel={this.state.selectedQnALevel}
                            setSelectedQnALevel={this.setQnALevel}
                        />
                    </Layout>
                    <Affix style={{position: "fixed", bottom: 20, right: 20}}>
                        <Dropdown overlay={this.menu} placement="topLeft" visible={this.state.openAffixDropdown} getPopupContainer={trigger => trigger.parentElement}>
                            <Button 
                                type="primary" 
                                shape="circle"
                                onClick={()=>{
                                    this.setState({ 
                                        openAffixDropdown: !this.state.openAffixDropdown
                                    })
                                    localStorage.setItem('videoPageAffix', !this.state.openAffixDropdown)
                                }}
                            >
                                <QuestionCircleOutlined />
                            </Button>
                        
                        </Dropdown>
                    </Affix>
                </div>
    }
} 

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        contentDoesNotExist: state.collectionReducer.contentDoesNotExist,
        collectionQnA: state.collectionReducer.collectionQnA,
        moduleQnA: state.collectionReducer.moduleQnA,
        videoQnA: state.collectionReducer.videoQnA,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        singleCollection: state.collectionReducer.singleCollection,
        singleModule: state.collectionReducer.singleModule,
        singleVideo: state.collectionReducer.singleVideo,
        singleCollectionIsSet: state.collectionReducer.singleCollectionIsSet,
        singleModuleIsSet: state.collectionReducer.singleModuleIsSet,
        singleVideoIsSet: state.collectionReducer.singleVideoIsSet,
        fetchingCollections: state.collectionReducer.fetchingCollections,
        mutedQuizQuestions: state.videoControlReducer.mutedQuizQuestions
    }
}

export default connect(mapStateToProps, {clearSelectedModuleAndVideo, fetchSingleCollection, getTranscript, getQuizQuestions, toggleMutedQuizQuestions, getVideoData})(VideoPage)
