import { Component } from 'react';
import { convertToRaw, Editor, EditorState, RichUtils} from 'draft-js';
import { Button } from 'antd';
import EditorButton from './EditorButton';
import 'draft-js/dist/Draft.css';


const RichTextPreview = ({editorState, placeholder}) => {

    const getBlockStyle = (block) => {
        switch (block.getType()) {
          case 'blockquote': return 'rich-editor-blockquote';
          case 'code-block': return 'rich-editor-code-block';
          default: return null;
        }
    }

    const styleMap = {
        CODE: {
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
          fontSize: 16,
          padding: 2,
        },
    };

    let className = 'rich-editor-preview';

    return (<div>
        <div className={className}>
            <Editor 
                blockStyleFn={getBlockStyle}
                editorState={editorState} 
                customStyleMap={styleMap}
                readOnly={true}
                placeholder={placeholder}
            />
        </div>
    </div>
    );
}

export default RichTextPreview;