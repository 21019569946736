import React, { Component, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { Button, Col, Descriptions, message, PageHeader, Row, Space, Spin, Statistic, Table, Tag, Tooltip, Typography } from 'antd';
import {LoadingOutlined, QuestionCircleOutlined} from '@ant-design/icons';

import TopUpForm from "./TopUpForm";
import TransactionRefundButton from './TransactionRefundButton';
import { issueRefund } from "../actions/user";


const {Title, Text} = Typography;

class WalletPage extends Component {

    columns = [
        {title: 'Order #', dataIndex: 'orderId', key: 'orderId'}, 
        {title: 'Transaction Type', dataIndex: 'transactionType', key: 'transationType', render: (type) => {
            switch(type){
                case "topup":
                    return <Tag color="#87d068">{type.toUpperCase()}</Tag>
                case "purchase":
                    return <Tag color="#f50">{type.toUpperCase()}</Tag>
                case "refund":
                    return <Tag color="#FCAE1E">{type.toUpperCase()}</Tag>
                default:
                    return
            }
        }}, 
        {title: 'Cupoints Amount', dataIndex: 'transactionTotalCupoints', key: 'transactionTotalCupoints'}, 
        {title: 'Currency Amount', dataIndex: 'transactionTotalCurrency', key: 'transactionTotalCurrency'}, 
        {title: 'Discount', dataIndex: 'discount', key: 'discount', render: (discount) => { return `${parseFloat(discount) * 100}%`}}, 
        {title: "Memo", dataIndex: 'memo', key: 'memo' }, 
        {title: "Date/Time", dataIndex: 'date', key: 'date', render: (transactionDate) => {
            var date = new Date(transactionDate)
            return <Text>{date.toDateString()}</Text>
        }},
        {title: 'Action', dataIndex: '', key: 'refundable', render: (instance) => {
            return <TransactionRefundButton transaction={instance} issueRefund={this.props.issueRefund} user={this.props.user}/>
        }}
    ]

    render() {
        if(this.props.fetchingUser || (!this.props.isAuthenticated && localStorage.getItem("access"))){
            return <Space align='center'>
                <Spin indicator={<LoadingOutlined spin style={{ fontSize: 50 }}/>}/> 
                <Title >Loading...</Title>
            </Space>
        }
        if(!localStorage.getItem("access")){
            message.warning("Please login to your account to view your account details.")
            return <Redirect to='/login' />
        }

        return <div id="wallet-dashboard">
            <br/>
            <PageHeader
                className="site-page-header-responsive"
                ghost={false}
                onBack={() => window.history.back()}
                title="Account Dashboard"
                subTitle={this.props.user.username}
                extra={[<Link to={{ pathname: "https://www.api.labs.cubits.ai/accounts/settings/" }} target="_blank"><Button type="primary">Manage Account</Button></Link>]}
            >
                <Descriptions size="small" column={2}>
                    <Descriptions.Item label="Join Date">{new Date(this.props.user.dateJoined).toDateString()}</Descriptions.Item>
                    <Descriptions.Item label="Account Type">{this.props.user.isContentCreator ? "Content Creator" : "General User"}</Descriptions.Item>
                </Descriptions>
               

            </PageHeader>
            <br/>
            <PageHeader
                className="site-page-header-responsive"
                ghost={false}
                title="My Wallet"
                subTitle={<Tooltip title="CUvids wallet is a new and an economical way to subscribe to studio produced curated videos as necessary. You decide when and what videos to subscribe. Based on your needs, you may spend $10 or more to get what you need. A minimum deposit to a wallet of $10 buys 1000 cupoints that can be used to subscribe to up to 25 curated videos. Each cuvids module (4-6 videos) is priced at 200 cupoints or $2." color="cyan">
                            <Text type="secondary">What is a wallet?</Text>
                        </Tooltip>}
            >
                <Row gutter={[16,32]} style={{width: "100%"}}>
                    <Col span={6}>                
                        <Statistic title="Wallet Currency Abbreviation" value={this.props.user.primaryWallet.currency.abbreviation} />
                    </Col>
                    <Col span={8}>                
                        <Statistic title="Wallet Currency Name" value={this.props.user.primaryWallet.currency.fullName} />
                    </Col>
                    <Col span={3}>
                        <Statistic title={<><Text>Available Cupoints </Text><Tooltip title="Cupoints is an internal point system used to reward authors and gamify the platform. 100 cupoints = 1 US $"><QuestionCircleOutlined /></Tooltip></>} value={this.props.user.primaryWallet.cupointsBalance} />
                        
                    </Col>
                    <Col span={4} >
                       <TopUpForm />
                    </Col>
                    <Col span={24}>
                        <Title level={5}>Transactions</Title>
                        <Table columns={this.columns} dataSource={this.props.user.primaryWallet.transactions} rowKey="id" bordered={true}/>
                    </Col>
                </Row>
            </PageHeader>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
    }
}

export default connect(mapStateToProps, {issueRefund})(WalletPage);