import { Card, Col, Form, Row, Typography} from 'antd';
import VideoCard from './VideoCard';

const { Title } = Typography;
const VideosInModule = (props) => {
    let listName = props.fieldKey != null? [props.fieldKey, "videos"] : ["videos"];

    return <Form.List 
                name={listName}
                rules = {[{
                    validator: async (_, videos) => {
                        if (!videos || videos.length < 1) {
                            return Promise.reject(new Error('Your module must have at least 1 video'));
                        }
                    }
                }]}
            > 
                {(fields, { add, move, remove }, {errors}) => {
                    const addVideo = () => {
                        props.selectedVideos.forEach(videoData => {
                            add(videoData)
                        })
                        props.addVideoToModule()
                    }

                    return <><Card 
                                className={`videos-in-module-list ${props.selectedVideos.length > 0 ? "highlighted-video-list" : ""}`}
                                onClick={props.selectedVideos.length > 0 ? addVideo : null}
                            >
                                <div className={`${props.selectedVideos.length > 0 ? "highlighted-video-list-blur" : ""}`}>
                                    <Row gutter={[16, 24]} wrap={false} className="module-video-list">
                                      
                                        {fields.length === 0 ? <h5>No videos added yet</h5> : null}
                                        
                                        {fields.map((videoField, index) => {
                                    
                                            const shiftVideoLeft = () => {
                                                if(index > 0) { move(index, index - 1) };
                                            };
                                            const shiftVideoRight = () => {
                                                if(index + 1  < fields.length) { move(index, index + 1) };
                                            };

                                            return <Col key={index}>
                                                        <VideoCard 
                                                            moduleFieldKey={props.fieldKey}
                                                            videoField={videoField} 
                                                            formRef={props.formRef}
                                                            isFirstVideoInModule={index === 0}
                                                            isLastVideoInModule={index === fields.length - 1}
                                                            moveVideoLeft={shiftVideoLeft}
                                                            moveVideoRight={shiftVideoRight}
                                                            removeVideoFromModule={remove}
                                                            currentUser={props.currentUser}
                                                            addImportedVideos={props.addImportedVideos}
                                                            playVideoInModal={props.playVideoInModal}
                                                            videoPosition={index}
                                                            totalVideos={fields.length}
                                                        />
                                                    </Col>
                                        })}
                                    </Row>
                                </div>
                                <div className={`${props.selectedVideos.length > 0 ? "click-to-add-selected-video-message" : "hidden"}`}><Title>Click to add selected video</Title></div>
                                
                            </Card>
                            <Form.ErrorList errors={errors} />
                            </>
                }}
            </Form.List>
}

export default VideosInModule;