import {useState} from 'react';
import {Badge, Popover} from 'antd';
import ReactMarkdown from 'react-markdown'
import { convertFromRaw, EditorState } from 'draft-js';
import RichTextPreview from '../../../Components/RichTextPreview';

const QuizQuestionOption = ({handleOnClick, latestAttempt="preview", option, selected, submitted}) => {

    var selectedClassName;

    if(submitted) {
        if(latestAttempt == "preview" || latestAttempt.numCorrect == latestAttempt.maxCorrectAnswer && latestAttempt.numIncorrect == 0){
            if(selected && option.correctAnswerFlag){
                selectedClassName = "correct-quiz-option "
            }else if(selected && !option.correctAnswerFlag){
                selectedClassName = "wrong-quiz-option "
            } else {
                selectedClassName = ""
            }
        }else {
            if(selected){
                selectedClassName = "not-full-correct-quiz-option "
            }else {
                selectedClassName = ""
            }
        }
    }else {
        if(selected){
            selectedClassName = "selected-quiz-option "
        }else {
            selectedClassName = ""
        }
    }
    

    const contentStateText = convertFromRaw(option.optionText);
    const editorStateText = EditorState.createWithContent(contentStateText);
    const contentStateExplanation = convertFromRaw(option.explanationText);
    const editorStateExplanation = EditorState.createWithContent(contentStateExplanation);
    
    const renderText = option.optionText.blocks.length <= 1 && option.optionText.blocks[0].text.length == 0 ? null : <RichTextPreview editorState={editorStateText} />
    const renderExplanation = option.explanationText.blocks.length <= 1 && option.explanationText.blocks[0].text.length == 0 ? null : <div>
        <RichTextPreview editorState={editorStateExplanation} />
    </div>

    const renderImage = option.image ? <div className="quiz-question-option-image-container"><img alt="example" style={{ width: '100%' }} src={option.image.image} /></div> : null;

    if(submitted){
        if(latestAttempt == "preview" || latestAttempt.numCorrect == latestAttempt.maxCorrectAnswer && latestAttempt.numIncorrect == 0){
            if(option.correctAnswerFlag){
                // return green outlined option and ribbon badge if correct answer + selected by user
                // OR
                // return ribbon badge only
                return <Badge.Ribbon text="Correct Answer" className="correct-answer-ribbon">
                        <Popover placement="left" title="Correct answer" content={renderExplanation} trigger="hover">
                            <div className={`non-clickable-option ${selectedClassName}quiz-option`} onClick={handleOnClick}>
                            {renderText}
                            {renderImage}
                            </div>
                        </Popover>
                    </Badge.Ribbon>
            }else {
                // return default option with hoverable explanation and outline with red if was selected by user
                return <Popover placement="left" title="Wrong answer" content={renderExplanation} trigger="hover">
                            <div className={`non-clickable-option ${selectedClassName}quiz-option`} onClick={handleOnClick}>
                            {renderText}
                            {renderImage}
                            </div>
                        </Popover>
            }
        }else{
            return <div className={`${selectedClassName}quiz-option`} onClick={handleOnClick}>
               {renderText}
               {renderImage}
            </div>
        }
        
    }else {
        return <div className={`${selectedClassName}quiz-option`} onClick={handleOnClick}>
                {renderText}
                {renderImage}
            </div>
    }
}


export default QuizQuestionOption;