import { Button, Card, Col, Collapse, Descriptions, Divider, Image, List, notification, PageHeader, Row, Space, Select, Spin, Typography } from 'antd';
import { Component } from 'react';
import { connect } from 'react-redux';
import ModalVideoPlayer from '../ModalVideoPlayer';
import CollectionAvatarUpload from './CollectionAvatarUpload';
import NewScheduledLicenseModal from './NewScheduledLicenseModal';
import { fetchCollectionDetail, fetchCollectionDetailScheduledLicenses, resetCollectionEditData, setNotifyToEditLicenseOnCollectionChangeStatus, updateCollectionCoverImage } from '../../actions/contentCreation';
import { Duration } from "luxon";
import ModulesComponentList from '../ModulesComponentList';
import EditScheduledLicensedModal from './EditScheduledLicensedModal';


const { Paragraph,Text } = Typography;
const { Option } = Select;

class UserCreatedCollectionDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isScheduledLicenseFormVisible: false,
            isEditScheduledLicenseFormVisible: false,
            selectedLicense: null,
            submittedForCuration: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        }
    }
    componentDidMount() {
        if(this.props.isAuthenticated){ 
            if(!this.props.receivedCollection || (this.props.receivedCollection && this.props.collection.id != this.props.match.params.collectionId)){
                this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.user.id, this.collectionNotFound)
                this.props.fetchCollectionDetailScheduledLicenses(this.props.match.params.collectionId, this.props.user.id)
            }
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated){
            if(!this.props.receivedCollection || (prevProps.receivedCollection == !this.props.receivedCollection && this.props.collection.id != this.props.match.params.collectionId)){
                this.props.fetchCollectionDetail(this.props.match.params.collectionId, this.props.user.id, this.collectionNotFound)
                this.props.fetchCollectionDetailScheduledLicenses(this.props.match.params.collectionId, this.props.user.id)
            }
        }
        if(this.props.notifyToEditLicenseOnCollectionChange){
            
            notification.open({
                message: 'Make sure to edit your coupons!',
                description: 'I will never close automatically. This is a purposely very very long description that has many many characters and words.',
                duration: 0
            })
            this.props.setNotifyToEditLicenseOnCollectionChangeStatus(false);
        }
    }

    collectionNotFound = () => {
        this.props.history.push('/404')
    }
    handleOnEditClick = () => {
        this.props.history.push(`/collections/${this.props.collection.id}/edit/`)
    }

    playVideoInModal = (video, parentModule) => {
        this.setState({
            visiblePlayer: true, 
            videoToPlay: video,
            videoPlayedFromModule: parentModule
        })
    }

    closeVideo = () => {
        this.setState({
            visiblePlayer: false,
        })
    }

    render(){
        if(!this.props.collection || this.props.collection.id != this.props.match.params.collectionId){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        return  <div id='creator-panel'>
                <br/>
                <PageHeader
                    title={this.props.collection.name}
                    subTitle={`Aggregated by ${this.props.collection.createdByUsername}`}
                    ghost={false}
                    onBack={()=>{
                        this.props.history.push('/creator/home/')
                    }}
                    extra={[ <Space align="center" key="actions">
                                <Button 
                                    type="primary" 
                                    key="edit" 
                                    onClick={this.handleOnEditClick}
                                >
                                    Edit
                                </Button>
                            </Space>
                        ]}
                >
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Card>
                            <Space>
                                <Text strong>Shareable at:</Text>
                             
                            </Space>
                            <Paragraph 
                                copyable key="share" 
                                ellipsis={true} 
                                style={{width: 300, marginBottom: 0}}
                                className="disable-highlighting"
                            >
                                {window.location.origin + this.props.collection.url}
                            </Paragraph>
                            <Divider style={{margin: 6}}></Divider>
                            <Button 
                                block
                                type="link" 
                                size="small" 
                                onClick={()=>this.props.history.push(this.props.collection.url)}
                            >
                                Go to Collection Page
                            </Button>
                        </Card>
                        <Card>
                            <Space >
                                <div style={{display: 'inline-block', height: "55px", marginRight: '15px'}}>
                                    <Text strong>Active Scheduled Licenses:</Text>
                                    <br/>
                                    <Select
                                        showSearch
                                        placeholder="Select a class"
                                        size="middle"
                                        style={{width: "100%"}}
                                        value={this.state.selectedLicense}
                                        onChange={(value)=>this.setState({selectedLicense: value})}
                                    >   
                                        {this.props.scheduledLicenses.map(license => <Option key={license.id} value={license.id}>{license.label}</Option>)}
                                    </Select>
                                </div>
                                <div style={{display: 'inline-block', height: "55px", marginRight: '15px'}}>
                                    <Text strong>Coupon Code:</Text>
                                    <br/>
                                    <Text style={{padding: "7px 0px", fontSize: "18px" }} code>
                                        {this.props.scheduledLicenses.filter(license=> license.id === this.state.selectedLicense).map(license => license.code)}
                                    </Text>
                                </div>
                                <Button 
                                    type="primary" 
                                    onClick={()=>this.setState({
                                            isScheduledLicenseFormVisible: true
                                        })
                                    }
                                >
                                    Create new license
                                </Button>
                                <Button 
                                    disabled={this.state.selectedLicense == null}  
                                    onClick={()=>this.setState({
                                            isEditScheduledLicenseFormVisible: true
                                        })
                                    }
                                >
                                    Edit selected license
                                </Button>
                                <NewScheduledLicenseModal 
                                    closeScheduledLicenseForm={
                                        ()=> this.setState({isScheduledLicenseFormVisible: false})
                                    }
                                    setSelectedLicense={(licenseId) => this.setState({selectedLicense: licenseId})}
                                    collectionId={this.props.collection.id}
                                    visible={this.state.isScheduledLicenseFormVisible}
                                    modules={this.props.collection.modules}
                                />
                                <EditScheduledLicensedModal 
                                    allLicenses={this.props.scheduledLicenses}
                                    closeEditScheduledLicenseForm={
                                        ()=> this.setState({isEditScheduledLicenseFormVisible: false})
                                    }
                                    selectedLicense={this.state.selectedLicense ? this.props.scheduledLicenses.find(license => license.id == this.state.selectedLicense) : null}
                                    setNextLicense={(licenseData)=>this.setState({ selectedLicense: licenseData})}
                                    collectionId={this.props.collection.id}
                                    visible={this.state.isEditScheduledLicenseFormVisible}
                                    modules={this.props.collection.modules}
                                />
                            </Space>
                        </Card>
                    </div>
                    <br/>
                    <Row gutter={[16, 4]}>
                        <Col span={16}>
                            <Descriptions 
                                bordered 
                                size="small"
                            >
                                <Descriptions.Item label="Author" span={3}>{this.props.collection.primaryAuthor.firstName} {this.props.collection.primaryAuthor.lastName}</Descriptions.Item>
                                <Descriptions.Item label="Co-Authors" span={3}>{this.props.collection.coAuthors.map((coAuthor, index) => `${coAuthor.firstName} ${coAuthor.lastName}${index == this.props.collection.coAuthors.length - 1 ? '' : ', '}`)}</Descriptions.Item>
                                <Descriptions.Item label="Description" span={3}>{this.props.collection.description}</Descriptions.Item>
                                <Descriptions.Item label="Long Description" span={3}>{this.props.collection.longDescription}</Descriptions.Item>
                                <Descriptions.Item label="Collection Price">{this.props.collection.valuePrice} cupoints</Descriptions.Item>
                                <Descriptions.Item label="Total Duration">{Duration.fromObject({'seconds': this.props.collection.totalDuration}).shiftTo('hours', 'minutes', 'seconds').toFormat("hh:mm:ss")}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}><CollectionAvatarUpload collectionId={this.props.collection.id} existingCoverImage={this.props.collection.coverImage.image} updateImage={this.props.updateCollectionCoverImage} receivedUserCreatedCollections={this.props.receivedUserCreatedCollections}/> </Col>
                    </Row>
                </PageHeader>
                <div style={{backgroundColor: "white"}}>
                    <Divider style={{marginBottom: "0px", marginTop: "0px"}}/>
                    <ModulesComponentList modules={this.props.collection.modules} playVideoInModal={this.playVideoInModal} moduleActions={[]} videoActions={['play', 'createQuestions']} enablePagination={false}/>
                   
                </div>
                <div style={{background: "black", display: "inline-block"}}>
                    <ModalVideoPlayer 
                        visible={this.state.visiblePlayer} 
                        toggleVideoPlayerModalVisibility={this.closeVideo}
                        video={this.state.videoToPlay}
                        module={this.state.videoPlayedFromModule}
                        playVideoInModal={this.playVideoInModal}
                        videoActions={['play']}
                    />
                </div>
            </div>
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        collection: state.contentCreationReducer.collectionDetail,
        notifyToEditLicenseOnCollectionChange: state.contentCreationReducer.notifyToEditLicenseOnCollectionChange,
        scheduledLicenses: state.contentCreationReducer.collectionDetailLicenses,
        receivedCollection: state.contentCreationReducer.receivedCollectionDetail,
        receivedScheduledLicenses: state.contentCreationReducer.receivedScheduledLicensesStatus,
        receivedUserCreatedCollections: state.contentCreationReducer.receivedUserCreatedCollections
    }
}

export default connect(mapStateToProps, {fetchCollectionDetail, fetchCollectionDetailScheduledLicenses, resetCollectionEditData, setNotifyToEditLicenseOnCollectionChangeStatus, updateCollectionCoverImage})(UserCreatedCollectionDetail);
