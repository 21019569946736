import {useState} from 'react';
import { Badge, Card, Form, Image, Input, Space, Tooltip, Typography} from 'antd';
import { CloseOutlined, DoubleLeftOutlined, DoubleRightOutlined, InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

const {Text}  = Typography;
const VideoCard = ({addImportedVideos, currentUser, formRef, isFirstInModule, isLastInModule, moduleFieldKey, moveVideoLeft, moveVideoRight, playVideoInModal, removeVideoFromModule, totalVideos, videoField, videoPosition}) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)

    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/0.jpg`} preview={false} width="320px" height="200px"/>
        }else if(video.videoType === "vimeo"){
           
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                        width="320px" 
                        height="200px"
                    />
        }
    }

    let videoData, moduleData, owner;
   
    if(moduleFieldKey != null){
        videoData = formRef.current.getFieldValue(['modules', moduleFieldKey, 'videos', videoField.name])
        moduleData = formRef.current.getFieldValue(['modules', moduleFieldKey])
        owner=videoData.owner
    }else {
        videoData = formRef.current.getFieldValue(['videos', videoField.name])
        moduleData = formRef.current.getFieldsValue(true)
        owner=videoData.owner
    }

    if(moduleData && videoData.parentModule != moduleData.id && videoData.parentModule){
        let tooltipContent = <Text className="white-color-text"> 
                                Licensing from the module: <br />
                                <Text className="white-color-text" strong>{`${videoData.parentModuleName}`}</Text>
                                <br />
                                at a price of <Text className="white-color-text" strong>{`${videoData.parentModulePrice} cupoints.`}</Text>
                            </Text>
        return <Badge.Ribbon text={<Tooltip title={tooltipContent}><Text className="white-color-text hoverable">Licensed Content <InfoCircleOutlined /></Text></Tooltip>} color="orange">
            <Card
                cover={getVideoThumbnail(videoData)}
                hoverable={true}
                actions={[
                    <Tooltip title="Move left" placement="bottom"><DoubleLeftOutlined key="shiftLeft" onClick={()=>moveVideoLeft()} /></Tooltip>,
                    <Tooltip title="Remove video" placement="bottom"><CloseOutlined key="remove" onClick={() => {
                            addImportedVideos([videoData])

                            removeVideoFromModule(videoField.name)
                        }
                    } /></Tooltip>,
                    <Tooltip title="Watch video" placement="bottom"><PlayCircleOutlined key="play" onClick={()=>playVideoInModal(videoData, moduleData)} /></Tooltip>,
                    <Tooltip title="Move right" placement="bottom"><DoubleRightOutlined key="shiftRight" onClick={()=>moveVideoRight()} /></Tooltip>
                ]}
               
            >
                <h5>Video {videoPosition + 1} of {totalVideos}</h5>
                <Form.Item
                    name={[videoField.name, 'title']}
                    fieldKey={[videoField.key, 'title']}
                    key={[videoField.key, 'title']}
                    className="video-card-in-module-collection-creator"
                    rules={[{ required: true, message: 'Please give your video a name!' }]}
                    >
                    <Input placeholder="Video title" disabled={owner !== currentUser.id && owner !== undefined ? true : false} maxLength={200} />
                </Form.Item>
                <Form.Item
                    name={[videoField.name, 'videoType']}
                    fieldKey={[videoField.key, 'videoType']}
                    key={[videoField.key, 'videoType']}
                    hidden
                    >
                    <Input disabled maxLength={7}/>
                </Form.Item>
                <Form.Item
                    name={[videoField.name, 'embedId']}
                    fieldKey={[videoField.key, 'embedId']}
                    key={[videoField.key, 'embedId']}
                    hidden
                    >
                    <Input disabled maxLength={11}/>
                </Form.Item>
                <Form.Item
                    name={[videoField.name, 'duration']}
                    fieldKey={[videoField.key, 'duration']}
                    key={[videoField.key, 'duration']}
                    hidden
                    >
                    <Input disabled/>
                </Form.Item>
                <Form.Item
                    name={[videoField.name, 'parentModule']}
                    fieldKey={[videoField.key, 'parentModule']}
                    key={[videoField.key, 'parentModule']}
                    hidden
                    >
                    <Input disabled/>
                </Form.Item>
            </Card>
        </Badge.Ribbon>
    }else {
        return  <Card
                    cover={getVideoThumbnail(videoData)}
                    hoverable={true}
                    actions={[
                        <Tooltip title="Move left" placement="bottom"><DoubleLeftOutlined key="shiftLeft" onClick={()=>moveVideoLeft()} /></Tooltip>,
                        <Tooltip title="Remove video" placement="bottom"><CloseOutlined key="remove" onClick={() => {
                                addImportedVideos([videoData])

                                removeVideoFromModule(videoField.name)
                            }
                        } /></Tooltip>,
                        <Tooltip title="Watch video" placement="bottom"><PlayCircleOutlined key="play" onClick={()=>playVideoInModal(videoData, moduleData)} /></Tooltip>,
                        <Tooltip title="Move right" placement="bottom"><DoubleRightOutlined key="shiftRight" onClick={()=>moveVideoRight()} /></Tooltip>
                    ]}
                
                >
                    <h5>Video {videoPosition + 1} of {totalVideos}</h5>
                    <Form.Item
                        name={[videoField.name, 'title']}
                        fieldKey={[videoField.key, 'title']}
                        key={[videoField.key, 'title']}
                        className="video-card-in-module-collection-creator"
                        rules={[{ required: true, message: 'Please give your video a name!' }]}
                        >
                        <Input placeholder="Video title" disabled={owner !== currentUser.id && owner !== undefined ? true : false} maxLength={50} />
                    </Form.Item>
                    <Form.Item
                        name={[videoField.name, 'videoType']}
                        fieldKey={[videoField.key, 'videoType']}
                        key={[videoField.key, 'videoType']}
                        hidden
                        >
                        <Input disabled maxLength={7}/>
                    </Form.Item>
                    <Form.Item
                        name={[videoField.name, 'embedId']}
                        fieldKey={[videoField.key, 'embedId']}
                        key={[videoField.key, 'embedId']}
                        hidden
                        >
                        <Input disabled maxLength={11}/>
                    </Form.Item>
                    <Form.Item
                        name={[videoField.name, 'duration']}
                        fieldKey={[videoField.key, 'duration']}
                        key={[videoField.key, 'duration']}
                        hidden
                        >
                        <Input disabled/>
                    </Form.Item>
                    <Form.Item
                        name={[videoField.name, 'parentModule']}
                        fieldKey={[videoField.key, 'parentModule']}
                        key={[videoField.key, 'parentModule']}
                        hidden
                        >
                        <Input disabled/>
                    </Form.Item>
                </Card>
    }
}

export default VideoCard;