import React, {useState} from 'react';
import {connect} from 'react-redux';
import { Button, Form, Modal, Radio } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import {setCouponDataAccessPreference} from '../actions/user';


const CouponDataPreferencesModal = ({setCouponDataAccessPreference, usedCoupon}) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)   
    
    const choicesDict = {
        "full": "Full",
        "anonymous": "Anonymous",
        "no_access": "None"
    }

    const handleSettingsClick = (event) => {
        event.stopPropagation();
        setModalVisible(true)
    }

    const handleOnCancel = () => {
        setModalVisible(false)
    }

    const handlePreferenceSaved = () => {
        setLoading(false)
        setModalVisible(false)
    }
    const handleOnFinish = (values) => {
        setLoading(true)
        setCouponDataAccessPreference(usedCoupon.id, values["dataAccessLevel"], handlePreferenceSaved)
    }


    return  <>
            <Button onClick={handleSettingsClick}><SettingOutlined /></Button>
            <Modal visible={modalVisible} 
                title="Data Sharing Preferences"
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={handleOnCancel}>Cancel</Button>,
                    <Button form={`dataPreferenceForm-${usedCoupon.id}`} 
                            key="submit" 
                            type="primary" 
                            htmlType="submit"
                            loading={loading}
                    >
                        Set Preference
                    </Button>
                ]}
            >
                <Form id={`dataPreferenceForm-${usedCoupon.id}`} onFinish={handleOnFinish} initialValues={{"dataAccessLevel": usedCoupon.dataAccessLevel}}>
                    <Form.Item name="dataAccessLevel">
                        <Radio.Group buttonStyle="solid">
                            {usedCoupon.allowableDataAccessChoices.map(choice => <Radio.Button value={choice}>{choicesDict[choice]}</Radio.Button>)}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            </>
}

export default connect(null, {setCouponDataAccessPreference})(CouponDataPreferencesModal);