import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import { fetchSingleCollection, setSingleCollection } from '../../actions/collection';
import {clearActiveCoupon, purchaseCollection} from '../../actions/user';
import ModuleList from './ModuleList';
import CouponSearchModal from './CouponSearchModal';
import {Badge, Button, Card, Col, Divider, Input, message, Row, PageHeader, Space, Tag, Spin, Tooltip, Typography} from 'antd';
import {LoadingOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import CollectionSearchResultVideoList from './CollectionSearchResultVideoList';
import CollectionSearchResultsVideoTranscriptList from './CollectionSearchResultsVideoTranscriptList';


const { Search } = Input; 
const {Text, Title, Paragraph} = Typography;

class CollectionShow extends Component {
    constructor(props){
        super(props)
        this.state = {
            collectionPurchaseLoading: false,
            searchValue: "",
            searchResults: null,
            moduleIdOfSelectedVideo: null,
            selectedVideoFromSearchResults: null,
            searchLoading: false,
            unlistedKey: ""
        }
    }

    componentDidMount(){
        //this.props.fetchCollection(this.props.match.params.collectionId)
        let query = new URLSearchParams(this.props.location.search)
        this.setState({ unlistedKey: query.get('key')})
        if(!this.props.fetchingCollections){
            this.props.fetchSingleCollection(this.props.match.params.collectionId, query.get('key'), this.collectionNotFound)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchingCollections !== this.props.fetchingCollections || !this.equalUrlParams(prevProps.match.params, this.props.match.params)) {
            let query = new URLSearchParams(this.props.location.search)
            this.props.fetchSingleCollection(this.props.match.params.collectionId, query.get('key'), this.collectionNotFound)
        }

    }

    componentWillUnmount(){
        message.destroy()
    }

    collectionNotFound = () => {
        this.props.history.push('/404')
    }
    equalUrlParams = (prev, current) => {
        return prev['collectionId'] === current['collectionId']
    }

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        let query = new URLSearchParams(this.props.location.search)
        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: 'Home'
                }, {
                    path: "/collections/",
                    breadcrumbName: "Collections"
                }, 
                {
                    path: `/collections/${this.props.collection.id}/${query.get('key') ? "?key=" + query.get('key') : ""}`,
                    breadcrumbName: this.props.collection.name
                }, 
            ]
        }
    }
    
    handleCouponTagClose = () => {
        this.props.clearActiveCoupon()
    }
    
    activeCouponTag = () => {
        return this.props.activeCoupon ? <Tag key="coupon-tag" color="volcano" 
                                            onClose={this.handleCouponTagClose} 
                                            closable>
                                                [{this.props.activeCoupon.code}] {this.props.activeCoupon.percentDiscount * 100}% OFF
                                        </Tag> : null
    }

    onCollectionPurchaseClick = () => {
        this.setState({collectionPurchaseLoading: true})
        const stopPurchaseLoading = () => {
            this.setState({collectionPurchaseLoading: false})
        }
        this.props.purchaseCollection(this.props.collection.id, stopPurchaseLoading)
    }
    
    collectionPurchaseButton = () => {
        if (this.props.user){
            let ownedModulesCount = 0
            const numModulesInCollection = this.props.collection.modules.length
            this.props.collection.modules.forEach(module => {
                if(this.props.user.moduleAccess[module.id] && this.props.user.moduleAccess[module.id]['accessType'] == 'permanent'){
                    ownedModulesCount += 1
                }
            })
            // {totalOriginalPrice, totalDiscountedPrice}
            var collectionPriceObject = this.calculateCollectionPrice()
            var buttonColor = this.props.activeCoupon && this.props.activeCoupon.discountedCollections.includes(this.props.collection.id) ? {color: "#fff2e8", background: "#fa541c", borderColor: "#ffbb96"} : {}
            return ownedModulesCount >= numModulesInCollection - 1 ? null :  <Tooltip title="Bundle Discount">
                                                                                    <Button key="1" 
                                                                                        type="primary" 
                                                                                        size='large'
                                                                                        loading={this.state.collectionPurchaseLoading}
                                                                                        style={buttonColor} onClick={this.onCollectionPurchaseClick}
                                                                                    >
                                                                                        <ShoppingCartOutlined />
                                                                                        <Text delete>{collectionPriceObject["totalOriginalPrice"]}</Text>
                                                                                        {collectionPriceObject["totalDiscountedPrice"]} cupoints
                                                                                    </Button>
                                                                            </Tooltip>
        } else {
            return null
        }
    }
    calculateCollectionPrice = () => {
        var bundleDiscount = parseFloat(this.props.collection.bundleDiscount);
        var couponDiscount = this.props.activeCoupon && this.props.activeCoupon.discountedCollections.includes(this.props.collection.id) ? parseFloat(this.props.activeCoupon.percentDiscount) : 0.00;

        var totalDiscount = Math.min(1, bundleDiscount + couponDiscount);
        var totalDiscountedPrice = 0;
        var totalOriginalPrice = 0;
        var visitedUniqueModules = []
        for(var singleModule of this.props.collection.modules) {
            if((!this.props.user.moduleAccess[singleModule.id] || this.props.user.moduleAccess[singleModule.id]['accessType'] !== "permanent") && !visitedUniqueModules.includes(singleModule.id)){
                totalDiscountedPrice += Math.round(singleModule.price * (1 - totalDiscount))
                totalOriginalPrice += singleModule.price
                visitedUniqueModules.push(singleModule.id)
            }
            for(var licensedModule of singleModule.licensedModules){
                if((!this.props.user.moduleAccess[singleModule.id] || this.props.user.moduleAccess[singleModule.id]['accessType'] !== "permanent") && !visitedUniqueModules.includes(licensedModule.id)){
                    totalDiscountedPrice += Math.round(licensedModule.price * (1 - totalDiscount))
                    totalOriginalPrice += licensedModule.price
                    visitedUniqueModules.push(licensedModule.id)
                }
            }
        }
        return {totalOriginalPrice, totalDiscountedPrice}
    }
    
    handleOnSearchChange = (value) => {
        if(value.length > 0){
            this.setState({
                searchValue: value, 
                searchResults: null, 
                selectedVideoFromSearchResults: null,
                searchLoading: true
            })
            fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${this.props.collection.id}/search/`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "searchValue": value
                })
            })
            .then(response => {
                if(response.ok){
                    return response.json()
                }else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                let selectedVideoData = data['results'].length ? data['results'][0] : null;
                let moduleOfVideo = null;
                if(selectedVideoData) {
                    moduleOfVideo = this.props.collection.modules.find(module => module.videos.find(videoData => selectedVideoData.id == videoData.id))
                    if(moduleOfVideo){
                        moduleOfVideo = moduleOfVideo.id
                    }
                }
                this.setState({
                    searchResults: data['results'],
                    selectedVideoFromSearchResults: selectedVideoData,
                    moduleIdOfSelectedVideo: moduleOfVideo,
                    searchLoading: false
                })
            })
            .catch(error => {
                this.setState({searchLoading: false})
                if(parseInt(error.message) == 401){
                }else {
                    console.log(error)
                }
            })
        } else {
            this.setState({
                searchValue: value, 
                searchResults: null, 
                selectedVideoFromSearchResults: null,
            })
        }
    }

    selectVideoFromSearchResults = (moduleId, videoData) => {
        this.setState({
            moduleIdOfSelectedVideo: moduleId,
            selectedVideoFromSearchResults: videoData
        })
    }


    renderCollectionContent = () => {
        if(this.state.searchValue.length > 0){
            if(this.state.searchResults && this.state.searchResults.length){
                return <>
                    <CollectionSearchResultVideoList 
                        collectionId={this.props.collection.id}
                        modulesInCollection={this.props.collection.modules}
                        unlistedKey={this.state.unlistedKey}
                        searchResults={this.state.searchResults} 
                        selectVideo={this.selectVideoFromSearchResults} 
                        selectedVideo={this.state.selectedVideoFromSearchResults}
                        searchValue={this.state.searchValue}
                    />
                    <CollectionSearchResultsVideoTranscriptList 
                        collectionId={this.props.collection.id}
                        unlistedKey={this.state.unlistedKey}
                        moduleId={this.state.moduleIdOfSelectedVideo}
                        video={this.state.selectedVideoFromSearchResults}
                        searchValue={this.state.searchValue}
                    />
                </>
            }  
        }else {
            return <ModuleList modules={this.props.collection.modules} collectionId={this.props.collection.id} unlistedKey={(new URLSearchParams(this.props.location.search)).get('key')}/>
        }
    }


    render(){
        if(this.props.collectionDoesNotExist) {
            return <Redirect to='/404' />
        }
        if(this.props.fetchingCollections || !this.props.singleCollectionIsSet || this.props.match.params.collectionId != this.props.collection.id){
            return  <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        return <div id="collection-show">
            
            <br/>
            <PageHeader
                title={this.props.collection.name}
                className="page-header-without-bottom-padding"
                subTitle={`Aggregated by ${this.props.collection.createdByUsername}`}
                ghost={false}
                onBack={() => window.history.back()}
                extra={[
                    <Space align="baseline">
                    {this.activeCouponTag()}
                    <CouponSearchModal key="coupon-search"/>
                    {this.collectionPurchaseButton()}</Space>
                ]}
                
                breadcrumb={ this.breadcrumbConfig() }
            >
                <Row gutter={4}>
                    <Col span={12}>
                        <Text type="secondary">Primary Author:</Text><Text> {this.props.collection.primaryAuthor.firstName} {this.props.collection.primaryAuthor.lastName}</Text>
                        <br/>
                        <Text type="secondary"> Co-Authors:</Text><Text> {this.props.collection.coAuthors.map((coAuthor, index) => `${coAuthor.firstName} ${coAuthor.lastName}${index == this.props.collection.coAuthors.length - 1 ? '' : ', '}`)}</Text>
                        <Paragraph>{this.props.collection.longDescription}</Paragraph>
                    </Col>
                </Row>
            </PageHeader>
            <br/>
            <div className="collection-search-input-container" >
                <Search 
                    placeholder="Search within collection" 
                    onSearch={this.handleOnSearchChange} 
                    allowClear
                    loading={this.state.searchLoading}
                    style={{
                        width: "400px"
                    }} 
                />
                
            </div>
                {/*<Card className="quiz-module-purchase-headers-container" bordered={false} >
                    <Space style={{float: "left"}}><Text underline className="quiz-module-purchase-headers-text">Module Name</Text></Space>
                    <Space style={{float: "right"}}>
                    <Text strong className="quiz-module-purchase-headers-text">Purchase option: </Text>
                        <div className="quiz-module-purchase-headers">
                            <Text underline className="quiz-module-purchase-headers-text">Quiz Questions</Text>
                        </div>
                        <div className="quiz-module-purchase-headers">
                            <Text underline className="quiz-module-purchase-headers-text">Module/Video</Text>
                        </div>
                    </Space>
                    </Card>*/}
               
                {this.renderCollectionContent()}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        activeCoupon: state.userReducer.activeCoupon,
        user: state.userReducer.user,
        fetchingCollections: state.collectionReducer.fetchingCollections,
        collection: state.collectionReducer.singleCollection,
        singleCollectionIsSet: state.collectionReducer.singleCollectionIsSet,
        collectionDoesNotExist: state.collectionReducer.collectionDoesNotExist
    }
}

export default connect(mapStateToProps, { clearActiveCoupon, fetchSingleCollection, purchaseCollection, setSingleCollection })(CollectionShow);