import {Form, Input, Modal, Typography} from 'antd';
import {fetchVideoDataFromURL} from '../../actions/contentCreation';
import { connect } from 'react-redux';
import { Duration } from "luxon";


const { Text } = Typography;

const ImportVideoFromUrl = ({ addImportedVideos, closeModal, fetchVideoDataFromURL, visible}) => {
    const [videoFromUrlForm] = Form.useForm();

    const handleOnFinish = ({ url }) => {
        const handleOnNewVideoByURLError = () => {
            return videoFromUrlForm.setFields([{
                name: 'url',
                errors: ["Invalid video or playlist URL"]
            }])
        }
        fetchVideoDataFromURL(url, parseVimeoData, parseYouTubeData, handleOnNewVideoByURLError)
    }

    const parseYouTubeData = (data) => {
        let newVideos = data['data']['items'].map(item => {
            let videoId = item['id']
            let videoDuration = Duration.fromISO(item['contentDetails']['duration']).shiftTo('seconds').seconds;
            return {
                'title': item['snippet']['title'],
                'videoType': data['videoType'],
                'embedId': videoId,
                'importedFromURL': "true",
                'duration': videoDuration
            }
        })
        let videosToImport = newVideos.concat(data['licensableContent'])
        addImportedVideos(videosToImport)
        videoFromUrlForm.resetFields()
    }
    const parseVimeoData = (data) => {
        let newVideos;
        if(data['contentType'] === "playlist"){
            newVideos = data['data']['data'].map(videoData => {
                return {
                    'title': videoData['name'],
                    'videoType': data['videoType'],
                    'embedId': videoData['uri'].replace('/videos/', ''),
                    'duration': videoData['duration']
                }
            })
        }else if(data['contentType'] === 'video'){
            newVideos = data['data'].map(videoData => {
                return {
                    'title': videoData['name'],
                    'videoType': data['videoType'],
                    'embedId': videoData['uri'].replace('/videos/', ''),
                    'duration': videoData['duration']
                }
            })
        }
        let videosToImport = newVideos.concat(data['licensableContent'])
        addImportedVideos(videosToImport)
        videoFromUrlForm.resetFields()
    }


    return  <Modal title="Add video with URL" 
                visible={visible} 
                onCancel={()=>closeModal()}
                onOk={() => videoFromUrlForm.submit()}
            >
                <Form 
                    onFinish={handleOnFinish}
                    id="add-video-by-playlist-or-video-url-form"
                    form={videoFromUrlForm}
                    layout="vertical"
                >
                    <Form.Item
                        label="Playlist or Video URL"
                        name="url"
                        rules={[{
                            required: true,
                            message: "Please enter a valid playlist or video URL"
                        }]}
                    >
                        <Input placeholder="https://www.youtube.com/playlist?list=<PLAYLIST_ID>"/>
                    </Form.Item>
                    <Text strong>* Supports YouTube (video/playlist) and Vimeo (video/showcase)</Text>
                </Form>
            </Modal>
}
export default connect(null, {fetchVideoDataFromURL})(ImportVideoFromUrl);