import {useState} from 'react';
import { Button } from 'antd';

const TransactionRefundButton = ({issueRefund, transaction, user}) => {
    const [loading, setLoading] = useState(false)

    const refundOnClick = () => {
        setLoading(true)
        const finishRefund = () => {
            setLoading(false)
        }
        issueRefund(user.primaryWallet.id, transaction.orderId, finishRefund)
    }
    var transactionDate = new Date(transaction.date);
    transactionDate.setHours(transactionDate.getHours() + 1);

    
    return transaction.refundable && new Date() <= transactionDate ? <Button danger loading={loading} onClick={refundOnClick}><span data-transaction-id={transaction.orderId}>Refund</span></Button> : <Button disabled>Refund</Button>
}

export default TransactionRefundButton