import { Button, Checkbox, Comment, Divider, Form, message, Radio, Select, Space, Spin, Typography } from "antd";
import { CloseCircleOutlined, MessageOutlined } from "@ant-design/icons";
import RichTextEditor from "../../../Components/RichTextEditor";
import { fetchVideoQnA, fetchModuleQnA, fetchCollectionQnA, postQnAQuestion } from '../../../actions/collection';
import QnAQuestion from "./QnAQuestion";
import { useState } from 'react';
import { connect } from 'react-redux';

const { Option } = Select;
const { Title, Text } = Typography;

const options = [
    { label: 'Collection', value: 'collection' },
    { label: 'Module', value: 'module' },
    { label: 'Video', value: 'video' },
  ];

const QnAContainer = ({ collectionId, collectionQnA, collectionQnAStatus, currentUser, enrolledLicenses, fetchingEnrolledLicensesStatus, fetchCollectionQnA, fetchModuleQnA,
                        fetchVideoQnA, moduleId, moduleQnA, moduleQnAStatus, postQnAQuestion, selectedQnALevel, setSelectedQnALevel, videoQnA, videoQnAStatus, videoId}) => {
    const [reset, setReset] = useState(false);
    const [form] = Form.useForm();
    const [filteredLicenses, setFilteredLicenses] = useState([])
    const [licensesFiltered, setLicensesFiltered] = useState(false);
    const [selectedLicense, setSelectedLicense] = useState(false);
    const [fetchedQnA, setFetchedQnA] = useState(false);
    const [submitQuestionLoading, setSubmitQuestionLoading] = useState(false);
    const [prevVideoId, setPrevVideoId] = useState(null);
    const [isAnonymous, setIsAnonymous] = useState(false);


    if(prevVideoId != videoId){
        setPrevVideoId(videoId)
        setFilteredLicenses([])
        setSelectedLicense(false);
        setLicensesFiltered(false);
        setFetchedQnA(false);
    }
    const handleOnLicenseSelectChange = (value) => {
        setSelectedLicense(value)
        setFetchedQnA(false);
    }

    const handleOnFinish = (values) => {
        const handleAfterSuccess = () => {
            setSubmitQuestionLoading(false);
            setReset(true);
            form.setFieldsValue({'text': undefined})
            message.success("Your question was posted.")
        }

        postQnAQuestion(collectionId, moduleId, videoId, selectedLicense, selectedQnALevel, values, handleAfterSuccess)
    }

    const handleOnRichTextEditorChange = (value) => {
        form.setFieldsValue({
            text: value
        })
    }

    const handleQnALevelChange = (event) => {
        setSelectedQnALevel(event.target.value)
    }

    const handleCheckboxChange = (event) => {
        form.setFieldsValue({
            anonymous: event.target.checked
        })
        setIsAnonymous(event.target.checked)
    }

    if(fetchingEnrolledLicensesStatus == "LOADED" && !licensesFiltered){
        let licenses = enrolledLicenses.filter(license => license.scheduledAccesses.some(access => access.collection == collectionId && access.module == moduleId))
        setLicensesFiltered(true);
        setFilteredLicenses(licenses); 

        if(licenses.length){
            setSelectedLicense(licenses[0].id)
        }
    }

    if(!fetchedQnA && selectedLicense) {
        fetchVideoQnA(collectionId, moduleId, videoId, selectedLicense)
        fetchModuleQnA(collectionId, moduleId, selectedLicense)
        fetchCollectionQnA(collectionId, selectedLicense)
        setFetchedQnA(true);
    }

    const renderQnAPostQuestionContent = () => {
        const renderPostingAsUsername = () => {
     
            if(isAnonymous){
                return "Anonymous User"
            }else {
                return currentUser.firstName && currentUser.lastName ? `${currentUser.firstName} ${currentUser.lastName}` : currentUser.username
            }   
        }
        const renderAnonymousCheckbox = () => {
            let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);
            if(currentLicense.createdById == currentUser.id){
                return <div></div>
            }else {
                return <Checkbox onChange={handleCheckboxChange}>Post anonymously to other students</Checkbox>
            }
        }
        return <>
            <Text>View discussion about the: </Text>
                <Radio.Group
                    options={options}
                    onChange={handleQnALevelChange}
                    optionType="button"
                    buttonStyle="solid"
                    value={selectedQnALevel}
                    size="small"
                />
                <br/>
                <br/>
                <Text type='secondary'>Posting as: {renderPostingAsUsername()}</Text>
                    <div style={{backgroundColor: "white", marginTop: "5px"}}>
                        <Form form={form} onFinish={handleOnFinish}>
                            <Form.Item name='text' hidden>
                                
                            </Form.Item>
                            <Form.Item name='anonymous' hidden>
                                
                            </Form.Item>
                        </Form>
                        <RichTextEditor 
                            customOnChange={handleOnRichTextEditorChange} 
                            placeholder="Ask a question" 
                            readOnly={false} 
                            reset={reset} 
                            setReset={setReset} 
                            allowTimestamps={true}
                        />
                    </div>
                    <br/>
                    <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <Space>{renderAnonymousCheckbox()}</Space>
                        
                        <Button 
                            type="primary" 
                            onClick={() => {
                                let textValue = form.getFieldValue('text')
                                if(textValue && textValue.blocks.some(block => block.text.length)){
                                    setSubmitQuestionLoading(true);
                                    form.submit()
                                } else {
                                    message.warning('Please enter a question first.')
                                }
                            }} 
                            loading={submitQuestionLoading}
                        >
                            <MessageOutlined /> Ask Question
                        </Button>
                    </div>
                   
                <Divider style={{ margin: "15px 0 5px 0"}}/>
        </>
    }
  
    const renderInnerContent = () => {
        let qna, qnaStatus;
        switch(selectedQnALevel){
            case "collection":
                qna = collectionQnA;
                qnaStatus = collectionQnAStatus
                break;
            case "module":
                qna = moduleQnA;
                qnaStatus = moduleQnAStatus
                break;
            default:
                qna = videoQnA;
                qnaStatus = videoQnAStatus
                break;

        }
        if(!filteredLicenses.length){
            return <div className="spin-container">
                    <Text className="qna-sub-heading">No QnA available for this video.</Text>
                </div>
        } else if(qnaStatus != "LOADED" && qnaStatus != "FAILED"){
            return <div className="spin-container">
                    <Spin size="large" />
                </div>
        } else if(qnaStatus == "FAILED") {
            return <Text type="danger"><CloseCircleOutlined /> Failed to load Q&A</Text>
        } else if(fetchingEnrolledLicensesStatus == "LOADED" && !filteredLicenses.length) {
            return <div id='q-and-a-section'><h1>No coupons</h1></div>
        } else if(qna.length){
            return <>
                {renderQnAPostQuestionContent ()}
                {
                    qna.map(question => {
                        return <QnAQuestion 
                                    key={question.id}
                                    question={question} 
                                    collectionId={collectionId}
                                    moduleId={moduleId}
                                    qnaLevel={selectedQnALevel}
                                    videoId={videoId}
                                    selectedLicense={selectedLicense}
                                    currentUser={currentUser}
                                    enrolledLicenses={enrolledLicenses}
                                />
                    })
                }
            </>
        } else {
            return <>
                {renderQnAPostQuestionContent()}
                No questions asked yet
            </>
        }
    }


    
    return  <div id='q-and-a-section'>
                <div id='q-n-a-header'>
                    <Space align="baseline" size="small">
                        <Title level={3} style={{display: "inline-block"}}>Q&A</Title>
                        <Text className="qna-sub-heading">Discussion for </Text>
                        <Select 
                            style={{width: 200, marginTop: 5}} 
                            size="small" 
                            value={selectedLicense}
                            onChange={handleOnLicenseSelectChange}
                        >
                            {filteredLicenses.map(license => <Option value={license.id}>{license.label}</Option>)}
                        </Select>
                    </Space>
                    <Space >
                        <div id='q-n-a-instructor-square'></div>
                        <Text className="instructor-color">Includes Instructor Response</Text>
                    </Space>
                </div>
                <Divider style={{ margin: "0 0 12px 0"}}/>

             
                { 
                    renderInnerContent()
                }
            </div>
}

const mapStateToProps = state => {
    return {
        currentUser: state.userReducer.user,
        fetchingEnrolledLicensesStatus: state.userReducer.fetchEnrolledLicensesStatus,
        enrolledLicenses: state.userReducer.enrolledLicenses,
        collectionQnA: state.collectionReducer.collectionQnA,
        collectionQnAStatus: state.collectionReducer.collectionQnAStatus,
        moduleQnA: state.collectionReducer.moduleQnA,
        moduleQnAStatus: state.collectionReducer.moduleQnAStatus,
        videoQnA: state.collectionReducer.videoQnA,
        videoQnAStatus: state.collectionReducer.videoQnAStatus,
    }
}
export default connect(mapStateToProps, { fetchCollectionQnA, fetchModuleQnA, fetchVideoQnA, postQnAQuestion})(QnAContainer);