import { Button, Card, Col, Collapse, Descriptions, Divider, Image, List, message, Modal, PageHeader, Row, Space, Tooltip, Typography, } from 'antd';
import { CheckOutlined} from '@ant-design/icons';
import { useState } from 'react';
import ModulesComponent from '../ModulesComponent';
import ModulesComponentList from '../ModulesComponentList';

const { Paragraph } = Typography;
const SelectedCollection = ({collection, clearSelectedCollection, copyModuleOrCollection, licensedVideos, playVideoInModal, addImportedVideos}) => {
    
    const [collectionCopied, setCollectionCopied] = useState(false)

    const addAllVideosFromCollection = () => {
        let videosToAdd = collection.modules.map(module => module.videos).flat()
        videosToAdd = videosToAdd.filter(video => !licensedVideos.includes(video.id))
        addImportedVideos(videosToAdd)
        message.success("Videos successfully imported")
    }
    
    const renderAddVideosFromCollectionButton = (collectionData) =>{
        const allVideosImported = collectionData.modules.every(moduleData => {
            return moduleData.videos.every(video => licensedVideos.includes(video.id))
        })
        if(allVideosImported) {
            return <Button type="primary" key="copyCollection" disabled><CheckOutlined /> All videos added</Button>
        }else {

            const handleOnCopyClick = () => {
                setCollectionCopied(true)
                copyModuleOrCollection(collection.modules)
                message.success("Collection copied.")
            }
            return <Space>
                    {copyModuleOrCollection ? <Tooltip title="Copy all modules and their basic data (including videos) directly into your collection" ><Button key="copyCollection" type="default" onClick={handleOnCopyClick} disabled={collectionCopied}>Copy collection</Button></Tooltip> : null}
                    <Tooltip title="Add all videos to your unassigned videos" ><Button type="primary" key="addCollection" onClick={addAllVideosFromCollection}>Add all videos</Button></Tooltip>
                </Space>
        }
    } 

    return <div>
                <PageHeader
                    className="import-collection-modal"
                    title={collection.name}
                    subTitle={`By ${collection.createdByUsername}`}
                    ghost={true}
                    onBack={clearSelectedCollection}
                    extra={[renderAddVideosFromCollectionButton(collection)]}
                >
                    <Row gutter={[16, 4]}>
                        <Col span={16}><Paragraph>{collection.longDescription}</Paragraph></Col>
                        <Col span={8}><Image src={collection.coverImage.image} preview={false}></Image></Col>
                    </Row>
                </PageHeader>
                <h4>Modules:</h4>
                <Divider style={{marginBottom: "0px"}}/>
                <ModulesComponentList 
                    modules={collection.modules} 
                    playVideoInModal={playVideoInModal} 
                    addImportedVideos={addImportedVideos} 
                    copyModuleOrCollection={copyModuleOrCollection} 
                    licensedVideos={licensedVideos}
                    moduleActions={['add']} 
                    videoActions={['add', 'play']} 
                    enablePagination={false}
                />  
            </div>
}

export default SelectedCollection;