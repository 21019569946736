import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, List, PageHeader, Row, Space, Spin, Tag, Typography} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { fetchUsedScheduledLicense } from '../actions/user';
import CouponDataPreferencesModal from './CouponDataPreferencesModal';

const { Title, Text } = Typography;

class UsedCouponPage extends Component {
    componentDidMount() {
        if(!this.props.fetchingUser && this.props.isAuthenticated){
            this.props.fetchUsedScheduledLicense()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
            this.props.fetchUsedScheduledLicense()
        }
    }

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: 'Home'
                }, {
                    path: "/collections/",
                    breadcrumbName: "Scheduled Licenses"
                }
            ]
        }
    }
    render(){
        if(this.props.fetchingUser || (!this.props.isAuthenticated && localStorage.getItem("access"))){
            return <Space align='center'>
                <Spin indicator={<LoadingOutlined spin style={{ fontSize: 50 }}/>}/> 
                <Title >Loading...</Title>
            </Space>
        }

        const schedules = this.props.usedCoupons.map(license => {
            return <Col span={10} className="scheduled-license-col">
                        <List
                            header={<Space>COUPON CODE:  <Tag>{license.couponCode}</Tag> <CouponDataPreferencesModal usedCoupon={license}/></Space>}
                            bordered
                            dataSource={license.scheduledAccess}
                            pagination={{
                                pageSize: 5,
                            }}
                            renderItem={access => {
                                let startDate = new Date(access.accessFrom);
                                let endDate = new Date(access.accessUntil);
                                let currentDate = new Date();
                                let isActive = currentDate >= startDate && currentDate < endDate;
                                let isExpired = currentDate >= startDate && currentDate >= endDate;

                                var tag;
                                if(this.props.user.moduleAccess[access.module.id] && this.props.user.moduleAccess[access.module.id].accessType === "permanent"){
                                    tag = <Tag color='#108ee9'>Purchased</Tag>
                                }
                                else if(isActive && !this.props.user.moduleAccess[access.module.id]){
                                    tag = <Tag color='green'>Active</Tag>
                                }
                                else if(isActive){
                                    tag = <Tag color='#87d068'>Available</Tag>
                                }
                                else if(isExpired){
                                    tag = <Tag color='#f50'>Expired</Tag>
                                }else {
                                    tag = <Tag>Inactive</Tag>
                                }

                                let linkToModuleCollection = access.module.parentCollection ? <Link to={`/collections/${access.module.parentCollection}/${access.module.parentCollectionUnlistedKey ? `?key=${access.module.parentCollectionUnlistedKey}` : ""}`}>
                                                                                                {access.module.name}
                                                                                            </Link> : <Text disabled>{access.module.name} (Removed from collection)</Text>


                                return <List.Item actions={[tag]}>
                                    <Space direction="vertical">
                                        {linkToModuleCollection}
                                        <Space>
                                            Access From:
                                            <Text keyboard>{startDate.toLocaleString()}</Text> → 
                                            <Text keyboard>{endDate.toLocaleString()}</Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                            }}
                        />
                 
                </Col>
        })
        return  <div id="scheduled-licenses-page">
                    <br/>
                    <PageHeader
                        title="Temporary Access Schedules"
                        className="site-page-header"
                        ghost={false}
                        onBack={() => window.history.back()}
                        breadcrumb={this.breadcrumbConfig()}
                    >
                        <Space direction="vertical" size={2}>Once you've used a scheduled licensed coupon, you will be granted temporary access to content according to a designated schedule.
                            <br/>
                            <Title level={4}>Legend</Title>
                            <Space size={20}>
                                <Space align="center" size={1}><Tag color='#108ee9'>Purchased</Tag><Text>You own this module and can access it at any time</Text></Space>
                                <Space align="center" size={1}><Tag color='#87d068'>Available</Tag><Text>Currently available to you until the end date as listed</Text></Space>
                                <Space align="center" size={1}><Tag color='green'>Active</Tag><Text>Not available to you, but currently available to everyone in the course*</Text></Space>  
                                <Space align="center" size={1}><Tag color='#f50'>Expired</Tag><Text>No longer available to you </Text></Space> 
                                <Space align="center" size={1}><Tag>Inactive</Tag><Text>Not yet available to you until the start date as listed </Text></Space> 
                            </Space>
                            <br/>
                            <Text>* Refunding a purchase after using a scheduled license coupon will make you ineligible for temporary access to that module or collection.</Text>
                        </Space>
                    </PageHeader>
                    <br/>
                    <Row gutter={[16,16]}>
                        {schedules}
                    </Row>
                </div>
    }
}

const mapStateToProps = state => {
    return {
        usedCoupons: state.userReducer.scheduledLicenses,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps, {fetchUsedScheduledLicense})(UsedCouponPage);