import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Layout} from 'antd';
import Player from '@vimeo/player';

import VideoPageFeatureMenu from '../VideoPageFeatureMenu';
import { postVideoWatchData } from '../../../actions/user';
import { setSkipToVideoTimestamp, setVideoTimestamp } from '../../../actions/videoControl';


const {Content} = Layout;
var BASE_RATE = 10000;

class VimeoPlayer extends Component {

    constructor(props){
        super(props)
        this.state = {
            trackingInterval: null,
            playerRef: null,
            currentTime: null,
            transcriptTimeout: null,
        }
    }
    componentDidMount = () => {
        var options = {
            id: this.props.video.embedId,
            autoplay: true,
        };
        var player = new Player('vimeo-player', options);
        if(this.props.customStartTime) {
            player.setCurrentTime(this.props.customStartTime)
            this.props.setVideoTimestamp(this.props.customStartTime)
        }
        this.setState({playerRef: player});
        player.on('play', this.handleOnPlay);
        player.on('pause', this.handleOnPause);
        player.on('end', this.handleOnEnd);
        player.on('error', this.handleOnError);
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.video.embedId !== this.props.video.embedId){
            this.state.playerRef.loadVideo(this.props.video.embedId)
        }
        if(prevProps.skipToVideoTimestamp !== this.props.skipToVideoTimestamp && this.props.skipToVideoTimestamp !== null) {
            let element = document.getElementById('video-show-page');
            element.scrollIntoView({behavior: "smooth", block: "start"});
            this.seekToVideoTimestamp(this.props.skipToVideoTimestamp)
            this.props.setSkipToVideoTimestamp(null)
        }
    }

    componentWillUnmount = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.props.setVideoTimestamp(0)
        this.setState({trackingInterval: null, transcriptTimeout: null})
    }

    track = async () => {
        var [currentTime, playbackRate] = await Promise.all([this.state.playerRef.getCurrentTime(), this.state.playerRef.getPlaybackRate()])
        var pollRate = BASE_RATE

        this.props.postVideoWatchData(this.props.collection.id, this.props.module.id, this.props.video.id, currentTime, pollRate, playbackRate)
    }

    scrollToNextParagraph = (currentTime, currentElement) => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        var nextElement = currentElement.nextSibling;
        if(nextElement){
            var startTime = nextElement.getAttribute('data-start-time');
            var endTime = nextElement.getAttribute('data-end-time');
            var paragraphFlag = nextElement.getAttribute('data-paragraph-flag');
    
            if(startTime <= currentTime && endTime > currentTime && paragraphFlag === "true"){
                transcriptDiv.scrollTop = nextElement.offsetTop
            }
        }
    }
    
    seekToVideoTimestamp = (timestamp) => {
        this.state.playerRef.setCurrentTime(timestamp)
        this.props.setVideoTimestamp(timestamp)
        this.state.playerRef.getPaused().then(() =>{
            this.state.playerRef.play()
        })
    }

    seekOnTranscriptClick = (targetElement, timestamp) => {
        var transcriptDiv = document.getElementById('transcript-tab-content');
        transcriptDiv.scrollTop = targetElement.offsetTop
       this.seekToVideoTimestamp(timestamp)
    }

    updateVideoTimestamp = async () => {
        if(this.props.transcript.length > 0){
            var [currentTime, playbackRate] = await Promise.all([this.state.playerRef.getCurrentTime(), this.state.playerRef.getPlaybackRate()])
            var currentElement = document.getElementById('highlighted-transcript-segment');
            if(currentElement){
                this.scrollToNextParagraph(currentTime, currentElement);
            }
            this.props.setVideoTimestamp(currentTime)
            var timeout = setTimeout(this.updateVideoTimestamp, (this.props.nextTranscriptUpdate/playbackRate))
            this.setState({transcriptTimeout: timeout})
        }
    }

    handleOnPlay = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.updateVideoTimestamp()
        var trackingInterval = setInterval(this.track, BASE_RATE)
        this.setState({trackingInterval: trackingInterval})
    }

    removeIntervals = () => {
        if(this.state.trackingInterval){
            clearInterval(this.state.trackingInterval)
        }
        if(this.state.transcriptTimeout){
            clearTimeout(this.state.transcriptTimeout)
        }
        this.setState({trackingInterval: null, transcriptTimeout: null})
    }

    pausePlayer = () => {
        this.state.playerRef.pause()
    }

    handleOnEnd = () => {
        this.removeIntervals()
    }
    handleOnPause = () => {
        this.removeIntervals()
    }
    handleOnError = () => {
        this.removeIntervals()
    }
    render() {
        return <>
            <Content id="video-content-wrapper">
                <div id="video-wrapper"><div id='vimeo-player'></div></div>
            </Content>
            <VideoPageFeatureMenu pauseVideo={this.pausePlayer} seekOnTranscriptClick={this.seekOnTranscriptClick} collapsedTranscript={this.props.collapsedTranscript} seekToVideoTimestamp={this.seekToVideoTimestamp}/>
        </>
    }
}

const mapStateToProps = (state) => {
    return {
        nextTranscriptUpdate: state.videoControlReducer.nextTranscriptUpdate,
        setSkipToVideoTimestamp: state.videoControlReducer.setSkipToVideoTimestamp,
        transcript: state.collectionReducer.transcript
    }
}


export default connect(mapStateToProps, {postVideoWatchData, setSkipToVideoTimestamp, setVideoTimestamp})(VimeoPlayer);