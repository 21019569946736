import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useState }  from 'react';
import moment from 'moment';
import { updateScheduledLicense } from '../../actions/contentCreation';
import SchedulePerModule from './SchedulePerModule';

import {connect} from 'react-redux';


const { RangePicker } = DatePicker;
const { Text, Title } = Typography;


const EditScheduledLicenseModal = ({allLicenses, closeEditScheduledLicenseForm, collectionId, modules, selectedLicense, setNextLicense, updateScheduledLicense, visible}) => {

    const [editScheduledLicenseForm] = Form.useForm();
    const [modulesImported, setModulesImported] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [currentSelectedLicense, setCurrentSelectedLicense] = useState(selectedLicense);
    const [currentIndex, setCurrentIndex] = useState(0)
    
    const rangeConfig = {
        rules: [
            {
            type: 'array',
            required: true,
            message: 'Please select time!',
            },
        ],
    };

    if(!modulesImported && editScheduledLicenseForm){
        editScheduledLicenseForm.setFieldsValue({ 
            'scheduledAccesses': modules.map(module=>{
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                }
            }),        })
        setModulesImported(true)
    }

    if(currentSelectedLicense != selectedLicense){
        let existingScheduledAccesses = selectedLicense['scheduledAccesses'];
        let startDate = new Date(selectedLicense['validFrom'])
        let endDate = new Date(selectedLicense['expiresOn'])

        let dateRange = [moment(startDate), moment(endDate)] 
        let newScheduledAccesses = modules.map(module => {
            let foundModule = existingScheduledAccesses.find(access => access.module == module.id);
            if(foundModule) {
                startDate = new Date(foundModule['accessFrom']);
                endDate = new Date(foundModule['accessUntil'])
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                    'dateRange': [moment(startDate), moment(endDate)] 
                }

            }else {
                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name
                }
            }
        })
        
        editScheduledLicenseForm.setFieldsValue({
            'code': selectedLicense['code'],
            'label': selectedLicense['label'],
            'percentDiscount': selectedLicense['percentDiscount'],
            'couponType': selectedLicense['couponType'],
            'dateRange': dateRange,
            'scheduledAccesses': newScheduledAccesses,
            'allowableDataAccessChoices': selectedLicense['allowableDataAccessChoices']
        });
        if(selectedLicense){
            let i = allLicenses.findIndex(license => license.id == selectedLicense.id);
            if(i){
                setCurrentIndex(i);
            }
        }

        setCurrentSelectedLicense(selectedLicense);
    }
    
    const handlePrevCouponClick = () => {
        if(currentIndex > 0) {
            setCurrentIndex(currentIndex - 1)
            setNextLicense(allLicenses[currentIndex - 1].id)
        }
    }

    const handleNextCouponClick = () => {
        if(currentIndex < allLicenses.length - 1) {
            setCurrentIndex(currentIndex + 1)
            setNextLicense(allLicenses[currentIndex + 1].id)
        }
    }
    const handleOnFinish = (values) => {
        let couponValidDates = values['dateRange'];
        delete values.dateRange;
        values['validFrom'] = couponValidDates[0].startOf('day').format('YYYY-MM-DD');
        values['expiresOn'] = couponValidDates[1].endOf('day').format('YYYY-MM-DD');

        let formattedScheduledAccesses = values['scheduledAccesses'].map(access => {
            let dateRange = access['dateRange'];
            return {
                'collection': access['collection'],
                'module': access['module'],
                'accessFrom': dateRange[0].startOf('day').format(),
                'accessUntil': dateRange[1].endOf('day').format(),
            }
        })
        values['scheduledAccesses'] = formattedScheduledAccesses;
        values['discountedCollections'] = [];
        values['discountedModules'] = [];
        if(values['allowableDataAccessChoices'] == undefined) {
            values['allowableDataAccessChoices'] = [];
        }

        const afterEditSuccess = () => {
            /*editScheduledLicenseForm.setFieldsValue({
                'code': "",
                'label': "",
                'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
            });*/
            closeEditScheduledLicenseForm()
        }

        setSubmitLoading(true);
        const finishLoading = () => setSubmitLoading(false);
        updateScheduledLicense(selectedLicense.code, values, afterEditSuccess, finishLoading)
    } 

    const renderModalTitle = () => {
        if(selectedLicense){
            return <Text>Editing scheduled license with code: <Text code>{selectedLicense.code}</Text></Text>
        }else {
            return
        }
    }
    return <Modal title={renderModalTitle()} 
                visible={visible} 
                onCancel={closeEditScheduledLicenseForm}
                okText="Create"
                onOk={() => editScheduledLicenseForm.submit()}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={closeEditScheduledLicenseForm}>
                            Close
                        </Button>
                        <Button onClick={handlePrevCouponClick} disabled={currentIndex == 0}>
                        <LeftOutlined />
                        </Button>
                        <Button style={{marginLeft: 0}} type="text">Coupon {currentIndex + 1} of {allLicenses.length}</Button>
                        <Button style={{marginLeft: 0}} onClick={handleNextCouponClick} disabled={currentIndex == allLicenses.length - 1}>
                        <RightOutlined />
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => editScheduledLicenseForm.submit()} 
                            loading={submitLoading}
                        >

                            Update
                        </Button>
                    </div>
                }
            >
                <Form 
                    onFinish={handleOnFinish}
                    id="create-scheduled-license-form"
                    form={editScheduledLicenseForm}
                    layout="vertical"
                    initialValues={{
                        'couponType': 'scheduled_license',
                        'percentDiscount': 1.00,
                        'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
                    }}
                >
                    <Space align="center">
                        <Text>Code: <Text code style={{fontSize: 24}}>{editScheduledLicenseForm.getFieldValue('code')}</Text></Text>
                        <Form.Item
                            label="Coupon Code"
                            name="code"
                            style={{marginBottom: 0, width: 280}}
                            hidden
                            rules={[
                                { 
                                    required: true, 
                                    message: 'Please give your scheduled license a coupon code!'
                                },
                            ]}
                        >
                            <Input 
                                placeholder="Enter a coupon code" maxLength={20}
                            />
                        </Form.Item>
                      
                    </Space>
                    <br/><br/>

                    <Form.Item
                        label="Label"
                        name="label"
                        rules={[{ required: true, message: 'Please give your scheduled license a label!' }]}
                        tooltip="This label is meant for you to easily identify who/what this coupon is for. For example, 'COS126 SPRING 2021'. (Max 20 characters)"
                    >
                        <Input placeholder="Enter a coupon label" maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="percentDiscount"
                        hidden
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="couponType"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="dateRange" label="Redeemable from... to..." {...rangeConfig}>
                        <RangePicker />
                    </Form.Item>

                    <Form.Item 
                        name="allowableDataAccessChoices" 
                        label="Data Access Choices" 
                        rules={[{ required: true, message: 'Please pick one!' }]} 
                        tooltip={"We believe students should have the choice to opt in to sharing their data before sharing analytics to you. Based on which boxes you check, you determine which of these choices they can choose from."}>
                        <Checkbox.Group>
                        <Row>
                            <Col>
                                <Checkbox value="full" style={{ lineHeight: '32px' }}>
                                    Full
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="anonymous" style={{ lineHeight: '32px' }}>
                                    Anonymous
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="no_access" style={{ lineHeight: '32px' }}>
                                    No Access
                                </Checkbox>
                            </Col>
                        </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    
                    <Text strong>Schedule per Module</Text>
                    <Form.List name="scheduledAccesses">
                        {(fields, { add, remove }) => (
                        <div className="schedule-accesses-container">
                            {fields.map((field, index) => (
                               <SchedulePerModule key={index} field={field} index={index} rangeConfig={rangeConfig} formRef={editScheduledLicenseForm} />
                            ))}
                        </div>
                        )}
                    </Form.List>
    
                </Form>
            </Modal>
}

export default connect(null, {updateScheduledLicense})(EditScheduledLicenseModal);