import React from 'react';
import {Link} from 'react-router-dom';
import { Button, Space, Typography } from 'antd';

const {Title} = Typography;

const Home = () => {
    return <section id="landing-section">
        <div>
            <Title >A <span className="text-color-green">Smarter</span> Way to Study</Title>
            
            <Title level={5}>Top Computer Science, Engineering and Other videos from top authors.</Title>
            <br />
            <Space>
                <Link to='/collections/'><Button type="primary">Explore Now</Button></Link>
            </Space>
        </div>
    </section>
}

export default Home;