import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Collapse, Space, Tag, Tooltip } from 'antd';
import { ClockCircleOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { purchaseModule } from '../../actions/user';
import DataPreferencesModal from './DataPreferencesModal';

// https://github.com/ant-design/ant-design/issues/4853

const ModuleListItem = ({header, children, module, index, activeCoupon, user, purchaseModule, ...props}) => {
    const [loading, setLoading] =  useState(false)

    const handlePurchase = (event) =>{
        event.stopPropagation();
        setLoading(true)
        purchaseModule(module.id, setLoading)
    }
    
    const calculateModulePrice = () => {
        var couponDiscount = activeCoupon && activeCoupon.discountedModules.includes(module.id) ? parseFloat(activeCoupon.percentDiscount) : 0.00
        let totalPrice = Math.round(module.price * (1 - couponDiscount))
        for(var licensedModule of module.licensedModules){
            if(user && !user.moduleAccess[licensedModule.id]){
                if(!user.moduleAccess[licensedModule.id] !== "permanent"){
                    totalPrice += Math.round(licensedModule.price * (1 - couponDiscount))
                }
            }
        }
        return totalPrice
    }

    const licensedModuleTags = () => {
        var tags = []
        for(var licensedModule of module.licensedModules){
            if(!licensedModule.parentCollection) {
                // do nothing
            }
            else if(user && user.moduleAccess[licensedModule.id]){
                tags.push(<Tag key={`licensed-module-tag-${licensedModule.id}`} color={user.moduleAccess[licensedModule.id].accessType === "permanent" ? null : "#33bcb7"}><Link to={`/collections/${licensedModule.parentCollection}`}>{licensedModule.name}</Link></Tag>)
            }else {
                tags.push(<Tooltip placement="top" title={`${licensedModule.price} cupoints`}><Tag key={`licensed-module-tag-${licensedModule.id}`} color="#33bcb7"><Link to={`/collections/${licensedModule.parentCollection}`}>{licensedModule.name}</Link></Tag></Tooltip>)
            }
            
        }
        return <Space key="1">{tags}</Space>
    }

    const checkTemporaryAccess = (moduleId) => {
        if (user.moduleAccess[moduleId]){
            var hasTemporaryAccess =  user.moduleAccess[moduleId].accessType === "temporary";
            var accessFrom = new Date(user.moduleAccess[moduleId].accessFrom)
            var accessUntil = new Date(user.moduleAccess[moduleId].accessUntil)
            var todaysDateTime = new Date();
            if(hasTemporaryAccess && todaysDateTime >= accessFrom && todaysDateTime < accessUntil){
                return <Tooltip title={
                            <Space direction="vertical">
                                Temporary Access until: 
                                {new Date(user.moduleAccess[module.id].accessUntil).toLocaleString()}
                            </Space>}
                            placement="topRight"
                    >
                        <Button><ClockCircleOutlined/></Button>
                    </Tooltip>
            } else {
                return null
            }
        }
    }

    const modulePurchaseButton = () => {
        if (user){
            if (user.moduleAccess[module.id] == null || user.moduleAccess[module.id].accessType === "temporary"){
                var buttonColor = activeCoupon && activeCoupon.discountedModules.includes(module.id) ? {color: "#fff2e8", background: "#fa541c", borderColor: "#ffbb96"} : {}
                return <Space key={`module-purchase-btn-${module.id}`}>
                            <Button key={index} style={buttonColor} loading={loading} type="primary" onClick={handlePurchase}>
                                <ShoppingCartOutlined />{calculateModulePrice()} cupoints
                            </Button>
                            {checkTemporaryAccess(module.id)}
                        </Space>
            }else if(user.moduleAccess[module.id].accessType === "permanent") {
                return <Button disabled key={`module-purchase-btn-${module.id}`}> Purchased </Button> 
            }
        } else {
            return null
        }
    }

    const settingsPopover = () => {
        if(user){
            var accessObject = user.moduleAccess[module.id]
            if(accessObject){
                return accessObject.accessType === "permanent" ? <DataPreferencesModal key={`data-preference-btn-${module.id}`} accessObject={accessObject} /> : null
            }
        }
    }

    return <Collapse.Panel header={header} {...props} key={`module-${index}-collapse-panel`} extra={[licensedModuleTags(), modulePurchaseButton(), settingsPopover()]} >
        {children}
    </Collapse.Panel>
}

const mapStateToProps = state => {
    return {
        activeCoupon: state.userReducer.activeCoupon,
        user: state.userReducer.user
    }
}
export default connect(mapStateToProps, {purchaseModule})(ModuleListItem);