import React from 'react';
import {connect} from 'react-redux';

import TranscriptText from './TranscriptText';

const VideoTranscript = ({seekOnTranscriptClick, status, transcript, videoTimestamp}) => {
    if(status === "LOADING" || status === "NOT LOADED"){
        return "Loading transcript..."
    }
    else if(status === "LOADED" && transcript.length === 0){
        return <h1 className="empty-transcript-message">Curated transcripts will be available upon finalization of the collection.</h1>
    }
    else {
        return <div id="transcript-text-wrapper"><TranscriptText textArray={transcript} videoTimestamp={videoTimestamp} seekOnTranscriptClick={seekOnTranscriptClick}/></div>
    }
}

const mapStateToProps = state => {
    return {
        status: state.collectionReducer.transcriptStatus,
        transcript: state.collectionReducer.transcript,
        videoTimestamp: state.videoControlReducer.videoTimestamp
    }
}


export default connect(mapStateToProps, {})(VideoTranscript);
