import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Typography } from 'antd';
import { useState }  from 'react';
import moment from 'moment';
import SchedulePerModule from './SchedulePerModule';
import {createScheduledLicense} from '../../actions/contentCreation';
import {connect} from 'react-redux';

import {debounce} from 'lodash';


const { RangePicker } = DatePicker;
const { Text, Title } = Typography;


const NewScheduledLicenseModal = ({closeScheduledLicenseForm, collectionId, createScheduledLicense, modules, setSelectedLicense, visible}) => {

    const [createScheduledLicenseForm] = Form.useForm();
    const [modulesImported, setModulesImported] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [couponCodeUniqueMessage, setCouponCodeUniqueMessage] = useState("");
    const [validateStatus, setValidateStatus] = useState("");

    
    const rangeConfig = {
        rules: [
            {
            type: 'array',
            required: true,
            message: 'Please select time!',
            },
        ],
    };

    if(!modulesImported && createScheduledLicenseForm){
        let startDate = new Date();
        let endDate = new Date(startDate);

        createScheduledLicenseForm.setFieldsValue({ 
            'scheduledAccesses': modules.map(module=>{
                startDate = new Date(endDate);
                endDate.setDate(endDate.getDate() +  7);

                return {
                    'collection': collectionId,
                    'module': module.id,
                    'moduleName': module.name,
                    'dateRange': [moment(startDate), moment(endDate)] 
                }
            }),
            'dateRange': [moment(new Date()), moment(endDate)]
        })
        setModulesImported(true)
    }

    const handleOnFinish = (values) => {
        let couponValidDates = values['dateRange'];
        delete values.dateRange;
        values['validFrom'] = couponValidDates[0].startOf('day').format('YYYY-MM-DD');
        values['expiresOn'] = couponValidDates[1].endOf('day').format('YYYY-MM-DD');

        let formattedScheduledAccesses = values['scheduledAccesses'].map(access => {
            let dateRange = access['dateRange'];
            return {
                'collection': access['collection'],
                'module': access['module'],
                'accessFrom': dateRange[0].startOf('day').format(),
                'accessUntil': dateRange[1].endOf('day').format(),
            }
        })
        values['scheduledAccesses'] = formattedScheduledAccesses;
        values['discountedCollections'] = [];
        values['discountedModules'] = [];
        if(values['allowableDataAccessChoices'] == undefined) {
            values['allowableDataAccessChoices'] = [];
        }

        const afterCreationSuccess = (createdLicense) => {
            createScheduledLicenseForm.setFieldsValue({
                'code': "",
                'label': "",
                'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
            });
            closeScheduledLicenseForm()
            setSelectedLicense(createdLicense.id)
        }

        setSubmitLoading(true);
        const finishLoading = () => setSubmitLoading(false);
        createScheduledLicense(values, afterCreationSuccess, finishLoading)
    } 

    const handleOnCouponCodeChange = async (event) => {
        if(event.target.value.length >= 1){    
            setValidateStatus("validating")
            return fetch(`https://www.api.labs.cubits.ai/api/v1/coupons/check-coupon-uniqueness/`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "code": event.target.value
                })
            })
            .then((response) => {
                if(response.ok) {
                    setValidateStatus("success")
                    setCouponCodeUniqueMessage("Coupon code is valid.")
                    return
                }else {
                    throw new Error(response.status)
                }
            })
            .catch(error => {
                if(parseInt(error.message) === 400){
                    setValidateStatus("error")
                    setCouponCodeUniqueMessage("Coupon code is taken.")
                    return 
                }
            })
        }else {
            setValidateStatus("")
            setCouponCodeUniqueMessage("");
            return
        }
    }

    const handleFetchRandomCode = async () => {
        setValidateStatus("validating")
        return fetch(`https://www.api.labs.cubits.ai/api/v1/coupons/generate-random-code/`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if(response.ok) {
                    return response.json()
                }else {
                    throw new Error(response.status)
                }
            })
            .then(data => {
                setValidateStatus("success")
                setCouponCodeUniqueMessage("Coupon code is valid.")
                return createScheduledLicenseForm.setFieldsValue({'code': data['code']})
            })
            .catch(error => {
                if(parseInt(error.message) === 400){
                    return 
                }
            })
    }
    return <Modal title="Create a Scheduled License" 
                visible={visible} 
                onCancel={closeScheduledLicenseForm}
                okText="Create"
                onOk={() => createScheduledLicenseForm.submit()}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button  style={{ marginRight: 8 }} onClick={closeScheduledLicenseForm}>
                            Cancel
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => createScheduledLicenseForm.submit()} 
                            loading={submitLoading}
                            disabled={validateStatus == "error"}
                        >

                            Create
                        </Button>
                    </div>
                }
            >
                <Form 
                    onFinish={handleOnFinish}
                    id="create-scheduled-license-form"
                    form={createScheduledLicenseForm}
                    layout="vertical"
                    initialValues={{
                        'couponType': 'scheduled_license',
                        'percentDiscount': 1.00,
                        'allowableDataAccessChoices': ['full', 'anonymous', 'no_access']
                    }}
                >
                    <Space align="center">
                        <Form.Item
                        label="Coupon Code"
                        name="code"
                        style={{marginBottom: 0, width: 280}}
                        rules={[
                            { 
                                required: true, 
                                message: 'Please give your scheduled license a coupon code!'
                            },
                        ]}
                        hasFeedback
                        
                        validateStatus={validateStatus}
                        help={couponCodeUniqueMessage}
                    >
                        <Input 
                            placeholder="Enter a coupon code" maxLength={20} onChange={debounce(handleOnCouponCodeChange, 500)}
                        />
                    </Form.Item>
                        <Button style={{marginTop: 7}} onClick={handleFetchRandomCode}>Generate Random Code</Button>
                    </Space>
                    <br/>

                    <Form.Item
                        label="Label"
                        name="label"
                        rules={[{ required: true, message: 'Please give your scheduled license a label!' }]}
                        tooltip="This label is meant for you to easily identify who/what this coupon is for. For example, 'COS126 SPRING 2021'. (Max 20 characters)"
                    >
                        <Input placeholder="Enter a coupon label" maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        name="percentDiscount"
                        hidden
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        name="couponType"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="dateRange" label="Redeemable from... to..." {...rangeConfig}>
                        <RangePicker />
                    </Form.Item>

                    <Form.Item 
                        name="allowableDataAccessChoices" 
                        label="Data Access Choices" 
                        rules={[{ required: true, message: 'Please pick one!' }]} 
                        tooltip={"We believe students should have the choice to opt in to sharing their data before sharing analytics to you. Based on which boxes you check, you determine which of these choices they can choose from."}>
                        <Checkbox.Group>
                        <Row>
                            <Col>
                                <Checkbox value="full" style={{ lineHeight: '32px' }}>
                                    Full
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="anonymous" style={{ lineHeight: '32px' }}>
                                    Anonymous
                                </Checkbox>
                            </Col>
                            <Col >
                                <Checkbox value="no_access" style={{ lineHeight: '32px' }}>
                                    No Access
                                </Checkbox>
                            </Col>
                        </Row>
                        </Checkbox.Group>
                    </Form.Item>
                    
                    <Text strong>Schedule per Module</Text>
                    <Form.List name="scheduledAccesses">
                        {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                               <SchedulePerModule key={index} field={field} index={index} rangeConfig={rangeConfig} formRef={createScheduledLicenseForm} />
                            ))}
                        </>
                        )}
                    </Form.List>
    
                </Form>
            </Modal>
}

export default connect(null, {createScheduledLicense})(NewScheduledLicenseModal);