import { Typography } from "antd";

const { Text, Paragraph} = Typography;

const PolicyModelTooltip = ({policyModel}) => ( <Paragraph className="white-color-text">
        If you price your module(s)...
        <ul>
            <li>above <Text strong className="white-color-text"> {policyModel.platformThresholdPrice} cupoints, </Text>
                you will be paid
                <Text strong className="white-color-text"> {policyModel.bonusPayoutRate * 100}% </Text>
                of the price of your module.
            </li>
            <li>
                between
                <Text strong className="white-color-text"> {policyModel.platformDefaultPrice} cupoints </Text>
                and
                <Text strong className="white-color-text"> {policyModel.platformThresholdPrice} cupoints </Text>
                (inclusive), you will be paid
                <Text strong className="white-color-text"> {policyModel.regularPayoutRate * 100}% </Text>
                of the price of your module.
            </li>
        </ul>
        If you don't want to set a custom price for each module, you can leave the default price (currently set to <Text strong className="white-color-text"> {policyModel.platformDefaultPrice} cupoints</Text>) as is and let the platform automatically handle the pricing of your modules.
        <br/><br/>
        <Text italic className="white-color-text">Please note: </Text> 
        <br/>
        CUbits can adjust the pricing of your module for promotional or other reasons.
    </Paragraph>)

export default PolicyModelTooltip;