import React from 'react';
import {Collapse, Space, Typography } from 'antd';
import ModuleListItem from './ModuleListItem';
import VideoList from './VideoList';

const {Text} = Typography
const ModuleList = ({modules, collectionId, unlistedKey}) => {
    return <Collapse defaultActiveKey={['module-0']} className="module-list">
        {
            modules.map((module, index) => {
                return <ModuleListItem header={<Space>{module.name}<Text type="secondary">({module.videos.length} Videos)</Text> </Space>} key={`module-${index}`} module={module} index={index+1}>
                    <VideoList videos={module.videos} moduleId={module.id} collectionId={collectionId} key={`module-${index}-video-list`} unlistedKey={unlistedKey}/>
                </ModuleListItem>
            })
        }
    </Collapse>
}

export default ModuleList;