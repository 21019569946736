import { Button, Card, Form, Input, InputNumber, PageHeader, Result, Select, Space, Spin, Typography } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { applyForCreator, fetchAllDepartments, fetchAllUniversities } from './actions/user';


const CreatorSignup = ({ applyForCreator, departments, fetchAllDepartments, fetchAllUniversities, fetchingUser, isAuthenticated, universities, user}) => {
    const [form] = Form.useForm();
    const [fetchedDepartments, setFetchedDepartments] = useState(false);
    const [fetchedUniversities, setFetchedUniversities] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false)

    const { Option } = Select;
    const { Text } = Typography; 

    const afterSuccess = () => {
        setSubmitLoading(false)
    }
    const onFinish = (values) => {
        console.log('Success:', values);
        setSubmitLoading(true)
        applyForCreator(user.id, values, afterSuccess)
    };

    const layout = {
        wrapperCol: { span: 8 },
    };

    const addCourseLayout = {
        wrapperCol: { span: 4}
    }

    if(user && user.isContentCreator){
        return <Redirect to='/creator/home' />
    }else if(localStorage.getItem("access") != null && !isAuthenticated){
        return <Space size="middle" className="loading-indicator-container">
                    <Spin size="large" />
                </Space>
    }else if(localStorage.getItem("access") == null){
        return <div className="centered-on-screen">
                    <Result
                        title="You must login or signup before applying to be an aggregator"
                        extra={[
                            <Button type='primary'>
                                <a href='https://www.api.labs.cubits.ai/accounts/signup/?next=https://www.cubits.ai/aggregator/apply/'>
                                    Sign Up
                                </a>
                            </Button>,
                            <Button>
                                <a href='https://www.api.labs.cubits.ai/accounts/login/?next=https://www.cubits.ai/aggregator/apply/'>
                                    Login
                                </a>
                            </Button>
                        ]}
                    />
                    <Text mark>You must complete the Creator Application Form to complete your registration as an aggregator. If you get redirected away from this page, please navigate back here to complete the form.</Text>
                </div>
    }else {
        if(!fetchedDepartments){
            fetchAllDepartments()
            setFetchedDepartments(true)
        } 
        if(!fetchedUniversities){
            fetchAllUniversities()
            setFetchedUniversities(true)
        }

        return <div id="creator-application-form-page">
                    <PageHeader
                        ghost={false}
                        title="Creator Application Form"
                        subTitle="Apply to be a creator"
                        
                    >
                    </PageHeader>
                    <br/>
                    <Card style={{padding: "15px"}}>
                    <Form id="creator-application-form" form={form} name="control-hooks" onFinish={onFinish} layout="vertical">
                        <Form.Item
                            {...layout}
                            name={'firstName'}
                            label="First Name"
                            rules={[{ required: true, message: 'Missing first name' }]}
                        >
                            <Input placeholder="First Name" maxLength={150}/>
                        </Form.Item>
                        <Form.Item
                            {...layout}
                            name={'lastName'}
                            label="Last Name"
                            rules={[{ required: true, message: 'Missing last name' }]}
                        >
                            <Input placeholder="Last Name" maxLength={150}/>
                        </Form.Item>
                        <Form.Item {...layout} name="universities" label="Universities" rules={[{ required: true }]}>
                            <Select placeholder="Please select a university" mode="tags">
                                {universities.map(university => <Option value={university.name} key={university.name}>{university.name}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item {...layout} name="departments" label="Departments" rules={[{ required: true }]}>
                            <Select placeholder="Please select a department" mode="tags">
                                {departments.map(department => <Option value={department.name} key={department.name}>{department.name}</Option>)}
                            </Select>
                        </Form.Item> 
                        <Text>Courses</Text>
                        <Form.List 
                            name="courses" 
                            rules = {[{
                                validator: async (_, courses) => {
                                    if (!courses || courses.length < 1) {
                                        return Promise.reject(new Error('At least one course required'));
                                    }
                                }
                            }]}
                        >
                            {(fields, { add, remove }, {errors}) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 , marginLeft: 15}} align="center">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Course Name"
                                            fieldKey={[fieldKey, 'name']}
                                            rules={[{ required: true, message: 'Missing course name' }]}
                                        >
                                            <Input placeholder="Course Name" maxLength={100}/>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'numOfStudents']}
                                            label="Class Size"
                                            fieldKey={[fieldKey, 'numOfStudents']}
                                            rules={[{ required: true, message: 'Missing course size' }]}
                                        >
                                            <InputNumber min={1} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.ErrorList errors={errors} />
                                <Form.Item {...addCourseLayout}>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add course
                                    </Button>
                                </Form.Item>
                            </>
                            )}
                        </Form.List>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={submitLoading}>
                            Submit
                            </Button>
                        </Form.Item>
                        </Form>
                    </Card>
                </div>
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        departments: state.userReducer.departments,
        universities: state.userReducer.universities
    }
}
export default connect(mapStateToProps, {applyForCreator, fetchAllDepartments, fetchAllUniversities})(CreatorSignup);