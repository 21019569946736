import {Card, List} from 'antd';
import {useHistory} from 'react-router-dom';


const UserCreatedCollectionsList = ({collections, playVideoInModal}) => {
    const history = useHistory();
    return <List
        grid={{ gutter: 12, column: 4}}
        dataSource={collections}
        renderItem={collection => (
            <List.Item key={collection.id} >
                <Card
                    hoverable
                    cover={<img alt="example" src={collection.coverImage.image} />}
                    onClick={()=>history.push(`/creator/created-collections/${collection.id}/`)
                }
                >
                    <Card.Meta title={collection.name} />
                </Card>
            </List.Item>
        )}
    />
}

export default UserCreatedCollectionsList;