import { Card, Typography } from "antd";
import { Link as RouterLink } from 'react-router-dom';
import Highlighter from "react-highlight-words";

const { Link, Text,Title } = Typography;
const CollectionSearchResultsVideoTranscriptList = ({collectionId, moduleId, searchValue, unlistedKey, video}) => {
    
    const convertSecondsToVideoTime = (seconds) => {
        seconds = Math.floor(seconds);
        var minutes = Math.floor(seconds / 60);
        var minuteString = `${minutes}`.padStart(2, "0")
        var secondsString = `${seconds - minutes * 60}`.padStart(2, "0")
        return `${minuteString}:${secondsString}`
    }

    if(video) {
        return <div className="collection-search-video-transcripts-container">
            <div>
                <Title 
                    level={4} 
                    style={{color: "white"}}
                >
                    Results for <span className="collection-search-keyword-highlight">"{searchValue}"</span> in transcript for video <span style={{backgroundColor: "#1a5c5a", padding: 2, borderRadius: 2}}>{video.title}</span>
                </Title>
            </div>
            {
                video.transcripts.map(transcript => (
                    <RouterLink to={`/collections/${collectionId}/modules/${moduleId}/videos/${video.id}/?t=${transcript.startTime}${unlistedKey ? "&key=" + unlistedKey : ""}`}>
                        <div className="collection-search-video-transcript-segment">
                            <Link>
                                {convertSecondsToVideoTime(transcript.startTime)}
                            </Link>
                            <Text 
                                style={{marginLeft: "10px", marginRight: "10px"}}
                                className="collection-search-keyword-unhighlight"
                            >
                                -
                            </Text>
                            <Text>
                            <Highlighter
                                unhighlightClassName="collection-search-keyword-unhighlight"
                                highlightClassName="collection-search-keyword-highlight"
                                searchWords={[searchValue]}
                                textToHighlight={transcript.text}
                            />
                            </Text>
                            <br/>
                        </div>
                    </RouterLink>
                ))
            }
        </div>
    }else {
        return <></>
    }
   
}

export default CollectionSearchResultsVideoTranscriptList;