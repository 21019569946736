import {getNewAccessToken} from './user.js';
import { message } from 'antd';

export const clearVideoPageContent = () => {
    return {
        type: "CLEAR_VIDEO_PAGE_CONTENT"
    }
}

export const clearSelectedModuleAndVideo = () => {
    return {
        type: "CLEAR_SELECTED_MODULE_AND_VIDEO"
    }
}
export const clearSingleCollection = () => {
    return {
        type: "CLEAR_SINGLE_COLLECTION"
    }
}
export const findAndSetSingleCollection = (collectionId) => {
    return {
        type: "FIND_AND_SET_SINGLE_COLLECTION",
        collectionId
    }
}

export const resetTranscriptData = () => {
    return {
        type: "RESET_TRANSCRIPT_DATA"
    }
}

export const setSingleCollection = (collectionData) => {
    return {
        type: "SET_SINGLE_COLLECTION",
        collectionData
    }
}

export const setFetchingCollections = (status) => {
    return {
        type: "SET_FETCHING_COLLECTIONS",
        status
    }
}

export const setOriginalCollections = (collections) => {
    return {
        type: "SET_ORIGINAL_COLLECTIONS",
        collections
    }
}


export const saveTranscript = (transcript) => {
    return {
        type: "SAVE_TRANSCRIPT",
        transcript
    }
}


export const setTranscriptStatus = (status) => {
    return {
        type: "SET_TRANSCRIPT_STATUS",
        status
    }
}

export const addNewQnAQuestion = (qnaLevel, newQuestion) => {
    return {
        type: "ADD_NEW_QNA_QUESTION",
        qnaLevel,
        newQuestion
    }
}

export const addNewQnAReply = (qnaLevel, questionId, reply) => {
    return {
        type: "ADD_NEW_QNA_REPLY",
        qnaLevel,
        questionId,
        reply
    }
}
export const setVideoQnAData = (qna) => {
    return {
        type: "SET_VIDEO_QNA_DATA",
        qna
    }
}
export const setModuleQnAData = (qna) => {
    return {
        type: "SET_MODULE_QNA_DATA",
        qna
    }
}
export const setCollectionQnAData = (qna) => {
    return {
        type: "SET_COLLECTION_QNA_DATA",
        qna
    }
}
export const saveQuizQuestions = (questions) => {
    return {
        type: "SAVE_QUIZ_QUESTIONS",
        questions
    }
}

export const setQuizQuestionStatus = (status) => {
    return {
        type: "SET_QUIZ_QUESTION_STATUS",
        status
    }
}

export const setVideoQnAStatus = (status) => {
    return {
        type: "SET_VIDEO_QNA_STATUS",
        status
    }
}
export const setModuleQnAStatus = (status) => {
    return {
        type: "SET_MODULE_QNA_STATUS",
        status
    }
}
export const setCollectionQnAStatus = (status) => {
    return {
        type: "SET_COLLECTION_QNA_STATUS",
        status
    }
}

export const updateOriginalCollection = (collectionData) => {
    return {
        type: "UPDATE_ORIGINAL_COLLECTION",
        collectionData
    }
}

export const fetchSingleCollection = (collectionId, unlistedKey, handleCollectionNotFound) => {
    
    let url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/`
    if(unlistedKey != null){
        url += `?key=${unlistedKey}`
    }
    return dispatch => {
        return fetch(url)
            .then(response => {
                if(response.ok){
                    return response.json()
                }else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                return dispatch(setSingleCollection(data))
            })
            .catch(error => {
                if(parseInt(error.message) === 401){
                    dispatch(getNewAccessToken(()=>fetchSingleCollection(collectionId, unlistedKey, handleCollectionNotFound)))
                }
                else if(parseInt(error.message) === 404){
                    return handleCollectionNotFound()
                }
                return console.log(error)
            })
    }
}

export const fetchOriginalCollections = () => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/`)
            .then (response => { 
                return response.json()
            })
            .then(data => {
                return dispatch(setOriginalCollections(data))
            })
            .catch(error => {
                if(parseInt(error.message) === 401){
                    dispatch(getNewAccessToken(()=>fetchOriginalCollections()))
                }
            })
    }
}


export const getVideoData = ({collectionId, moduleId, videoId}) => {
    return {
        type: "GET_VIDEO_DATA",
        collectionId, 
        moduleId, 
        videoId
    }
}

export const getTranscript = (videoId) => {
    return dispatch => {
        dispatch(setTranscriptStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/videos/${videoId}/transcripts/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {   
            dispatch(saveTranscript(data))
            dispatch(setTranscriptStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) === 401){
                dispatch(getNewAccessToken(()=>getTranscript(videoId)))
            }
        })
    }
}

// Retrieves quiz questions for a single video
export const getQuizQuestions = (collectionId, moduleId, videoId) => {
    return dispatch => {
        dispatch(setQuizQuestionStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(saveQuizQuestions(data))
            dispatch(setQuizQuestionStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>getQuizQuestions(videoId)))
            }
        })
    }
}


export const setLatestQuizQuestionAttempt = (questionId, attemptData) => {
    return {
        type: "SET_LATEST_QUIZ_QUESTION_ATTEMPT",
        questionId,
        attemptData
    }
}

export const submitQuizQuestionAttempt = (collectionId, moduleId, videoId, questionId, selectedAnswers, finishSubmission) => {
    return dispatch => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/${questionId}/attempts/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "selectedOptions": selectedAnswers
            })
        })
        .then(response => {
            if(response.ok) {
                return response.json()
            }else {
                throw new Error(response.status)
            }
        })
        .then(data => {
            dispatch(setLatestQuizQuestionAttempt(questionId, data))
            finishSubmission()
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>submitQuizQuestionAttempt(videoId, questionId, selectedAnswers)))
            }else {
                message.error("An error has occured.")
                finishSubmission()
            }
        })
    }
}


export const fetchVideoQnA = (collectionId, moduleId, videoId, couponId) => {
    return dispatch => {
        dispatch(setVideoQnAStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/?coupon=${couponId}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setVideoQnAData(data))
            dispatch(setVideoQnAStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>fetchVideoQnA(collectionId, moduleId, videoId, couponId)))
            }else {
                dispatch(setVideoQnAStatus("FAILED"))
            }
        })
    }
}

export const fetchModuleQnA = (collectionId, moduleId, couponId) => {
    return dispatch => {
        dispatch(setModuleQnAStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/?coupon=${couponId}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setModuleQnAData(data))
            dispatch(setModuleQnAStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>fetchModuleQnA(collectionId, moduleId, couponId)))
            }else {
                dispatch(setModuleQnAStatus("FAILED"))
            }
        })
    }
}

export const fetchCollectionQnA = (collectionId, couponId) => {
    return dispatch => {
        dispatch(setCollectionQnAStatus("LOADING"))
        return fetch(`https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/qnaquestions/?coupon=${couponId}`, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(setCollectionQnAData(data))
            dispatch(setCollectionQnAStatus("LOADED"))
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>fetchCollectionQnA(collectionId, couponId)))
            }else {
                dispatch(setCollectionQnAStatus("FAILED"))
            }
        })
    }
}

export const postQnAQuestion = (collectionId, moduleId, videoId, couponId, qnaLevel, formData, handleAfterSuccess) => {
    let url;
    if(qnaLevel == "collection"){
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/qnaquestions/`
    }else if(qnaLevel == "module"){
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/`
    }else {
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/`
    }
    return dispatch => {
        return fetch(url, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({
                "text": formData["text"],
                "anonymous": formData["anonymous"],
                "coupon": couponId
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(addNewQnAQuestion(qnaLevel, data))
            handleAfterSuccess()
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>postQnAQuestion(collectionId, moduleId, videoId, formData)))
            }else {
                console.log(error)
            }
        })
    }
}

export const postQnAReply = (collectionId, moduleId, videoId, questionId, couponId, qnaLevel, formData, handleAfterSuccess) => {
    let url;
    if(qnaLevel == "collection"){
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/qnaquestions/${questionId}/qnaanswers/`
    }else if(qnaLevel == "module"){
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/${questionId}/qnaanswers/`
    }else {
        url = `https://www.api.labs.cubits.ai/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/${questionId}/qnaanswers/`
    }

    return dispatch => {
        return fetch(url, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "text": formData['text'],
                "anonymous": formData["anonymous"],
                "coupon": couponId
            })
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }else {
                throw new Error(response.status);
            }
        })
        .then(data => {
            dispatch(addNewQnAReply(qnaLevel, questionId, data))
            handleAfterSuccess()
        })
        .catch(error => {
            if(parseInt(error.message) == 401){
                dispatch(getNewAccessToken(()=>postQnAReply(collectionId, moduleId, videoId, couponId, formData, handleAfterSuccess)))
            }else {
                console.log(error)
            }
        })
    }
}