export const setVideoTimestamp = (timestamp) => {
    return {
        type: "SET_VIDEO_TIMESTAMP",
        timestamp
    }
}

export const setNextTranscriptUpdate = (timeInterval) => {
    return {
        type: "SET_NEXT_TRANSCRIPT_UPDATE",
        timeInterval
    }
}

export const toggleMutedQuizQuestions = () => {
    return {
        type: "TOGGLE_MUTED_QUIZ_QUESTIONS"
    }
}

export const setSkipToVideoTimestamp = (timestamp) => {
    return {
        type: "SET_SKIP_TO_VIDEO_TIMESTAMP",
        timestamp
    }
}