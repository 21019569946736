import React from 'react';
import {connect} from 'react-redux';
import {Link, useHistory, useLocation} from 'react-router-dom';
import { BarChartOutlined, CalendarOutlined, ContainerOutlined, LogoutOutlined, MenuOutlined, PlusSquareOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Space, Typography } from 'antd';
import { logoutUser } from './actions/user';

const { Text } = Typography;

const LoginLogoutButtons = ({user, isAuthenticated, logoutUser}) => {
    const history = useHistory();
    const location = useLocation();

    const handleMenuClick = (e) => {
        switch(e.key){
          case "1":
            return history.push("/purchased-content/")
          case "2":
            return history.push("/scheduled-licenses")
          case "3":
            return history.push("/creator/home/")
          case "4":
            window.location.href = "https://www.api.labs.cubits.ai/accounts/login/?next=https://www.dashboard.cubits.ai/";
            return null;
          case "5":
            localStorage.removeItem("access")
            localStorage.removeItem("refresh")
            message.loading("Logging out...")
            logoutUser()
            return setTimeout(()=>{
              message.success("You are now logged out")
              return window.location = "https://www.api.labs.cubits.ai/accounts/logout/"
            }, 3000)
          default: 
            return
        }
    }


    const creatorMenu = <Menu onClick={handleMenuClick}>
                           
                            <Menu.Item key="1" icon={<ContainerOutlined />}>
                                Purchased Collections
                            </Menu.Item>
                            <Menu.Item key="2" icon={<CalendarOutlined />}>
                              Temporary Access Schedule
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item key="3" icon={<Text type="success"><PlusSquareOutlined /></Text>}>
                              <Text type="success">Creator Panel</Text>
                            </Menu.Item>
                            <Menu.Divider />
                            {/*<Menu.Item key="4" icon={<Text className="text-color-green"><BarChartOutlined /></Text>}>
                              <Text className="text-color-green">Dashboard</Text>
                            </Menu.Item>
                            <Menu.Divider />*/}
                            <Menu.Item key="5" icon={<LogoutOutlined />} danger>
                                Logout
                            </Menu.Item>
                        </Menu>
    
    const generalUserMenu = <Menu onClick={handleMenuClick}>
                                <Menu.Item key="1" icon={<ContainerOutlined />}>
                                    Purchased Collections
                                </Menu.Item>
                                <Menu.Item key="2" icon={<CalendarOutlined />}>
                                    Temporary Access Schedule
                                </Menu.Item>
                                <Menu.Divider />
                               
                                <Menu.Item key="5" icon={<LogoutOutlined />} danger>
                                    Logout
                                </Menu.Item>
                            </Menu>

    const menu = user.isContentCreator ? creatorMenu : generalUserMenu 

    if (!isAuthenticated){
      if(location.pathname == '/aggregator/apply/'){
        return <Space id='login-register-btn-wrapper' size='middle'>
                <Button className='login-btn' type='primary'>
                  <a href='https://www.api.labs.cubits.ai/accounts/signup/?next=https://www.cubits.ai/aggregator/apply/'>
                    Sign Up
                  </a>
                </Button>
                <Button className='login-btn' ghost>
                  <a href='https://www.api.labs.cubits.ai/accounts/login/?next=https://www.cubits.ai/aggregator/apply/'>
                    Login
                  </a>
                </Button>
              </Space>
      }else {
        return <Space id='login-register-btn-wrapper' size='middle'>
                <Button className='login-btn' type='primary'>
                  <a href='https://www.api.labs.cubits.ai/accounts/signup/'>
                    Sign Up
                  </a>
                </Button>
                <Button className='login-btn' ghost>
                  <a href='https://www.api.labs.cubits.ai/accounts/login/'>
                    Login
                  </a>
                </Button>
              </Space>
      }
    } else {
        return <Space id='login-register-btn-wrapper'>
                <Dropdown.Button overlay={menu} placement="bottomRight" icon={<MenuOutlined />}>
                  <Link to={`/account`}>
                    <Space >
                        <UserOutlined />
                        {user.username}
                    </Space>
                  </Link>
                </Dropdown.Button>
              </Space>
    }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.userReducer.isAuthenticated
  }
}

export default connect(mapStateToProps, {logoutUser})(LoginLogoutButtons);