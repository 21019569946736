import {useState} from 'react';
import { Badge, Card, Image, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
const {Text} = Typography;

const ImportedVideo = ({ selectVideo, videoData, videoSelected}) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)

    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/0.jpg`} preview={false} height={"100%"}/>
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                        height={"100%"}
                    />
        }
    }

    if(videoData.parentModule){
        let tooltipContent = <Text className="white-color-text">
             Video belongs to the module: <br /><Text className="white-color-text" strong>{`${videoData.parentModuleName}`}</Text>
            <br />
            with a price of <Text className="white-color-text" strong>{`${videoData.parentModulePrice} cupoints.`}</Text><br/><br/>

        
            You can still use this video in another module, but it will add <Text className="white-color-text" strong>{`${videoData.parentModulePrice} cupoints`}</Text> to your module's total price. 
            <br/><br/>
            You can use any number of videos from the same module listed above and the licensed price will remain the same.

           
        </Text>
        return  <Badge.Ribbon text={<Tooltip title={tooltipContent} placement="right"><Text className="white-color-text hoverable">Licensed Content <InfoCircleOutlined /></Text></Tooltip>} color="orange"><Card
                    className={`imported-video-card${videoSelected ? " video-selected" : ""}`}
                    cover={getVideoThumbnail(videoData)}
                    hoverable={true}
                    onClick={() => selectVideo(videoData)}
                    style={{height: "100%"}}       
                >
                    <Text ellipsis={{tooltip: <Tooltip title="hello" />}} style={{width: "100%"}}>{videoData.title}</Text>
                </Card></Badge.Ribbon>
    } else {
        return  <Card
                className={`imported-video-card${videoSelected ? " video-selected" : ""}`}
                cover={getVideoThumbnail(videoData)}
                hoverable={true}
                onClick={() => selectVideo(videoData)}
                style={{height: "100%"}}       
            >
                <Text ellipsis={{tooltip: <Tooltip title="hello" />}} style={{width: "100%"}}>{videoData.title}</Text>
            </Card>
    }
        
}

export default ImportedVideo;