import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Card, Image, Tag} from 'antd';
const { Meta } = Card;
const VideoListItem = ({collectionId, fetchingUser, index, isAuthenticated, moduleId, user, unlistedKey, video}) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)
    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/0.jpg`} preview={false}/>
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                    />
        }
    }
    const checkPurchasedModule = () => {
        if(isAuthenticated && !fetchingUser) {
            return user.moduleAccess[moduleId] ? true : false 
        }
    }
    return <Col xs={{ span: 5 }} key={`module-${moduleId}-video-${index}`}>
        <Link to={ unlistedKey != null ? `/collections/${collectionId}/modules/${moduleId}/videos/${video.id}/?key=${unlistedKey}` : `/collections/${collectionId}/modules/${moduleId}/videos/${video.id}`}>
            <div className={`ant-card ${checkPurchasedModule() ? "" : "greyed-card"}`}>
                <Card
                    hoverable={checkPurchasedModule()}
                    cover={getVideoThumbnail(video)}
                >
                    <Meta title={video.title} description={<>From module: <Tag>{video.parentModuleName}</Tag></>}/>
                </Card>
            </div>
        </Link>
    </Col>
}
const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        fetchingUser: state.userReducer.fetchingUser
    }
}
export default connect(mapStateToProps, {})(VideoListItem);