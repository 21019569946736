import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm';


const stripePromise = loadStripe("pk_test_51H8Ab3Hiwneg3vZPYuQcSmnExTwbVDGZ8W8hcZRYIAzMLVYZXodN0Koh9Nf4SacfCqXlnBIqZoUhxVTtuFKaXBmh00xnqc8ESm");


const StripePaymentContainer = ({resetReadyToCheckout, cupointsValue, currencyValue, currency}) => {

    return  (
        <Elements stripe={stripePromise}>
           <StripePaymentForm 
                cupointsValue={cupointsValue} 
                currency={currency} 
                currencyValue={currencyValue}
                resetReadyToCheckout={resetReadyToCheckout}
            />
        </Elements>
    )       
}

export default StripePaymentContainer;