import React, { Component } from 'react';
import { connect } from 'react-redux'; 
import { Button, Col, InputNumber, Modal, Radio, Row, Tooltip, Typography } from "antd";
import { UpCircleOutlined } from '@ant-design/icons';
import StripePaymentContainer from './StripePaymentContainer';


const { Text } = Typography;


class TopUpForm extends Component {

    constructor(props){
        super(props)
        this.state = {
            visible: false,
            confirmLoading: false,
            selected: null,
            value: null,
            readyToCheckout: false
        }
    }

    onRadioButtonChange = (event) => {
        if(event.target.value !== "other"){
            this.setState({
                selected: event.target.value,
                value: event.target.value
            })
        }else {
            this.setState({
                selected: event.target.value,
                value: null
            })
        }
       
    }

    onInputNumberChange = (value) => {
        this.setState({
            value: value
        })
    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }
    
    handleOk = () => {
        this.setState({
            visible: false,
            readyToCheckout: true
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            value: null,
            selected: null
        })
    }

    hidePaymentForm = () => {
        this.setState({
            visible: false,
            value: null,
            selected: null,
            readyToCheckout: false
        })
    }

    calculateCurrencyEquivalent = (amount) => {
        var amountInCurrency = amount / parseFloat(this.props.currency.unitCupoints) * parseFloat(this.props.currency.unitPrice)
        return amountInCurrency / Math.pow(10, parseInt(this.props.currency.allowableDecimalPlaces))
    }
    render() {
        return <>
            <Button style={{ marginTop: 28 }} type="primary" onClick={this.showModal} icon={<UpCircleOutlined />}>
                Recharge
            </Button>
            <Modal
                title="Recharge Your Wallet"
                visible={this.state.visible}
                onOk={this.handleOk}
                confirmLoading={this.state.confirmLoading}
                okButtonProps={this.state.value === null ? { disabled: true } : {} }
                onCancel={this.handleCancel}
                okText="Proceed to Checkout"
                cancelText="Cancel"
            >
                <div id='top-up-form'>
                <Text strong>Select a CUpoint amount: </Text>
                <br/>
                <Radio.Group value={this.state.value} onChange={this.onRadioButtonChange} size="medium" style={{ marginTop: 16 }} buttonStyle="solid">
                    <Tooltip placement="bottom" title={this.calculateCurrencyEquivalent(100) + ` ${this.props.currency.abbreviation}`}>
                        <Radio.Button value={100}>100</Radio.Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={this.calculateCurrencyEquivalent(200) + ` ${this.props.currency.abbreviation}`}>
                        <Radio.Button value={200}>200</Radio.Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={this.calculateCurrencyEquivalent(500) + ` ${this.props.currency.abbreviation}`}>
                        <Radio.Button value={500}>500</Radio.Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={this.calculateCurrencyEquivalent(1000) + ` ${this.props.currency.abbreviation}`}>
                        <Radio.Button value={1000}>1000</Radio.Button>
                    </Tooltip>
                    <Radio.Button value="other">
                        Custom
                    </Radio.Button>
                    { this.state.selected === "other" ? <InputNumber 
                                                            style={{ width: 100}} 
                                                            min={100}
                                                            max={10000}
                                                            onChange={this.onInputNumberChange} 
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                                                        /> : null }
                    
                </Radio.Group>
                <br/><br/>
                { this.state.value ? <>
                                        <Text strong>You have selected:</Text>
                                        <Row gutter style={{marginLeft: "20px"}}>
                                            <Col span={6}>{this.state.value} CUpoints</Col>
                                            <Col span={2}>=</Col>
                                            <Col span={6}>{`${this.calculateCurrencyEquivalent(this.state.value)} ${this.props.currency.abbreviation}`} </Col>
                                        </Row>
                                    </> : null
                }
                </div>
            </Modal>
            {this.state.readyToCheckout ? <StripePaymentContainer 
                                            resetReadyToCheckout={this.hidePaymentForm} 
                                            cupointsValue={this.state.value}
                                            currencyValue={this.calculateCurrencyEquivalent(this.state.value)}
                                            currency={this.props.currency}
                                        /> : null}
        </>
    }
}

const mapStateToProps = state => {
    return {
        currency: state.userReducer.user.primaryWallet.currency
    }
}
export default connect(mapStateToProps, {})(TopUpForm);