import { Card, Carousel, Drawer, Form, Input, Button, Select, Space, Tag, TimePicker, Typography} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Editor, EditorState, convertFromRaw } from "draft-js";
import RichTextPreview from '../../../Components/RichTextPreview';
import QuizQuestionOption from '../../../Collections/Video/QuizQuestions/QuizQuestionOption';

const QuestionCreatorFormPreview = ({handlePreviewDrawerClose, previewData,visiblePreview}) => {
    const [submitted, setSubmitted] = useState(false);
    const [selected, setSelected] = useState(false);
    const [revealed, setRevealed] = useState(false);
    const { Title } = Typography;
    
    const toggleRevealedAnswers = () => {
        setSubmitted(!submitted);
        setSelected(!selected);
        setRevealed(!revealed);
    }

    const handleClosePreview = () => {
        setSelected(false);
        setSubmitted(false);
        setRevealed(false);
        handlePreviewDrawerClose()
    }

    const renderQuestionType = () => {
        if(previewData.questionType == "select_one"){
            return "Select one"
        } else if(previewData.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        }
    }

    if(Object.keys(previewData).length === 0){
        return <Drawer
                    title="Missing data to preview"
                    placement="right"
                    closable={false}
                    onClose={handlePreviewDrawerClose}
                    visible={visiblePreview}
                    width={"37.5vw"}
                    
                >
                    <Title level={4}>{previewData.text}</Title>
                        Missing data to preview
                </Drawer>
    }else {

        const contentState = convertFromRaw(previewData['text']);
        const editorState = EditorState.createWithContent(contentState);

        return <Drawer
                    title={previewData.title}
                    placement="right"
                    closable={false}
                    onClose={handleClosePreview}
                    visible={visiblePreview}
                    width={"700px"}
                    footer={
                        <div
                            style={{
                            textAlign: 'right',
                            }}
                        >
                            <Button  style={{ marginRight: 8 }} onClick={handleClosePreview}>
                                Close Preview
                            </Button>
                            <Button onClick={toggleRevealedAnswers}>
                                <EyeOutlined /> {revealed ? "Hide Answers" : "Reveal Answers"}
                            </Button>
                        </div>
                    }
                >
                    <div className="quizquestion-drawer-content-width">
                    <RichTextPreview editorState={editorState}/>
                    <br/>
                    <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                        {previewData.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)
}
                    </Carousel>
                    <br/>
                    <Tag color="geekblue">{renderQuestionType()}</Tag>
                    {
                        previewData.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                            handleOnClick={() => {}}
                                                            selected={selected} 
                                                            submitted={submitted}
                                                            option={option}/>)
                    }
                    </div>
                </Drawer>
    }
    
}

export default QuestionCreatorFormPreview;