import React from 'react';
import {Row} from 'antd';
import VideoListItem from './VideoListItem';
const VideoList = ({collectionId, moduleId, unlistedKey, videos}) => {
    return <Row key={`module-${moduleId}-row`} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} wrap={false} className="module-video-list">
        {videos.map((video, index) => {
            return <VideoListItem collectionId={collectionId} moduleId={moduleId} video={video} index={index} unlistedKey={unlistedKey}/>
        })}
    </Row>
}
export default VideoList;