import { Button, Card, Comment, Divider, Space } from 'antd';
import { CloseOutlined, EyeInvisibleOutlined, SendOutlined } from '@ant-design/icons';
import QnAAnswer from './QnAAnswer';
import QnAReplyForm from './QnAReplyForm';
import RichTextPreview from '../../../Components/RichTextPreview';
import { compositeDecorator } from '../../../Components/RichTextDecoratorConfig';
import { EditorState, convertFromRaw } from "draft-js";
import { useState } from 'react';

const QnAQuestion = ({collectionId, currentUser, enrolledLicenses, moduleId, qnaLevel, question, selectedLicense, videoId}) => {
    const [visibleReplyForm, setVisibleReplyForm] = useState(false);
    const [visibleReplies, setVisibleReplies] = useState(false);
    const contentState = convertFromRaw(question['text']);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);

    const renderDisplayName = () => {
        if(question.anonymous){
            let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);
            if(question.user && question.user.id == currentUser.id){
                return "Anonymous User (You)"
            }else if(currentUser.id === currentLicense.createdById){
                return <div className="qna-anonymous-spoiler-tag">
                            {question.user.firstName && question.user.lastName ? `${question.user.firstName} ${question.user.lastName}` : question.user.username}
                            <div className="qna-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            } else {
                return "Anonymous User"
            }
        }else if(question.user.firstName && question.user.lastName){
            return `${question.user.firstName} ${question.user.lastName}`
        } else {
            return question.user.username
        }
        
        
    }
    
    const renderDate = () => {
        let date = new Date(question.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }

    const renderViewResponsesButton = () => {
        if(question.responses.length){
            return visibleReplies ? <p className="qna-sub-heading">Hide Replies</p> : <p className="qna-sub-heading">View Replies ({question.responses.length})</p>
        } else {
            return <p className="qna-sub-heading">No replies yet</p>
        }
    }

    const onViewRepliesClick = () => {
        let element = document.getElementById(`qna-question-${question.id}-replies`);
        if(element.style.maxHeight) {
            element.style.maxHeight = null;
        } else {
            element.style.maxHeight = element.scrollHeight + "px";
        }
        setVisibleReplies(!visibleReplies)
    }
    const handleOnReplyFormShow = () => {
        let element = document.getElementById(`qna-question-${question.id}-reply-form`);
        if(element.style.maxHeight || element.style.maxHeight == "none") {
            element.style.maxHeight = null;
        } else {
           // element.style.maxHeight = element.scrollHeight + "px";
           element.style.maxHeight = 10000 + "px";
        }
        setVisibleReplyForm(!visibleReplyForm)
    }

    const handleOnReplyFormSubmitSuccess = () => {
        let formElement = document.getElementById(`qna-question-${question.id}-reply-form`);
        formElement.style.maxHeight = null;
        let element = document.getElementById(`qna-question-${question.id}-replies`);
        element.style.maxHeight = element.scrollHeight + "px";
        setVisibleReplyForm(false)
        setVisibleReplies(true);
        setTimeout(()=>element.lastChild.scrollIntoView({behavior: "smooth", block: "center"}), 300)
        
    }

    return <>
            <Comment
                actions={[
                    <span 
                        key="comment-nested-reply-to" 
                        onClick={
                            handleOnReplyFormShow
                        }
                    >
                        {visibleReplyForm ? <>Cancel <CloseOutlined style={{marginTop: "10px"}}/></> : <>Reply <SendOutlined style={{marginTop: "10px"}}/></>}
                    </span>
                ]}
                author={renderDisplayName()}
                datetime={renderDate()}
                content={
                    <RichTextPreview editorState={editorState}/>
                }
            >
                <QnAReplyForm 
                    visible={visibleReplyForm} 
                    handleSubmitSuccess={handleOnReplyFormSubmitSuccess}
                    replyingTo={renderDisplayName()}
                    cancelReply={handleOnReplyFormShow}
                    collectionId={collectionId}
                    moduleId={moduleId}
                    qnaLevel={qnaLevel}
                    questionId={question.id}
                    videoId={videoId}
                    selectedLicense={selectedLicense}
                    currentUser={currentUser}
                    enrolledLicenses={enrolledLicenses}
                />
                <div id={`qna-question-${question.id}-replies`} className={`qna-replies`}>
                    {question.responses.map(response => {
                        return <QnAAnswer 
                                    currentUser={currentUser} 
                                    key={response.id} 
                                    response={response} 
                                    enrolledLicenses={enrolledLicenses}
                                    selectedLicense={selectedLicense}
                                />
                    })}
                </div>
            </Comment>
            <Divider style={{ margin: "5px 0 5px 0"}} plain>
                <Space>
                    {question.responses.some(response => response.instructorHighlight) && !visibleReplies ? <div id='q-n-a-instructor-square'></div> : null}
                    <Button type="text" size='small' onClick={onViewRepliesClick}>
                        {renderViewResponsesButton()}
                    </Button>
                </Space>
            </Divider>
        </>
}

export default QnAQuestion;