import {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {fetchUserCreatedCollections, fetchUserCreatedModules} from '../../actions/contentCreation';
import {getTranscript} from '../../actions/collection';
import {Link} from 'react-router-dom';
import {Button, Divider, Dropdown, Menu, PageHeader, Space, Spin, Tabs} from 'antd';
import {FileAddOutlined, FolderAddOutlined, PlusOutlined} from '@ant-design/icons';
import ModalVideoPlayer from '../ModalVideoPlayer';
import UserCreatedModulesList from './UserCreatedModulesList';
import UserCreatedCollectionsList from './UserCreatedCollectionsList';

const { TabPane } = Tabs;

class CreatorHome extends Component {
    constructor(){
        super()
        this.state = {
            sorted: "",
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        }
    }
    componentDidMount() {
        if(this.props.isAuthenticated && !this.props.receivedUserCreatedModules && !this.props.fetchingUserCreatedModules){
            this.props.fetchUserCreatedModules(this.props.user.id)
        }
        if(this.props.isAuthenticated && !this.props.receivedUserCreatedCollections && !this.props.fetchingUserCreatedCollections){
            this.props.fetchUserCreatedCollections(this.props.user.id)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false || (!this.props.receivedUserCreatedModules && !this.props.fetchingUserCreatedModules)){
            this.props.fetchUserCreatedModules(this.props.user.id)
        }
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false || (!this.props.receivedUserCreatedCollections && !this.props.fetchingUserCreatedCollections)){
            this.props.fetchUserCreatedCollections(this.props.user.id)
        }
        if(prevState.videoToPlay.embedId !== this.state.videoToPlay.embedId && this.state.visiblePlayer){
            this.props.getTranscript(this.state.videoToPlay.id)
        }
    }

    playVideo = (video, parentModule) => {
        this.setState({
            visiblePlayer: true, 
            videoToPlay: video,
            videoPlayedFromModule: parentModule
        })
    }

    closeVideo = () => {
        this.setState({
            visiblePlayer: false,
        })
    }

    createDropdown = () => {
        const menu = <Menu>
                        <Menu.Item>
                            <Link to="/collections/create/">
                                <FolderAddOutlined /> Collection
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to="/modules/create/">
                                <FileAddOutlined /> Module
                            </Link>
                        </Menu.Item>
                    </Menu>
        return  <Dropdown overlay={menu} placement="bottomLeft" arrow key="createDropdown">
                    <Button type="primary"><PlusOutlined /> Create</Button>
                </Dropdown>
    }

    applySort = (modules) => {
        switch(this.state.sorted){
            case "collection":
                return modules.filter(module => module.parentCollection).sort((a, b) => a.parentCollection - b.parentCollection)
            case "duration-low-to-high":
                return modules.sort((a,b) => a.totalDuration - b.totalDuration)
            case "duration-high-to-low": 
                return modules.sort((a,b) => b.totalDuration - a.totalDuration)
            case "price-low-to-high":
                return modules.sort((a, b) => a.price - b.price)
            case "price-high-to-low":
                return modules.sort((a,b) => b.price - a.price)
            case "unassigned-to-a-collection":
                return modules.filter(module => module.parentCollection === null)
            default:
                return modules
        }
    }
    render(){
        if(this.props.isAuthenticated && !this.props.user.isContentCreator){
            return <Redirect to='/collections' />
        }
        if(!this.props.receivedUserCreatedCollections || !this.props.receivedUserCreatedModules){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }

        let query = new URLSearchParams(this.props.location.search)
        let defaultTab = query.get('tab') == "2" ? "2" : "1" 

        return <div id='creator-panel'>
                <PageHeader
                    title="Creator Panel"
                    ghost={false}
                    onBack={() => this.props.history.push('/')}
                    extra={[
                        this.createDropdown()
                    ]}
                >
                        
                </PageHeader>
                <br/>
                
                <Tabs defaultActiveKey={defaultTab} type="card" id='creator-panel-tabs'>
                    <TabPane tab="My Collections" key="1">
                        <div style={{width: "100%", backgroundColor: "white", padding: "20px 20px 1px 20px"}}>

                        <UserCreatedCollectionsList collections={this.props.userCreatedCollections} playVideoInModal={this.playVideo}/>
                        </div>
                    </TabPane>
                    <TabPane tab="My Modules" key="2" forceRender>
                        <div style={{width: "100%", backgroundColor: "white", padding: "20px 0px 1px 20px"}}>
                            <Space >
                                Sort by: 
                                <Button shape="round" type={this.state.sorted == 'collection' ? "primary" : "default"} onClick={() => this.setState({sorted: 'collection'})}>Collection</Button> 
                                <Button shape="round" type={this.state.sorted == 'unassigned-to-a-collection' ? "primary" : "default"} onClick={() => this.setState({sorted: 'unassigned-to-a-collection'})}>Unassigned</Button>
                                <Button shape="round" type={this.state.sorted == 'price-low-to-high' ? "primary" : "default"} onClick={() => this.setState({sorted: 'price-low-to-high'})}>Price (Low to High)</Button> 
                                <Button shape="round" type={this.state.sorted == 'price-high-to-low' ? "primary" : "default"} onClick={() => this.setState({sorted: 'price-high-to-low'})}>Price (High to Low)</Button> 
                                <Button shape="round" type={this.state.sorted == 'duration-low-to-high' ? "primary" : "default"} onClick={() => this.setState({sorted: 'duration-low-to-high'})}>Duration (Low to High)</Button> 
                                <Button shape="round" type={this.state.sorted == 'duration-high-to-low' ? "primary" : "default"} onClick={() => this.setState({sorted: 'duration-high-to-low'})}>Duration (High to Low)</Button> 
                            </Space>
                            <Divider style={{marginBottom: "0px"}}/>
                        </div>
                        <UserCreatedModulesList modules={this.applySort(this.props.userCreatedModules)} playVideoInModal={this.playVideo}/>
                    </TabPane>
                    
                </Tabs>
                <div style={{background: "black", display: "inline-block"}}>
                    <ModalVideoPlayer 
                        visible={this.state.visiblePlayer} 
                        toggleVideoPlayerModalVisibility={this.closeVideo}
                        video={this.state.videoToPlay}
                        module={this.state.videoPlayedFromModule}
                        playVideoInModal={this.playVideo}
                        videoActions={['play']}
                    />
                </div>
            </div>
    }
}

const mapStateToProps = state => {
    return {
        user: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        fetchingUserCreatedCollections: state.contentCreationReducer.fetchingUserCreatedCollections,
        fetchingUserCreatedModules: state.contentCreationReducer.fetchingUserCreatedModules,
        userCreatedModules: state.contentCreationReducer.userCreatedModules,
        receivedUserCreatedModules: state.contentCreationReducer.receivedUserCreatedModules,
        userCreatedCollections: state.contentCreationReducer.userCreatedCollections,
        receivedUserCreatedCollections: state.contentCreationReducer.receivedUserCreatedCollections
    }
}
export default connect(mapStateToProps, {fetchUserCreatedCollections, fetchUserCreatedModules, getTranscript})(CreatorHome);