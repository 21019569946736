import { Card, DatePicker, Form, Input, Typography } from 'antd';
import { useState } from 'react';


const { RangePicker } = DatePicker;
const { Text, Title } = Typography;
const SchedulePerModule = ({field, formRef, index, rangeConfig}) => {

    const [selectedDuration, setSelectedDuration] = useState(0);
    const [firstValueReceived, setFirstValueReceived] = useState(false);

    const calculateDuration = () => {
        let ranges = formRef.getFieldValue(['scheduledAccesses', field.name, 'dateRange']);
        if(ranges){
            setSelectedDuration(ranges[1].diff(ranges[0], 'days'))
        }else {
            setSelectedDuration(0);
        }
        
    }

    if(!firstValueReceived){
        calculateDuration()
        setFirstValueReceived(true)
    }

    return  <><Card key={`scheduled-access-${index}`} hoverable>
                <Title level={5}>{formRef.getFieldValue(['scheduledAccesses', field.name, 'moduleName'])}</Title>
                <Form.Item
                    name={[field.name, 'moduleName']}
                    fieldKey={[field.fieldKey, 'moduleName']}
                    hidden
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Access From"
                    name={[field.name, 'dateRange']}
                    fieldKey={[field.fieldKey, 'dateRange']}
                    //rules={[{ required: true, message: 'Missing price' }]}
                    {...rangeConfig}
                >
                    <RangePicker onChange={calculateDuration}/>
                </Form.Item>
                <Text type="secondary">{ selectedDuration ? `Selected Duration:` : ""}</Text><Text>{` ${selectedDuration} days`}</Text>
            </Card><br/></>
}

export default SchedulePerModule;