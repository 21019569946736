import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import {Button, Col, Row, message, Modal, Space, Typography} from 'antd';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {topUpWallet} from '../actions/user';
import stripeBadge from './stripe-badge.svg';

const { Text } = Typography;

const StripePaymentForm = ({cupointsValue, currency, currencyValue, resetReadyToCheckout, topUpWallet, walletId}) => {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [isPaymentFormVisible, setIsPaymentFormVisible] = useState(true)
    const stripe = useStripe();
    const elements = useElements();


    const onPaymentCancel = () => {
        setIsPaymentFormVisible(false)
        resetReadyToCheckout()
    } 

    const CARD_STYLE = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        window
          .fetch("https://www.api.labs.cubits.ai/api/v1/create-payment-intent/", {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({cupoints: cupointsValue})
          })
          .then(res => {
            return res.json();
          })
          .then(data => {
            setClientSecret(data.clientSecret);
          });
    }, []);

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };


    const onPaymentOk = async ev => {
        ev.preventDefault();
        setProcessing(true);
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        });
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
          const handleAfterTopUpSuccess = () => {
            message.success("Purchase successful.")
            setProcessing(false)
            setSucceeded(true)
            resetReadyToCheckout()
          }
          topUpWallet(walletId, cupointsValue, payload.paymentIntent.id, handleAfterTopUpSuccess)
        }
    };

    
    return (
        <Modal 
            title={<Space><Text>Checkout</Text> <a target="_blank" rel="noopener noreferrer" href="http://stripe.com"><img src={stripeBadge} id="stripe-badge"/></a></Space>}
            visible={isPaymentFormVisible}
            onCancel={onPaymentCancel}
            footer={null}
            maskClosable={false}
        >
            <form id="payment-form" >
                <Row gutter={16}>
                    <Col span={12}>
                        <Text strong>Order details:</Text>
                        <br/>
                        <Text>Purchasing </Text>
                        <Text code>{cupointsValue} cupoints</Text>
                    </Col>
                    <Col span={12}>
                        <Text strong> Order Total: </Text>
                        <br/>
                        <Text>Payment of </Text>
                        <Text code>{`${currencyValue} ${currency.abbreviation}`}</Text>
                    </Col>
                </Row>
                <br/>
                <Text strong>Enter your card information:</Text> 
                <br/>
                <div id='stripe-container'>
                  <div className={`stripe-card-input ${error ? 'stripe-card-error' : ''}`}>
                    <CardElement options={CARD_STYLE} onChange={handleChange}/>
                  </div>
                  {/* Show any error that happens when processing the payment */}
      
                  <div className="card-error" role="alert">
                    {error ? error : null}
                  </div>
                
                  <Button type='primary' 
                          id='stripe-pay-now'
                          onClick={onPaymentOk}
                          loading={processing}
                          disabled={processing || disabled || succeeded}
                  >Pay now</Button>
                </div>
            </form>
           
        </Modal>
    )
}

const mapStateToProps = (state) => {
  return {
    walletId: state.userReducer.user.primaryWallet.id
  }
}
export default connect(mapStateToProps, {topUpWallet})(StripePaymentForm);