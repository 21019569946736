import { Card, Space, Tooltip, Typography } from 'antd';
import { EyeOutlined, FastForwardOutlined, SwapRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

const QuizQuestionCard = ({playerRef, setPreviewQuizQuestion, quizQuestion, video}) => {

    const handleJumpToTimestamp = () => {
        if(video.videoType == "youtube"){
            playerRef.seekTo(quizQuestion.startVideoTimestamp)
        }else if(video.videoType == "vimeo"){
            playerRef.setCurrentTime(quizQuestion.startVideoTimestamp)
        }
    }

    const convertSecondsToVideoTime = (seconds) => {
        var minutes = Math.floor(seconds / 60);
        var minuteString = `${minutes}`.padStart(2, "0")
        var secondsString = `${seconds - minutes * 60}`.padStart(2, "0")
        return `${minuteString}:${secondsString}`
    }
    
    return  <Card bordered
                style={{width: "350px", display: "inline-block", margin: 5}}
                actions={[
                    <div onClick={handleJumpToTimestamp}>
                        <Tooltip title="Jump to the beginning of covered section." placement="bottomLeft" color="cyan">
                            <FastForwardOutlined key="fastforward"/> Jump to timestamp
                        </Tooltip>
                    </div>,
                    <div onClick={()=>setPreviewQuizQuestion(quizQuestion)}><EyeOutlined key="ellipsis" /> View Question</div>,
                ]}
            >
                <Space align="start">
                    <Tooltip title={"The start of the section of which the question is covering"} placement="topLeft" color="cyan">
                        <Text keyboard >{convertSecondsToVideoTime(quizQuestion.startVideoTimestamp)}</Text>
                    </Tooltip>
                    <SwapRightOutlined />
                    <Tooltip title={"The end of the section of which the question is covering. The quiz question will be presented to them when the video reaches the timestamp"} placement="topLeft" color="cyan">
                        <Text keyboard >{convertSecondsToVideoTime(quizQuestion.endVideoTimestamp)}</Text>
                    </Tooltip>
                    <Tooltip title={quizQuestion.title.toUpperCase()} placement="topLeft" color="cyan"><Text style={{width:"190px"}} ellipsis={{}}>{quizQuestion.title.toUpperCase()}</Text></Tooltip>
                </Space>
            </Card>
}

export default QuizQuestionCard