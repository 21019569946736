const initialState = {
    fetchingUserCreatedCollections: false,
    fetchingUserCreatedModules: false,
    quizQuestionsForVideoStatus: "NOT LOADED",
    modulePool: [],
    userCreatedCollections: [],
    userCreatedModules: [],
    collectionDetail: null,
    collectionDetailLicenses: [],
    moduleDetail: null,
    policyModel: null,
    receivedCollectionDetail: false,
    receivedModuleDetail: false,
    receivedScheduledLicensesStatus: false,
    receivedUserCreatedCollections: false,
    receivedUserCreatedModules: false,
    receivedQuizQuestionsForVideo: false,
    notifyToEditLicenseOnCollectionChange: false,
    quizQuestionsForVideo: []
}

export default (state=initialState, action) => {
    switch(action.type){
        case "ADD_NEWLY_CREATED_COLLECTION":
            return Object.assign({}, state, {userCreatedCollections: [action.collectionData, ...state.userCreatedCollections]})
        case "ADD_NEWLY_CREATED_QUIZ_QUESTION":
            return Object.assign({}, state, { quizQuestionsForVideo: [...state.quizQuestionsForVideo, ...action.quizQuestionData ]})
        case "CLEAR_COLLECTION_DETAIL":
            return Object.assign({}, state, { collectionDetail: null, receivedCollectionDetail: false})
        case "CLEAR_POLICY_MODEL":
            return Object.assign({}, state, { policyModel: null})
        case "CLEAR_USER_CREATED_COLLECTIONS":
            return Object.assign({}, state, { userCreatedCollections: [], receivedUserCreatedCollections: false})
        case "CLEAR_USER_CREATED_MODULES":
            return Object.assign({}, state, { userCreatedModules: [], receivedUserCreatedModules: false})
        case "ADD_NEWLY_CREATED_MODULE":
            return Object.assign({}, state, { userCreatedModules: [action.moduleData, ...state.userCreatedModules]})
        case "ADD_NEWLY_CREATED_SCHEDULED_LICENSE":
            return Object.assign({}, state, { collectionDetailLicenses: [action.licenseData, ...state.collectionDetailLicenses]})
        case "REMOVE_DELETED_QUIZ_QUESTION":
            let quizQuestions = [...state.quizQuestionsForVideo];
            let index = quizQuestions.findIndex(quizQuestion => quizQuestion.id == action.questionId);
            quizQuestions.splice(index, 1);
            return Object.assign({}, state, { quizQuestionsForVideo: quizQuestions})
        case "RESET_MODULE_EDIT_DATA":
            return Object.assign({}, state, { receivedModuleDetail: false, moduleDetail: null})
        case "RESET_COLLECTION_EDIT_DATA":
            return Object.assign({}, state, { receivedCollectionDetail: false, collectionDetail: false})
        case "RESET_RECEIVED_COLLECTION_DETAIL":
            return Object.assign({}, state, { receivedCollectionDetail: action.status})
        case "SET_COLLECTION_DETAIL":
            return Object.assign({}, state, { collectionDetail: action.collectionData, receivedCollectionDetail: true})
        case "SET_FETCHING_USER_CREATED_COLLECTIONS":
            return Object.assign({}, state, { fetchingUserCreatedCollections: action.status})
        case "SET_FETCHING_USER_CREATED_MODULES":
            return Object.assign({}, state, { fetchingUserCreatedModules: action.status})
        case "SET_MODULE_DETAIL":
            return Object.assign({}, state, { moduleDetail: action.moduleData, receivedModuleDetail: true})
        case "SET_MODULE_POOL":
            return Object.assign({}, state, { modulePool: action.modules})
        case "NOTIFY_TO_EDIT_LICENSE_ON_COLLECTION_CHANGE_STATUS":
            return Object.assign({}, state, { notifyToEditLicenseOnCollectionChange: action.status })
        case "SET_POLICY_MODEL":
            return Object.assign({}, state, { policyModel: action.policyModel})
        case "SET_RECEIVED_SCHEDULED_LICENSES_STATUS":
            return Object.assign({}, state, { receivedScheduledLicensesStatus: action.status})
        case "SET_SCHEDULED_LICENSES_FOR_COLLECTION_DETAIL":
            return Object.assign({}, state, { collectionDetailLicenses: action.licenses})
        case "SET_USER_CREATED_MODULES":
            return Object.assign({}, state, { userCreatedModules: action.modules, receivedUserCreatedModules: true})
        case "SET_USER_CREATED_COLLECTIONS":
            return Object.assign({}, state, { userCreatedCollections: action.collections, receivedUserCreatedCollections: true})
        case "SET_QUIZ_QUESTIONS_FOR_VIDEO":
            return Object.assign({}, state, { quizQuestionsForVideo: action.quizQuestions, receivedQuizQuestionsForVideo: true})
        case "SET_QUIZ_QUESTIONS_FOR_VIDEO_STATUS":
            return Object.assign({}, state, { quizQuestionsForVideoStatus: action.status})
        case "UPDATE_COLLECTION_COVER_IMAGE":
            let newCreatedCollectionsState = state.userCreatedCollections;
            let collectionIndex = newCreatedCollectionsState.findIndex(element => element.id === state.collectionDetail.id);
            let collection = newCreatedCollectionsState[collectionIndex];
            collection['coverImage'] = { ...collection['coverImage'], image: action.imageUrl}
            newCreatedCollectionsState[collectionIndex] = collection;
            return Object.assign({}, state, { collectionDetail: collection, userCreatedCollections: newCreatedCollectionsState})
        case "UPDATE_NEWLY_EDITED_COLLECTION":
            let createdCollections = [...state.userCreatedCollections];
            let createdCollectionIndex = createdCollections.findIndex(element => element.id === action.collectionData.id)
            let previousModules = createdCollections[createdCollectionIndex].modules.map(module => module.id).sort();
            let currentModules = action.collectionData['modules'].map(module => module.id).sort();
            let alert = false;
            if(previousModules.length != currentModules.length){
                alert = true;
            }else {
                for(let i = 0; i < previousModules.length; i++){

                    if(previousModules[i] != currentModules[i]){
                        alert = true;
                        break;
                    }
                }
            }
            createdCollections[createdCollectionIndex] = action.collectionData
            return Object.assign({}, state, {userCreatedCollections: createdCollections, collectionDetail: action.collectionData, notifyToEditLicenseOnCollectionChange: alert})
        case "UPDATE_NEWLY_EDITED_MODULE":
            let createdModules = [...state.userCreatedModules]
            let createdModuleIndex = createdModules.findIndex(element => element.id === action.moduleData.id)
            createdModules[createdModuleIndex] = action.moduleData
            return Object.assign({}, state, {userCreatedModules: createdModules})
        case "UPDATE_CREATED_SCHEDULED_LICENSE":
            let scheduledLicenses = [...state.collectionDetailLicenses]
            let scheduledLicenseIndex = scheduledLicenses.findIndex(element => element.id === action.licenseData.id)
            scheduledLicenses[scheduledLicenseIndex] = action.licenseData
            return Object.assign({}, state, {collectionDetailLicenses: scheduledLicenses })
        default:
            return state
    }
}