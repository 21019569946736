import { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Carousel, Drawer, message, Space, Tag, Timeline, Tooltip, Typography } from 'antd';
import {EyeOutlined, FastForwardOutlined, SwapRightOutlined} from '@ant-design/icons';

import QuizQuestionOption from './QuizQuestionOption';
import { convertFromRaw, EditorState } from 'draft-js';
import RichTextPreview from '../../../Components/RichTextPreview';

import {submitQuizQuestionAttempt} from '../../../actions/collection.js';
 
const {Title, Text} = Typography;

class QuizQuestion extends Component {
    constructor(props) {
        super(props)
        var submitted;
        if(!this.props.question.latestAttempt){
            submitted= false
        }else {
            submitted = true
        }
        this.state = {
            visibleQuestion: false,
            submitted: submitted,
            selected: submitted ? this.props.question.latestAttempt.selectedAnswers : [],
            submitLoading: false,
            triggered: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.question.latestAttempt !== this.props.question.latestAttempt){
            if(this.props.question.latestAttempt != null){
                this.setState({
                    submitted: true,
                    selected: this.props.question.latestAttempt.selectedAnswers
                })
            }else {
                this.setState({
                    submitted: false,
                    selected: []
                })
            }
        }else if(
            !this.props.mutedQuizQuestions
            && Math.floor(this.props.currentTimestamp) == this.props.question.endVideoTimestamp 
            && prevProps.currentTimestamp < this.props.question.endVideoTimestamp 
            && !this.state.triggered
            && !this.state.submitted
        ){
            this.setState({triggered: true, visibleQuestion: true})
            this.props.pauseVideo()
            message.info("Video Paused.")
        }
    }

    timelineDotColor = () => {
        if(this.state.submitted && this.props.question.latestAttempt){
            if(this.props.question.latestAttempt.numIncorrect == 0
                && this.props.question.latestAttempt.maxCorrectAnswer == this.props.question.latestAttempt.numCorrect){
                    return "green"
            }else {
                return "red"
            }
        } else if(!this.state.submitted && this.props.currentTimestamp >= this.props.question.endVideoTimestamp){
            return "#dbb63d"
        } else {
            return "grey"
        }
    }

    insertOrRemoveSelectedOption = (optionId) => {
        let selectedState = this.state.selected;
        var index = selectedState.indexOf(optionId)
        if(this.state.submitted){
            return
        }
        if(this.props.question.questionType == "select_one"){
            if(index > -1) {
                selectedState = []
            }else {
                selectedState = [optionId]
            }
            this.setState({
                selected: selectedState
            })
        }else if(this.props.question.questionType == "choose_all_that_apply"){
           
            if(index > -1) {
                selectedState.splice(index, 1)
            }else {
                selectedState.push(optionId)
            }
            this.setState({
                selected: selectedState
            })
        }
    }

    convertSecondsToVideoTime = (seconds) => {
        var minutes = Math.floor(seconds / 60);
        var minuteString = `${minutes}`.padStart(2, "0")
        var secondsString = `${seconds - minutes * 60}`.padStart(2, "0")
        return `${minuteString}:${secondsString}`
    }

    handleJumpToTimestamp = () => {
        this.props.seekToVideoTimestamp(this.props.question.startVideoTimestamp)
    }

    closeDrawer = () => {
        if(!this.state.submitted && this.state.selected.length > 0){
            this.setState({visibleQuestion: false, selected: []})
        }else {
            this.setState({visibleQuestion: false})
        }
    }

    submitQuizOrTryAgain = () => {
        if(!this.state.submitted) {
            if(this.state.selected.length == 0){
                message.warning('You must select and answer')
            } else {
                this.setState({submitLoading: true})
                this.props.submitQuizQuestionAttempt(this.props.collection.id, this.props.module.id, this.props.video.id, this.props.question.id, this.state.selected, ()=>this.setState({submitLoading: false}))
            }
        }else {
            this.setState({
                submitted: false,
                selected: [],
                submitLoading: false,
            })
        }
       
    }

    renderQuestionType = () => {
        if(this.props.question.questionType == "select_one"){
            return "Select one"
        } else if(this.props.question.questionType == "choose_all_that_apply"){
            return "Choose all that apply"
        }
    }

    renderIncorrectMessage = () => {
        if(this.state.submitted && this.props.question.latestAttempt.maxCorrectAnswer == this.props.question.latestAttempt.numCorrect && this.props.question.latestAttempt.numIncorrect == 0){
            return <Text style={{color: "green"}}>Great job! Hover over the options to see an explanation as to why each option is correct or incorrect.</Text>
        }else if(this.state.submitted){
            if(this.props.question.questionType == "select_one"){
                return <Text type="danger">The answer you have selected is incorrect. Please click the 'Try Again' button to retry.</Text>
            }else if(this.props.question.questionType == "choose_all_that_apply"){
                if(this.props.question.latestAttempt.maxCorrectAnswer > this.props.question.latestAttempt.numCorrect && this.props.question.latestAttempt.numIncorrect == 0){
                    return <Text type="danger">Missing at least one more correct answer. Please click the 'Try Again' button to retry.</Text>
                }
                return <Text type="danger">At least one of the options you have selected is incorrect. Please click the 'Try Again' button to retry.</Text>
            }
        }else {
            return ""
        }
    }

    render() {
        const contentStateText = convertFromRaw(this.props.question.text);
        const editorStateText = EditorState.createWithContent(contentStateText);
        return <Timeline.Item color={this.timelineDotColor()} >
                <Card bordered
                    actions={[
                        <div onClick={this.handleJumpToTimestamp}><FastForwardOutlined key="fastforward"/> Jump to start</div>,
                        <div onClick={() => this.setState({visibleQuestion: true})}><EyeOutlined key="ellipsis" /> View Question</div>,
                    ]}
                >
                    <Space align="start">
                        <Tooltip title={"The start of the section of which the question is covering"} placement="topLeft" color="cyan">
                            <Text keyboard >{this.convertSecondsToVideoTime(this.props.question.startVideoTimestamp)}</Text>
                        </Tooltip>
                        <SwapRightOutlined />
                        <Tooltip title={"The end of the section of which the question is covering. The quiz question will be presented to you when the video reaches the end timestamp"} placement="topLeft" color="cyan">
                            <Text keyboard >{this.convertSecondsToVideoTime(this.props.question.endVideoTimestamp)}</Text>
                        </Tooltip>
                        <Tooltip title={this.props.question.title.toUpperCase()} placement="topLeft" color="cyan"><Text style={{width:"190px"}} ellipsis={{}}>{this.props.question.title.toUpperCase()}</Text></Tooltip>
                    </Space>
                </Card>
                <Drawer
                    title={this.props.question.title}
                    onClose={this.closeDrawer}
                    width={"700px"}
                    visible={this.state.visibleQuestion}
                    footer={
                        <div
                        style={{
                            textAlign: 'right',
                        }}
                        >
                        <Button style={{ marginRight: 8 }} onClick={this.closeDrawer}>
                            Close
                        </Button>
                        <Button type="primary" loading={this.state.submitLoading} onClick={this.submitQuizOrTryAgain} disabled={this.state.submitted && this.props.question.latestAttempt.maxCorrectAnswer == this.props.question.latestAttempt.numCorrect && this.props.question.latestAttempt.numIncorrect == 0}>
                            {this.state.submitted ? "Try Again" : "Submit"}
                        </Button>
                        </div>
                    }
                >
                    <div className="quizquestion-drawer-content-width">
                    <RichTextPreview editorState={editorStateText} />
                    <Carousel style={{background: '#364d79'}} autoplay className="quizquestion-carousel-width">
                        {this.props.question.images.map((imageFile, index) => <div key={index}><img alt="example" style={{ width: '100%' }} src={imageFile.image} /></div>)}
                    </Carousel>
                    <br/>
                    <Tag color="geekblue">{this.renderQuestionType()}</Tag>
                    <br/>
                    {this.renderIncorrectMessage()}
                    {
                        this.props.question.options.map((option, index) => <QuizQuestionOption key={`option[${index}]`}
                                                            handleOnClick={() => this.insertOrRemoveSelectedOption(option.id)}
                                                            selected={this.state.selected.indexOf(option.id) > -1} 
                                                            latestAttempt={this.props.question.latestAttempt}
                                                            submitted={this.state.submitted}
                                                            option={option}/>)
                    }
                    </div>
                </Drawer>
            </Timeline.Item>
    }
    
}

const mapStateToProps = (state) => {
    return {
        collection: state.collectionReducer.singleCollection,
        module: state.collectionReducer.singleModule,
        video: state.collectionReducer.singleVideo,
        currentTimestamp: state.videoControlReducer.videoTimestamp,
        mutedQuizQuestions: state.videoControlReducer.mutedQuizQuestions
    }
}
export default connect(mapStateToProps, {submitQuizQuestionAttempt})(QuizQuestion);