import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Checkbox, Col, Descriptions, Divider, Form, Input, InputNumber, PageHeader, Row, Space, Spin, Typography} from 'antd';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';

import { createNewModule, fetchUserPolicyModel, fetchPublicModules, fetchUserCreatedCollections, fetchUserCreatedModules} from '../../actions/contentCreation';
import ImportVideoFromUrl from '../ImportFromUrl/ImportVideoFromUrl';
import ImportVideoFromCollection from '../ImportFromCollection/ImportVideoFromCollection';
import ImportVideoFromModule from '../ImportFromModule/ImportVideoFromModule';
import ModalVideoPlayer from '../ModalVideoPlayer';
import ImportedVideo from '../ImportedVideo';
import VideosInModule from '../VideosInModule';
import Tour from '../Tour';
import AuthorSelect from '../AuthorSelect';
import PolicyModelTooltip from '../PolicyModelTooltip';

const {Paragraph, Text} = Typography;


  
class ModuleCreator extends Component {
    formRef = React.createRef();

    constructor(props){
        super(props)
        this.state = { 
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        }
    }

    tutorialSteps = [{
            target: '#new-module-form',
            content: <div>
                        <p style={{textAlign: 'left'}}>Welcome to the New Module Form!</p>
        
                        <p style={{margin: 0, textAlign: 'left', fontWeight: 'bold'}}>What is a module?</p>
                        <p style={{textAlign: 'left'}}>You can think of a module as a single lecture. A lecture could cover a bigger topic such as Arrays, so you can assign a module several shorter videos that break down the main topic into smaller sub topics.</p>
                    </div>,
            placement: "right-start",
            disableBeacon: true,
        },
        {
            target: '.ant-page-header-heading-extra', 
            content: 'To find content to add to your module, you can browse for existing content from collection/modules/videos using the buttons here or import videos with a link to a YouTube or Vimeo video or playlist.'
        },
        {
            target: '#imported-videos-container',
            content: 'Any content added will show up in this section, which you can then select and assign them to modules of your choice.',
            placement: "left-start"
        },
        {
            target: '#new-module-videos-container',
            content: 'After selecting videos, click here to add them to the module.',
        },
        {
            target: '#new-module-submit-btn',
            content: 'After reviewing your module, click Submit to finish creating your module!'
        },
       ]
    componentDidMount(){
        this.props.fetchPublicModules()
        if(this.props.isAuthenticated && !this.props.receivedUserCreatedModules){
            this.props.fetchUserCreatedModules(this.props.user.id)
        }
        if(this.props.isAuthenticated && !this.props.receivedUserCreatedCollections){
            this.props.fetchUserCreatedCollections(this.props.user.id)
        }
        if(this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false && !this.props.receivedUserCreatedModules){
            this.props.fetchUserCreatedModules(this.props.user.id)
        }
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && prevProps.isAuthenticated === false && !this.props.receivedUserCreatedCollections){
            this.props.fetchUserCreatedCollections(this.props.user.id)
        }
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated){
            this.props.fetchUserPolicyModel(this.props.user.id)
        }
    }

    openModal = (event) => {
        //this.setState({})
        this.setState({
            [event.currentTarget.getAttribute('data-modal-type')]: true
        })
    }

    closeModal = () => {
        this.setState({
            isPolicyModelModalVisible: false,
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false
        })
    }

    addImportedVideos = (videos) => {
        let videoIds = videos.map(video => video.id)
        this.setState({
            licensedVideos: [...this.state.licensedVideos, ...videoIds],
            importedVideos: [...this.state.importedVideos, ...videos],
            isVideoImportFromUrlModalVisible: false,
        })
    }

    selectVideos = (videoData) => {
        const newSelectedVideosState = this.state.selectedVideos.filter(existingVideo => existingVideo.embedId !== videoData.embedId)
        if(newSelectedVideosState.length === this.state.selectedVideos.length) {
            // same length == no change / video doesn't exist in selected videos
            this.setState({
                selectedVideos: [...this.state.selectedVideos, videoData]
            })
        } else {
            this.setState({
                selectedVideos: newSelectedVideosState
            })
        }
    }

    selectVideoToPlay = (video, module) => {
        this.setState({
            visiblePlayer: true, 
            videoToPlay: video,
            videoPlayedFromModule: module
        })
    }

    closeVideoPlayer = () => {
        this.setState({
            visiblePlayer: false,
        })
    }

    addVideoToModule = () => {
        this.setState({
            selectedVideos: [], 
            importedVideos: this.state.importedVideos.filter(videoData => {
                return !this.state.selectedVideos.some(selectedVideo => videoData.embedId === selectedVideo.embedId)
            })
        })
    }

    isImportedVideoSelected = (videoEmbedId) => {
        return this.state.selectedVideos.some(selectedVideo => videoEmbedId === selectedVideo.embedId)
    }

    handleOnCreateCollectionFormFinish = (values) => {
        if(values['coAuthorUsernames'] == null){
            values['coAuthorUsernames'] = []
        }
        if(values['primaryAuthorUsername'] == null) {
            values['primaryAuthorUsername'] = this.props.user.username
        }
        this.setState({ submitLoading: true })
        const afterCreation = () => {
            this.setState({ submitLoading: false})
        }
        this.props.createNewModule(values, this.handleAfterCollectionCreated, afterCreation)
    }

    handleAfterCollectionCreated = () => {
        this.setState({
            licensedVideos: [],
            isVideoImportFromCollectionModalVisible: false,
            isVideoImportFromModuleModalVisible: false,
            isVideoImportFromUrlModalVisible: false,
            importedVideos: [],
            selectedVideos: [],
            submitLoading: false,
            visiblePlayer: false,
            videoToPlay: {
                videoType: null
            },
            videoPlayedFromModule: null
        })
        this.formRef.current.resetFields()
        this.props.history.push('/creator/home/?tab=2')
    }

    handleOnSelectAllClick = () => {
        this.setState({
            selectedVideos: this.state.importedVideos
        })
    }

    handleOnUnselectAllClick = () => {
        this.setState({
            selectedVideos: []
        })
    }
    
    handleDiscardSelected = () => {
        const newImportedVideosState = this.state.importedVideos.filter(video => !this.state.selectedVideos.some(selectedVideo => video.id === selectedVideo.id));
        let videoIdsToRemove = this.state.selectedVideos.map(videoData => videoData.id)
    
        this.setState({
            licensedVideos: this.state.licensedVideos.filter(videoId => !videoIdsToRemove.includes(videoId)),
            importedVideos: newImportedVideosState,
            selectedVideos: []
        })
    }

    copyModuleOrCollection = (moduleDatas) => {
        moduleDatas.forEach(module => {
            if("id" in module){
                delete module["id"]
            }
        })
        let existingModules = this.formRef.current.getFieldValue("modules")
        if(!existingModules){ existingModules = [] }
        this.formRef.current.setFieldsValue({modules: [...existingModules, ...moduleDatas]})
    }

    renderTutorial = () => {
        const completedTutorial = localStorage.getItem('completedCreateModuleTutorial');
        if(completedTutorial == null) {
            return <Tour steps={this.tutorialSteps} tutorialName='completedCreateModuleTutorial' />
        } else {
            return
        }
    }

    fetchCreatorList = async (username) => {
        return fetch(`https://www.api.labs.cubits.ai/api/v1/creators/${username ? "?search=" + username : ""}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access'),
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((body) =>
                body.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.username,
                })),
            );
    }

    calculateLicensedModulePrice = () => {
        if(this.formRef.current) {
            let listName = ["videos"];
            let currentModuleId = this.formRef.current.getFieldValue(['id'])
            let videos = this.formRef.current.getFieldValue(listName)
            if(videos){
                let licensedModuleIds = new Set(videos.map(video => video.parentModule));
                licensedModuleIds.delete(currentModuleId)
                licensedModuleIds.delete(undefined)
                licensedModuleIds = [...licensedModuleIds]
                let sum = 0;
                if(licensedModuleIds.length){
                    licensedModuleIds.forEach(moduleId => {
                    let licensedModule = this.props.allModules.find(moduleData => moduleData.id == moduleId);
                    if(licensedModule != null){
                        sum += licensedModule.price;
                    }
                    })
                }
                return sum;
            }else {
                return 0;
            }
        }
    }

    calculateTotalPrice = () => {
        if(this.formRef.current) {
            let totalPrice = this.formRef.current.getFieldValue(['customPrice']);
            if(totalPrice == null){
                totalPrice = this.props.policyModel.platformDefaultPrice;
            }
            totalPrice += this.calculateLicensedModulePrice();
            return totalPrice;
        }
        console.log(this.formRef)
    }
    
    render() {
        if(!this.props.policyModel){
            return <Space size="middle" className="loading-indicator-container">
                        <Spin size="large" />
                    </Space>
        }
        const { policyModel } = this.props;
       
        const modulePriceTooltipConfig = {
            title: <PolicyModelTooltip policyModel={policyModel} />,
            placement: 'bottomLeft'
        }
        return <div id="module-creator-container">
                {this.renderTutorial()}
                <div id="module-creator-header">
                    <PageHeader
                        title="Create a Module"
                        className="site-page-header"
                        ghost={false}
                        onBack={() => this.props.history.push('/creator/home/')}
                        extra={[
                            <Space key="1">
                                <Button data-modal-type="isVideoImportFromCollectionModalVisible" onClick={this.openModal} ><ImportOutlined /> Add from collection</Button>
                                <ImportVideoFromCollection 
                                    visible={this.state.isVideoImportFromCollectionModalVisible} 
                                    closeModal={this.closeModal} 
                                    playVideoInModal={this.selectVideoToPlay} 
                                    addImportedVideos={this.addImportedVideos}
                                    licensedVideos={this.state.licensedVideos}
                                    copyModuleOrCollection={null}
                                />
                            </Space>,
                            <Space key="2">
                                <Button data-modal-type="isVideoImportFromModuleModalVisible" onClick={this.openModal}><ImportOutlined /> Add from module</Button>
                                <ImportVideoFromModule 
                                    visible={this.state.isVideoImportFromModuleModalVisible} 
                                    closeModal={this.closeModal} 
                                    playVideoInModal={this.selectVideoToPlay} 
                                    addImportedVideos={this.addImportedVideos}
                                    licensedVideos={this.state.licensedVideos}
                                    copyModuleOrCollection={null}
                                />
                            </Space>, 
                            <Space key="3">
                                <Button data-modal-type="isVideoImportFromUrlModalVisible" onClick={this.openModal}><ImportOutlined /> Import videos from URL</Button>
                                <ImportVideoFromUrl 
                                    visible={this.state.isVideoImportFromUrlModalVisible} 
                                    closeModal={this.closeModal} 
                                    addImportedVideos={this.addImportedVideos}
                                />
                            </Space>
                        ]}
                    >
                            
                        </PageHeader>
                </div>
                <br/>
                <div id="new-module-form-container">
                    <Card id="new-module-form">
                        <Form
                            layout="vertical"
                            name="collection"
                            ref={this.formRef}
                            onFinish={this.handleOnCreateCollectionFormFinish}
                        >
                            <Form.Item
                                name="name"
                                label="Module Name"
                                rules={[{ required: true, message: 'Please give your module a name!' }]}
                            >
                                <Input maxLength={50}/>
                            </Form.Item>
                            <Form.Item 
                                label="Author"
                                name="primaryAuthorUsername"
                                tooltip="If you are not the primary author, search for the user's account by username or name. Only this user will get paid for any purchases. If you leave this blank, the primary author will be set to you."

                            >
                                <AuthorSelect 
                                    placeholder={"Select or search for a user by username (Or leave blank to default to yourself)"}
                                    fetchOptions={this.fetchCreatorList}
                                    mode={null}
                                />
                            </Form.Item>
                            <Form.Item 
                                label="Co-Authors"
                                name="coAuthorUsernames"
                                tooltip="Used mainly for credit purposes only."

                            >
                                <AuthorSelect 
                                    placeholder={"Select or search for a user by username"}
                                    fetchOptions={this.fetchCreatorList}
                                    mode={"multiple"}
                                />
                            </Form.Item>
                            <Space size={'large'} align="start" wrap>
                                <Space align="center">
                                    <Form.Item
                                        name={'customPrice'}
                                        label='Module Price'
                                        tooltip={modulePriceTooltipConfig}
                                    >
                                        <InputNumber 
                                            min={this.props.policyModel.platformMinimumPrice}
                                            onChange={value => this.formRef.current.setFieldsValue('customPrice', value)}
                                        />
                                    </Form.Item>
                                    cupoints
                                </Space>
                                + <Space direction="vertical">
                                    <Text>Licensed Content Price</Text>
                                    <InputNumber readOnly placeholder={this.calculateLicensedModulePrice()} />
                                </Space>
                                +
                                <Space direction="vertical">
                                    <Text>Total Price</Text>
                                    <InputNumber readOnly placeholder={this.calculateTotalPrice()}/>
                                </Space>
                            </Space>
                            <div id="new-module-videos-container">
                                <VideosInModule 
                                    fieldKey={null}
                                    formRef={this.formRef}
                                    selectedVideos={this.state.selectedVideos}
                                    addVideoToModule={this.addVideoToModule}
                                    currentUser={this.props.user}
                                    addImportedVideos={this.addImportedVideos}
                                    playVideoInModal={this.selectVideoToPlay} 
                                />
                            </div>
                            <br/>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={this.state.submitLoading} id="new-module-submit-btn">
                                Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Card 
                        id="imported-videos-container" 
                        title={`Unassigned Videos`}
                        actions={[
                            <Text onClick={this.handleOnSelectAllClick} disabled={this.state.importedVideos.length ? false : true}>Select all</Text>, 
                            <Text onClick={this.handleOnUnselectAllClick} disabled={this.state.selectedVideos.length ? false : true}>Unselect All</Text>, 
                            <Text onClick={this.handleDiscardSelected} disabled={this.state.selectedVideos.length ? false : true}>Remove selected</Text>]}
                    >
                        
                        <Descriptions bordered size="small">
                            <Descriptions.Item label="Imported">{this.state.importedVideos.length}</Descriptions.Item>
                            <Descriptions.Item label="Selected">{this.state.selectedVideos.length}</Descriptions.Item>
                        </Descriptions>
                        
                        <div id="imported-videos-scroll-container">
                            <div id="imported-video-cards-wrapper"> 
                                <Row gutter={[16,16]}>
                                {
                                    this.state.importedVideos.map((videoData, index) => 
                                        <>
                                            <Col span={12}>
                                            <ImportedVideo 
                                                videoData={videoData} 
                                                key={index} 
                                                selectVideo={this.selectVideos}
                                                videoSelected={this.isImportedVideoSelected(videoData['embedId'])}
                                                
                                            />
                                            </Col>
                                            <br/>
                                        </>
                                    )
                                }
                                </Row>
                            </div>
                        </div>
                    </Card>
                    <ModalVideoPlayer  
                        module={this.state.videoPlayedFromModule}
                        playVideoInModal={this.selectVideoToPlay}
                        visible={this.state.visiblePlayer} 
                        toggleVideoPlayerModalVisibility={this.closeVideoPlayer}
                        video={this.state.videoToPlay}
                        videoActions={['play']}
                    />
                </div>
            </div>
    }
}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
        policyModel: state.contentCreationReducer.policyModel,
        receivedUserCreatedCollections: state.contentCreationReducer.receivedUserCreatedCollections,
        allModules: state.contentCreationReducer.modulePool,
        receivedUserCreatedModules: state.contentCreationReducer.receivedUserCreatedModules
    }
}

export default connect(mapStateToProps, {createNewModule, fetchUserPolicyModel, fetchPublicModules, fetchUserCreatedCollections, fetchUserCreatedModules})(ModuleCreator);