import { Button, Collapse, Divider, List, Input, Modal, Space} from 'antd';
import { connect } from 'react-redux';
import { useState } from 'react';

import ModulesComponentList from '../ModulesComponentList';

const { Search } = Input;

const ImportVideoFromModule = ({ addImportedVideos, assignModuleToCollection, closeModal, copyModuleOrCollection, currentUser, licensedVideos, modules, playVideoInModal, userCreatedModules, visible}) => {
    const [selectedFilter, setSelectedFilter] = useState("public")
    const [moduleSearchValue, setModuleSearchValue] = useState("")

    
    const determineDataSource = () => {
        let result;
        if(selectedFilter === "userCreated") {
            result = userCreatedModules;
        } else if(selectedFilter === "myUnassignedModules"){
            result = userCreatedModules.filter(module => module.parentCollection == null)
        } else if(selectedFilter === "public") {
            result = modules.filter(module => module.privacyScope == "public" && module.createdBy != currentUser.id);
        } else {
            result = []
        }
        return result.filter(module => {
            return module.name.toLowerCase().search(moduleSearchValue) != -1 || 
                module.creatorUsername.toLowerCase().search(moduleSearchValue) != -1 || 
                module.primaryAuthor.firstName.toLowerCase().search(moduleSearchValue) != -1 ||
                module.primaryAuthor.lastName.toLowerCase().search(moduleSearchValue) != -1
        })
    }
    
    const handleOnModuleSearch = (value) => {
        setModuleSearchValue(value.toLowerCase())
    }
    const handleOnModuleSearchChange = (event) => {
        setModuleSearchValue(event.target.value.toLowerCase())
    }

    return  <Modal title="Available modules" 
                className="import-content-from-modal"
                width="80vw"
                visible={visible}
                maskClosable={false}
                footer={[<Button onClick={closeModal}>Cancel</Button>]}
                onCancel={closeModal}
                onOk={() => console.log("hello")}
            >

                <Space style={{ padding: "10px 25px 0px 25px"}}>
                    <Search placeholder="Search for a module" onSearch={handleOnModuleSearch} onChange={handleOnModuleSearchChange} allowClear style={{ width: 500 }} />
                    <Button shape="round" 
                        type={selectedFilter === "public" ? "primary" : "default"}
                        onClick={() => setSelectedFilter("public")}
                    >
                        Public
                    </Button>
                    <Button shape="round" 
                        type={selectedFilter === "userCreated" ? "primary" : "default"}
                        onClick={() => setSelectedFilter("userCreated")}
                    >
                        My Modules
                    </Button>
                    <Button shape="round"
                        type={selectedFilter === "myUnassignedModules" ? "primary" : "default"}
                        onClick={() => setSelectedFilter("myUnassignedModules")}
                    >
                        My Unassigned Modules
                    </Button>
                </Space>
                <Divider style={{margin: "10px 0px 0px 0px"}}/>
                
                 <ModulesComponentList 
                    modules={determineDataSource()}
                    searchValue={moduleSearchValue}
                    playVideoInModal={playVideoInModal} 
                    moduleActions={['add']} 
                    videoActions={['add', 'play']}
                    addImportedVideos={addImportedVideos} 
                    assignModuleToCollection={assignModuleToCollection} 
                    copyModuleOrCollection={copyModuleOrCollection} 
                    licensedVideos={licensedVideos} 
                    currentUser={currentUser}  
                    enablePagination={true}
                />
            </Modal>
}


/*


*/
const mapStateToProps = state => {
    return {
        modules: state.contentCreationReducer.modulePool,
        userCreatedModules: state.contentCreationReducer.userCreatedModules,
        currentUser: state.userReducer.user
    }
}
export default connect(mapStateToProps, {})(ImportVideoFromModule);