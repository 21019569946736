import CollectionSearchResultsVideoTranscriptList from './CollectionSearchResultsVideoTranscriptList';
import { Button, Col, Card, Image, Row, Space, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { LeftOutlined, PlayCircleOutlined, RightOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";


const { Meta } = Card;
const { Title } = Typography;

const CollectionSearchResultVideoList = ({ collectionId, modulesInCollection, searchResults, selectVideo, selectedVideo, searchValue, unlistedKey }) => {
    var [vimeoThumbnailUrl, setVimeoThumbnailUrl] = useState(null)
    const fetchVimeoThumbnail = async(video) => {
        var response = await fetch(`https://vimeo.com/api/v2/video/${video.embedId}.json`)
        const json = await response.json()
        return setVimeoThumbnailUrl(json[0]["thumbnail_large"])
    }
    const getVideoThumbnail = (video) => {
        if(video.videoType === "youtube"){
            return <Image src={`https://img.youtube.com/vi/${video.embedId}/0.jpg`} preview={false}/>
        }else if(video.videoType === "vimeo"){
            fetchVimeoThumbnail(video)
            return <Image
                        src={vimeoThumbnailUrl}
                        preview={false}
                    />
        }
    }

    const scrollVideoListLeft = (event) => {
        event.stopPropagation();
        let videoListElement = document.getElementById('search-module-video-list').scrollLeft -= 600;
    }
    const scrollVideoListRight = (event) => {
        event.stopPropagation();
        let videoListElement = document.getElementById('search-module-video-list').scrollLeft += 600;
    }
    return <div className="collection-search-results-video-container">  
            <Button
                shape="circle"
                style={{
                    position: 'absolute',
                    left: 0,
                    top: "45%",
                    zIndex: 2
                }}
                onClick={scrollVideoListLeft}
            >
                <LeftOutlined />
            </Button>
            <Row id="search-module-video-list" wrap={false}>
                
                {searchResults.map(video => {
                    let module = modulesInCollection.find(module => module.videos.find(videoData => video.id == videoData.id))
                    return <>
                        <Col xs={{ span: 5 }} key={`video-${video.id}`}>
                            <div className={`search-module-video-card-container${selectedVideo && selectedVideo.id == video.id ? " search-module-video-card-container-selected" : ""}`}>
                                <Card
                                    cover={getVideoThumbnail(video)}
                                    onClick={()=>{
                                        selectVideo(module.id, video)
                                    }}
                                    actions={[
                                        <Link to={`/collections/${collectionId}/modules/${module.id}/videos/${video.id}/${unlistedKey ? "?key=" + unlistedKey : ""}`}><Space>Watch <PlayCircleOutlined key="play"/></Space></Link>,
                                        <Tooltip title={`The keyword "${searchValue}" was found in ${video.transcripts.length} moments of video`}><Space>{video.transcripts.length} matches</Space></Tooltip>
                                    ]}
                                >
                                    <Meta title={<Highlighter
                                                    highlightClassName="collection-search-keyword-highlight"
                                                    searchWords={[searchValue]}
                                                    textToHighlight={video.title}
                                                />} 
                                        />
                                </Card>
                            </div>
                        </Col>
                    </>
            })}
                
            </Row>
            <Button
                shape="circle"
                style={{
                    position: 'absolute',
                    right: 0,
                    top: "45%",
                    zIndex: 2
                }}
                onClick={scrollVideoListRight}
            >
                <RightOutlined />
            </Button>
    </div>
}

export default CollectionSearchResultVideoList;