import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';


const RedirectableRoute = ({redirectAfterLogout, requireLogin, ...props}) => {
    if(requireLogin || redirectAfterLogout){
        return <Redirect to='/' />
    } else {
        return <Route {...props} />
    }
}

const mapStateToProps = (state) => {
    return {
        requireLogin: state.userReducer.requireLogin,
        redirectAfterLogout: state.userReducer.redirectAfterLogout
    }
}
export default connect(mapStateToProps, {})(RedirectableRoute);