import { Comment, Divider, Tag } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import RichTextPreview from '../../../Components/RichTextPreview';
import { EditorState, convertFromRaw } from "draft-js";
import { compositeDecorator } from '../../../Components/RichTextDecoratorConfig';

const QnAAnswer = ({currentUser, enrolledLicenses, selectedLicense, response}) => {
    const contentState = convertFromRaw(response['text']);
    const editorState = EditorState.createWithContent(contentState, compositeDecorator);
    
    const renderDisplayName = () => {
        let currentLicense = enrolledLicenses.find(license => license.id === selectedLicense);

        if(!response.anonymous && response.instructorHighlight){
            if(response.user.firstName && response.user.lastName){
                return <Tag color="#868CBE">{`${response.user.firstName} ${response.user.lastName}`}</Tag>
            } else {
                return <Tag color="#868CBE">{response.user.username}</Tag>
            }
        }else if(response.anonymous){
            if(response.user && response.user.id == currentUser.id){
                return "Anonymous User (You)"
            }
            else if(currentUser.id === currentLicense.createdById){
                return <div className="qna-anonymous-spoiler-tag">
                            {response.user.firstName && response.user.lastName ? `${response.user.firstName} ${response.user.lastName}` : response.user.username}
                            <div className="qna-anonymous-hover-to-reveal"><EyeInvisibleOutlined /></div>
                        </div>
            }else {
                return "Anonymous User"
            }
        }else if(response.user.firstName && response.user.lastName){
            return `${response.user.firstName} ${response.user.lastName}`
        } else {
            return response.user.username
        }
        
    }

    const renderDate = () => {
        let date = new Date(response.createdAt);
        return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
    }

    return <>
            <Divider style={{ margin: "5px 0 5px 0"}}/>
            <Comment
                author={renderDisplayName()}
                content={
                    <RichTextPreview editorState={editorState}/>
                }
                datetime={renderDate()}
                className={response.newComment ? "highlight-new-comment" : ""}
            >
              
            </Comment>
        </>
}

export default QnAAnswer;