import { setSkipToVideoTimestamp } from "../actions/videoControl"

const initialState = {
    videoTimestamp: null,
    nextTranscriptUpdate: 1000,
    mutedQuizQuestions: false,
    skipToVideoTimestamp: null
}

export default (state=initialState, action) => {
    switch(action.type){
        case "SET_VIDEO_TIMESTAMP":
            return Object.assign({}, state, {videoTimestamp: action.timestamp})
        case "SET_NEXT_TRANSCRIPT_UPDATE":
            return Object.assign({}, state, { nextTranscriptUpdate: action.timeInterval})
        case "SET_SKIP_TO_VIDEO_TIMESTAMP":
            return Object.assign({}, state, { skipToVideoTimestamp: action.timestamp})
        case "TOGGLE_MUTED_QUIZ_QUESTIONS":
            return Object.assign({}, state, { mutedQuizQuestions: !state.mutedQuizQuestions})
        default:
            return state
    }
}