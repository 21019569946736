import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Button, Result} from 'antd';
import {clearSingleCollection, clearVideoPageContent} from './actions/collection';


class PageNotFound extends Component {
    componentDidMount() {
      if(this.props.collectionDoesNotExist){
        this.props.clearSingleCollection()
      }
      if(this.props.contentDoesNotExist){
        this.props.clearVideoPageContent()
      }

    }

    render(){
      return <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={<Link to='/'><Button type="primary">Back Home</Button></Link>}
            />
    }
}

const mapStateToProps = state => {
  return {
    collectionDoesNotExist: state.collectionReducer.collectionDoesNotExist,
    contentDoesNotExist: state.collectionReducer.contentDoesNotExist
  }
}
export default connect(mapStateToProps, {clearSingleCollection, clearVideoPageContent})(PageNotFound);