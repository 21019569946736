import Player from '@vimeo/player';
import { Component } from 'react';

class VimeoPlayer extends Component {
    componentDidMount = () => {
        var options = {
            id: this.props.embedId,
            autoplay: true,
        };
        var player = new Player('vimeo-player', options);
        player.on('play', this.props.handleOnPlay);
        this.props.setVideoRef(player);
    }

    render(){
        return <div id='vimeo-player'></div>
    }
}


export default VimeoPlayer;