import { useState } from 'react';
import { Card, Form, Input, Modal, Space, Switch, Typography, Upload} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { convertToRaw, EditorState} from 'draft-js';
import RichTextEditor from '../../../Components/RichTextEditor';
import OptionTextEditorModal from './OptionTextEditorModal';
import QuizQuestionImageUpload from './QuizQuestionImageUpload';

const QuestionCreatorFormOption = ({addImageToAll, addImageToForm, field, formRef, index, removeOption}) => {
    const { Paragraph, Text } = Typography;

    const [optionText, setOptionText] = useState(convertToRaw(EditorState.createEmpty().getCurrentContent()))
    const [optionExplanation, setOptionExplanation] = useState(convertToRaw(EditorState.createEmpty().getCurrentContent()))

    const handleChangeExplanation = (value) => {
        let options = formRef.getFieldValue('options');
      
        options[field.name] = {
            ...options[field.name],
            explanationText: value
        }
        formRef.setFieldsValue({
            options: options
        });
        setOptionExplanation(value)
    }

    const handleChangeOptionText = (value) => {
        let options = formRef.getFieldValue('options');
        options[field.name] = {
            ...options[field.name],
            optionText: value
        }
        formRef.setFieldsValue({
            options: options
        });
        setOptionText(value)
    }
    const handleOnSwitchChange = (checked) => {
        let options = formRef.getFieldValue('options');
        let questionType = formRef.getFieldValue('questionType');

        if(questionType == "select_one"){
            options.forEach((option, index) => {
                if(option.correctAnswerFlag && index != field.name){
                    option.correctAnswerFlag = false;
                }
            })
        }
       
        options[field.name] = {
            ...options[field.name],
            correctAnswerFlag: checked
        }
        formRef.setFieldsValue({
            options: options
        });
    }

    return  (<Form.Item
                label={index === 0 ? 'Options' : ''}
                required={true}
                key={field.key}
            >   
                <Card>
                    <OptionTextEditorModal onOptionTextChange={handleChangeOptionText} onOptionExplanationChange={handleChangeExplanation} textValue={optionText} explanationValue={optionExplanation} removeOption={removeOption}/>
                    <Text>Supporting Image (Limited to 1 per Option)</Text>
                    <QuizQuestionImageUpload addImageToForm={(imageId) =>addImageToForm(index, imageId)} addImageToAll={addImageToAll} maxCount={1} customOnRemove={(file)=>{}}/>
                    <br/>
                    <Form.Item name={[field.name, 'correctAnswerFlag']} initialValue={false} style={{margin: 0}}>
                        <Space align="center"><Switch onChange={handleOnSwitchChange} checked={formRef.getFieldValue(['options', field.name, 'correctAnswerFlag'])} size="small"/><Text type={formRef.getFieldValue(['options', field.name, 'correctAnswerFlag']) ? "success" : "warning"}>{formRef.getFieldValue(['options', field.name, 'correctAnswerFlag']) ? "This option is correct." : "This option is incorrect."}</Text></Space>
                    </Form.Item>
                </Card>
                <Form.Item 
                    name={[field.name, 'optionText']}
                    fieldKey={[field.fieldKey, 'optionText']} 
                    initialValue={"Enter the option text"}
                    hidden
                >
                    <Input value={optionText} />
                </Form.Item>
                <Form.Item 
                    name={[field.name, 'explanationText']}
                    fieldKey={[field.fieldKey, 'explanationText']}
                    initialValue={"Enter an explanation for why this question is correct or incorrect."} 
                    hidden
                >
                    <Input />
                </Form.Item>

            </Form.Item>
    )
}

export default QuestionCreatorFormOption;