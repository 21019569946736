import { connect } from 'react-redux';
import { setSkipToVideoTimestamp } from '../actions/videoControl';
const convertTimestampStringToSeconds = (timestampString) => {
    let splitTimestampString = timestampString.split(':');
    if(splitTimestampString.length === 3){
        let hours = parseInt(splitTimestampString[0]) * 3600;
        let minutes = parseInt(splitTimestampString[1]) * 60;
        let seconds = parseInt(splitTimestampString[2]);
        return hours + minutes + seconds;
    }else if(splitTimestampString.length === 2) {
        let minutes = parseInt(splitTimestampString[0]) * 60;
        let seconds = parseInt(splitTimestampString[1]);
        return minutes + seconds;
    }else {
        return null;
    }
}
const TimestampComponent = ({setSkipToVideoTimestamp ,...props}) => {
    // remove @ from string
    let timestampString = props.decoratedText.slice(1)
    let timestamp = convertTimestampStringToSeconds(timestampString);
    return (
        <span className="timestamp-component" onClick={()=>setSkipToVideoTimestamp(timestamp)}>
            {props.children}
        </span>
    );
};

export default connect(null, {setSkipToVideoTimestamp})(TimestampComponent);