import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import { Image, Input, List, message, PageHeader, Space, Spin, Tag, Typography} from 'antd';
import {FolderOpenOutlined, RightCircleOutlined} from '@ant-design/icons';
import {clearActiveCoupon, verifyCoupon} from '../../actions/user';


const {Search} = Input;
const {Text, Title} = Typography;
class CollectionList extends Component {

    constructor(props){
        super(props)
        this.state = {
            couponSearchValue: "",
            couponSearchLoading: false,
            collectionSearchValue: "",
        }
    }
    
    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last ? (
              <span>{route.breadcrumbName}</span>
            ) : (
              <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: 'Home'
                }, {
                    path: "/collections/",
                    breadcrumbName: "Collections"
                }, 
            ]
        }
    }

    onSearchChange = (event) =>{
        this.setState({couponSearchValue: event.target.value})
    }
    clearCouponSearch = () => {
        this.setState({couponSearchValue: "", couponSearchLoading: false})
    }
    handleOnSearch = (value) => {
        this.setState({couponSearchLoading: true})
        if(value.length){
            this.props.verifyCoupon(value, this.clearCouponSearch)
        } else {
            message.warning('Please enter a valid coupon code')
        }
    }

    handleCouponTagClose = () => {
        this.props.clearActiveCoupon()
    }
    activeCouponTag = () => {
        return this.props.activeCoupon ? <Tag color="volcano" key="active-coupon-tag" onClose={this.handleCouponTagClose} closable>[{this.props.activeCoupon.code}] {this.props.activeCoupon.percentDiscount * 100}% OFF</Tag> : null
    }

    renderCouponSearch = () => {
        if(this.props.isAuthenticated){
            return <Space key={2}>
                        <Search
                            placeholder="Enter a Coupon Code"
                            allowClear
                            enterButton="Apply Coupon"
                            size="small"
                            value={this.state.couponSearchValue}
                            onChange={this.onSearchChange}
                            onSearch={this.handleOnSearch}
                        />
                    </Space>   
        } else {
            return
        }
    }

    checkCouponApplicable = (collection) => {
        if(this.props.user && this.props.activeCoupon){
            let collectionDiscounted = this.props.activeCoupon.discountedCollections.includes(collection.id)
            let moduleDiscounted = collection.modules.find(module => this.props.activeCoupon.discountedModules.includes(module.id))
            return collectionDiscounted || moduleDiscounted ? <Tag color="volcano" key={1}>Coupon Applicable</Tag> : null
        }else{
            return null
        }
    }
    handleOnCollectionSearch = (value) => {
        this.setState({collectionSearchValue: value.toLowerCase()})
    }
    handleOnCollectionSearchChange = (event) => {
        this.setState({collectionSearchValue: event.target.value.toLowerCase()})
    }
    determineDataSource = () => {
        return this.props.collections.filter(collection => collection.name.toLowerCase().search(this.state.collectionSearchValue) != -1 || collection.description.toLowerCase().search(this.state.collectionSearchValue) != -1 || collection.description.toLowerCase().search(this.state.collectionSearchValue) != -1)
    }

    render() {
        return (
            <div id="collection-list-container">
                <br/>
                <PageHeader
                    title="Our Collections"
                    className="site-page-header"
                    ghost={false}
                    breadcrumb={ this.breadcrumbConfig() }
                    extra={[
                        this.activeCouponTag(),
                        this.renderCouponSearch()              
                    ]}
                >
                     <Search placeholder="Search for a collection" onSearch={this.handleOnCollectionSearch} onChange={this.handleOnCollectionSearchChange} allowClear style={{ width: 500 }} />
                </PageHeader>
            
                <br />
                <List 
                    itemLayout="vertical"
                    bordered={false}
                    split={true}
                    size="large"
                    loading={this.props.collections.length == 0 && this.props.fetchingCollections ? {tip:"Loading Collections...", size: "large"}: false}
                    dataSource={this.determineDataSource()}
                    renderItem = { collection => (
                        <List.Item
                            key={collection.id}
                            className="collection-list-item"
                            actions={[<Link to={'/collections/' + collection.id}><RightCircleOutlined /> View More</Link>, <Space><FolderOpenOutlined /> {collection.modules.length + ' Modules'}</Space>, `Overall Value: ${collection.valuePrice} cupoints ($${collection.valueInUsd})`, this.checkCouponApplicable(collection) ]}
                            extra={<Link to={'/collections/' + collection.id}>
                                <Image 
                                    width={500}
                                    height={300}
                                    preview={false}
                                    src={collection.coverImage.image}
                                />
                                </Link>
                            }
                        >
                            <List.Item.Meta
                                title={<Link to={'/collections/' + collection.id}><Title level={4} >{collection.name}</Title></Link>}
                                description={collection.description}
                            />
                            <Text type="secondary">Author:</Text><Text> {collection.primaryAuthor.firstName} {collection.primaryAuthor.lastName}</Text>
                            <br/>
                            <Text type="secondary"> Co-Authors:</Text><Text> {collection.coAuthors.map((coAuthor, index) => `${coAuthor.firstName} ${coAuthor.lastName}${index == collection.coAuthors.length - 1 ? '' : ', '}`)}</Text>
                            <br/>
                            {collection.longDescription}
                        </List.Item>
                    )}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        collections: state.collectionReducer.originalCollections,
        isAuthenticated: state.userReducer.isAuthenticated,
        fetchingCollections: state.collectionReducer.fetchingCollections,
        user: state.userReducer.user,
        activeCoupon: state.userReducer.activeCoupon
    }
}
export default connect(mapStateToProps, {verifyCoupon, clearActiveCoupon})(CollectionList);