import { Card, Col, message, Row, Tag, Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { connect } from 'react-redux';
import VideoList from './VideoList';
import { CheckOutlined, CopyOutlined, EditOutlined, ExportOutlined, LoadingOutlined, LockOutlined, PlusCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import { Duration } from "luxon";
import {useHistory} from 'react-router-dom';
import {toggleModulePrivacyScope} from '../actions/contentCreation';


const { Title, Text } = Typography;
const ModulesComponent = ({addImportedVideos, assignModuleToCollection=null, copyModuleOrCollection, currentUser, licensedVideos, module, playVideoInModal, toggleModulePrivacyScope, moduleActions=[], videoActions=[]}) => {

    const history = useHistory();
    const [privacyToggleBtnLoading, setPrivacyToggleBtnLoading] = useState(false);
    const [copied, setCopied] = useState(false)
    const [assigned, setAssigned] = useState(false)

    
    const redirectToEditPage = (event) => {
        event.stopPropagation();
        history.push(`/modules/${module.id}/edit/`)
    }

    const redirectToCollectionEditor = (event) => {
        event.stopPropagation();
        history.push(`/collections/${module.parentCollection}/edit/?module=${module.id}`)
    }
    const addAllVideosFromModule = () => {
        addImportedVideos(module.videos)
        message.success('Videos successfully imported')
    }

    const renderPrivacyTag = (privacyScope) => {
        switch(privacyScope){
            case "public":
                return <Tag color="green"><UnlockOutlined /> Public</Tag>
            case "private":
                return <Tag color="volcano"><LockOutlined /> Private</Tag>
            default:
                return <h1>?</h1>
        }
    }

    const renderModuleActions = () => {
        let actionButtons = moduleActions.flatMap(moduleAction => {
            switch(moduleAction) {
                case "edit":
                    if(module.parentCollection){
                        return [<Tooltip title="To edit this module, you will be redirected to the collection editor"><Text onClick={redirectToCollectionEditor}><EditOutlined /> Edit</Text></Tooltip>]
                    }else{
                        return [<Text onClick={redirectToEditPage}><EditOutlined /> Edit</Text>]
                    }
                case "add":
                    const allVideosAdded = module.videos.every(video => licensedVideos.includes(video.id));
                    const actionButtons = []

                    const handleCopyClick = () => {
                        setCopied(true)
                        copyModuleOrCollection([module])
                        message.success('Module copied')
                    }
        
                    const handleAssignModule = () => {
                        setAssigned(true)
                        assignModuleToCollection(module)
                        message.success('Module assigned')
                    }

                    if(allVideosAdded){
                        return [<Text key='added'><CheckOutlined /> Added</Text>]
                    }
                    if(assignModuleToCollection && module.parentCollection == null && module.createdBy == currentUser.id){
                        actionButtons.push(<Tooltip title="Add this module to your collection"><Text key='assign-to-collection' onClick={handleAssignModule}><ExportOutlined /> Add Module</Text></Tooltip>)
                    }
                    if(copyModuleOrCollection){
                        actionButtons.push(<Tooltip title="Copy all module basic data (including videos) directly into your collection"><Text key='copy-module' onClick={handleCopyClick}><CopyOutlined /> Copy</Text></Tooltip>)
                    }
                    actionButtons.push(<Tooltip title="Add all videos from module into your unassigned videos"><Text key='add-all-videos-from-module' onClick={()=>addAllVideosFromModule(module)}><PlusCircleOutlined /> Select Videos</Text></Tooltip>)
                    return actionButtons
                default:
                    return
            }
        })
        return actionButtons
    }

    return <Card className="modules-component">
        
        <Row>
            <Col span={8}>
                <Card className="module-descriptions-card" 
                    actions={renderModuleActions()}
                >
                    <Title level={5} ellipsis>{module.name}</Title>
                    {renderPrivacyTag(module.privacyScope)}
                    <br/>
                    <Text type="secondary">Author:</Text><Text> {module.primaryAuthor.firstName} {module.primaryAuthor.lastName}</Text>
                    <br/>
                    <Text type="secondary">Co-Authors:</Text><Text> {module.coAuthors.map((coAuthor, index) => `${coAuthor.firstName} ${coAuthor.lastName}${index == module.coAuthors.length - 1 ? '' : ', '}`)}</Text>
                    <br/>
                    <Text type="secondary">Aggregator:</Text><Text> {module.creatorUsername}</Text>
                    <br/>
                    <Text type="secondary">Collection:</Text><Text> {module.parentCollectionName ? module.parentCollectionName : "<Not Assigned>"}</Text>
                    <br/>
                    <Text type="secondary"># of Videos:</Text><Text> {module.videos.length}</Text>
                    <br/>
                    <Text type="secondary"># of Licensed Modules</Text><Text> {module.licensedModules.length}</Text>
                    <br/>
                    <Text type="secondary">Total Duration:</Text><Text> {Duration.fromObject({'seconds': module.totalDuration}).shiftTo('hours', 'minutes', 'seconds').toFormat("hh:mm:ss")}</Text>
                    <br/>
                    <Text type="secondary">Price:</Text><Text> {module.price} CUpoints</Text>
                    <br/>
                    <Text type="secondary">Licensed content price:</Text><Text> {module.valuePrice - module.price} CUpoints</Text>
                    <br/>
                    <Text type="secondary">Total Price:</Text><Text> {module.valuePrice} CUpoints</Text>
                </Card>
            </Col>
            <Col span={16}>
                <Card className="modules-component-video-list-container">
                <Row key={`module-${module.id}-row`} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} wrap={false} className="module-video-list">
                    {module.videos.map((video, index) => {
                        return <Col key={`module-${module.id}-video-${index}`}>
                            <VideoList 
                                module={module} 
                                videoData={video} 
                                playVideoInModal={playVideoInModal} 
                                addImportedVideos={addImportedVideos}
                                licensedVideos={licensedVideos}
                                videoActions={videoActions}
                                currentUser={currentUser}
                            />
                        </Col>
                    })}
                </Row>
                </Card>
            </Col>
        </Row>
        
    </Card>
}

const mapStateToProps = state => {
    return {
        modules: state.contentCreationReducer.modulePool,
        userCreatedModules: state.contentCreationReducer.userCreatedModules,
        currentUser: state.userReducer.user
    }
}

export default connect(mapStateToProps, {toggleModulePrivacyScope})(ModulesComponent);