import React from 'react';
import { Typography } from 'antd';

const {Text} = Typography;

const TranscriptText = ({seekOnTranscriptClick, textArray, videoTimestamp}) => {

    const handleOnClick = (event) => {
        seekOnTranscriptClick(event.target, event.target.getAttribute('data-start-time'))
    }
    return textArray.map((textSegment, index) => {
        var highlighted = textSegment.startTime <= videoTimestamp && textSegment.endTime > videoTimestamp ? "highlighted-transcript-segment" : ""
        return <Text 
                data-start-time={textSegment.startTime} 
                data-end-time={textSegment.endTime} 
                data-paragraph-flag={textSegment.startOfParagraph} 
                key={index} id={highlighted} className="transcript-segment" onClick={handleOnClick}>{textSegment.startOfParagraph && index !== 0 ? <><br/><br/></> : null}{textSegment.text} </Text>
    })
}

export default TranscriptText;